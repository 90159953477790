<template>

  <v-container fluid>

    <v-row>
      <v-col>
        <h3>Hello {{ task.user.first_name }},</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>More information has been requested regarding your form submission for '{{ task.data.form.title }}' (workflow #{{ task.data.process.id }})</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p>The approver, {{ approverName }}, has provided following feedback:</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined" class="pa-2">
          <span style="white-space: pre;"><b>{{ task.data.task_response.response.query_message }}</b></span>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-toolbar>
          <v-btn
            v-tooltip="'Reopen the form submission for editing.'"
            :to="editLink"
            color="primary"
            variant="elevated"
            class="ma-2"
          >
            Edit submission
          </v-btn>
          <confirm-button
            v-tooltip="'Ask the approver to cancel your submission.'"
            color="warning"
            variant="elevated"
            @click="abandon"
          >
            Abandon submission
          </confirm-button>
        </v-toolbar>
      </v-col>
    </v-row>

  </v-container>

  <json-tree :value="task" />
</template>

<script setup>

const props = defineProps({
  task: {
    type: Object,
    required: true,
    default: () => ({})
  },
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

defineEmits(['execute']);

const approverName = computed(() => `${props.task.data.task_response.user.first_name} ${props.task.data.task_response.user.last_name}`);
const editLink = computed(() => `/forms/${props.task.data.form_submission.key}/edit/${props.task.data.form_submission.lookup_key}`);
const abandonLink = computed(() => `/forms/${props.task.data.form_submission.key}/abandon/${props.task.data.form_submission.lookup_key}`);

function abandon() {
  
}

</script>