import parseDate from './parseDate'

/**
 * Dayjs timezone not working??
 *
 * @param {*} input
 * @param {*} fallback
 */
export default function(date1, date2, op) {
        
  date1 = parseDate(date1);
  date2 = parseDate(date2);

  if (!date1 || !date2) {
      return false;
  }

  let result = false;
  if (op == 'gt') {
      result = date1 > date2;
  } else if (op == 'gte') {
      result = date1 >= date2;
  } else if (op == 'lt') {
      result = date1 < date2;
  } else if (op == 'lte') {
      result = date1 <= date2;
  }

  console.debug(date1.toISOString() +' '+ op +' '+ date2.toISOString() +' = '+ result);

  return result;

}