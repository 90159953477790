<template>

  <v-row
    dense
    no-gutters
    style="margin-bottom: -12px;"
  >
    <v-col>
      <v-radio-group
        v-model="target.type"
        inline
        hide-details
      >
        <v-radio 
          v-for="typ in allowedTypes"
          :key="typ"
          :value="typ" 
          :label="typeMap[typ]"
        />
      </v-radio-group>
    </v-col>
  </v-row>

  <v-row v-if="entityResolutionMissing" dense>
    <v-col>
      <v-alert type="warning">
        Entity resolution has not been configured. Please configure it in the form settings.
      </v-alert>
    </v-col>
  </v-row>

  <v-row v-else-if="requiresEntityResolution" dense>
    <v-col>
      <v-alert type="info">
        <span v-if="target.entity_resolution_override">
          Entity resolution is overridden to <span v-pre>{{</span> {{ target.entity_resolution_field }} <span v-pre>}}</span>. 
        </span>
        <span v-else>
          Entity resolution is set to '{{ graphStore.globalConfig.entity_resolution }}' in {{ graphStore.nodeHoldingGlobalConfig.data.key }}. 
        </span>
      </v-alert>
    </v-col>
  </v-row>

  <!-- <v-alert type="warning">
    TODO: allow overrides here
  </v-alert> -->

  <v-row v-if="target?.type">
    <v-col>
      
      <simple-user-select 
        v-if="target.type == 'user'"
        v-model="target.user"
        multiple
        chips
        closable-chips
        clearable
        label="User"
      />
      
      <simple-role-select 
        v-else-if="target.type == 'role'"
        v-model="target.role"
        multiple
        chips
        closable-chips
        clearable
        label="Role"
      />
      
      <field-select
        v-else-if="target.type == 'field'"
        v-model="target.field"
        :node="node"
      />

      <v-alert v-else-if="target.type == 'definition_owner'" type="info">
        <contact-name :user-id="graphStore.workflowDefinition?.user_id">
          <template #display="{ text }">
            The owner of workflow '{{ graphStore.workflowDefinition.key }}' is <b>{{ text }}</b>
          </template>
        </contact-name>
      </v-alert>

      <v-alert v-else-if="target.type == 'process_owner'" type="warning">
        The Process Owner is usually whoever starts the workflow, but it depends on the workflow configuration. 
        <br>
        It is usually passed by the Rails controller, but it can be overridden, or nil. Use with caution!
        <br>
        <p><small>See backend/app/models/concerns/has_workflows.rb#resolve_workflow_user for more information</small></p>
      </v-alert>

      <div
        v-else-if="target.type == 'workflow_role'" 
        class="d-flex flex-column"
      >

        <workflow-role-select 
          v-model="target.workflow_role"
          clearable
          label="Workflow Role"
        />

        <v-checkbox
          v-if="allowEntityOverride"
          v-model="target.entity_resolution_override"
          density="compact"
          style="margin-top: -24px; margin-bottom: -24px;"
          label="Override Entity Resolution"
          class="mr-2"
        />

        <workflow-field-select
          v-if="target.entity_resolution_override"
          v-model="target.entity_resolution_field"
          :node="node"
        />

      </div>

      <v-checkbox
        label="Restrict to single user"
        density="compact"
        hint="Workflow will fail if multiple users are resolved"
      />
      
    </v-col>
  </v-row>

</template>

<script setup>

/**
 * TODO: add workflow roles page in worflow admin 
 * TODO: allow role overrides here
 */

import FieldSelect from './FieldSelect.vue';

const graphStore = useGraphStore();

const target = defineModel({
  type: Object,
  required: false,
  default: () => ({ type: 'role' })
});

defineProps({
  node: {
    type: Object,
    required: true,
  },
  allowEntityOverride: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowedTypes: {
    type: Array,
    required: false,
    default: () => ['role', 'workflow_role', 'user', 'field', 'definition_owner', 'process_owner']
  }
});

const typeMap = {
  role: 'By IDP Role',
  workflow_role: 'By Workflow Role',
  user: 'By User',
  field: 'By Field',
  definition_owner: 'Definition Owner',
  process_owner: 'Process Owner'
};

// const resolutionStrategies = [
//   { title: '(As configured)', value: null },
//   { title: 'Applicant home entity', value: 'user' },
//   { title: 'Workflow field', value: 'workflow' },
// ];

const requiresEntityResolution = computed(() => target.value.type == 'role' || target.value.type == 'workflow_role');
const entityResolutionMissing = computed(() => {
  return (
    requiresEntityResolution.value &&   
    ! target.value.entity_resolution_override &&  
    ! graphStore.globalConfig.entity_resolution
  );
});

</script>