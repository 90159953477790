<template>
 
  <div v-if="chips" class="d-flex flex-column">

    <json-tree v-if="$portal.debug" :value="field.conditions" />

    <div class="d-flex">
      <v-chip
        v-if="! field.conditions"
        size="small"
        class="mr-2"
      >
        No conditions
      </v-chip>

      <v-chip
        v-if="field.conditions?.length > 1"
        size="small"
        class="mr-2 mt-1"
        :color="field.condition_join == 'or' ? 'green' : 'blue'"
        @click="flipJoin"
      >
        {{ field.condition_join == 'or' ? 'Any' : 'All' }}
      </v-chip>

      <v-chip-group column class="pa-0 ma-0">
        <v-chip
          v-for="condition in field.conditions"
          :key="`condition-${condition.id}`"
          size="small"
          :color="editing == condition.id ? 'blue' : undefined"
          closable
          @click:close="removeCondition(condition)"
          @click="editing == condition.id ? editing = null : editing = condition.id"
        >
          {{ summarise(condition) }}
        </v-chip>
      </v-chip-group>
    
      <v-chip
        size="small"
        class="mt-1"
        @click="addCondition"
      >
        <v-icon>mdi-plus</v-icon>
        condition
      </v-chip>
    </div>

    <div v-if="isEditing" class="d-flex">
      <forms-builder-condition 
        v-if="isEditing"
        :key="`chip-edit-${editing}`"
        v-model="field.conditions[editingIndex]"
        :field="field"
        v-bind="$attrs"
      />
      <v-btn
        size="x-small"
        icon="mdi-check"
        color="teal"
        variant="plain"
        @click="editing = null"
      />
    </div>

  </div>

  <draggable
    v-else
    v-model="field.conditions"
    class="d-flex flex-column"
    ghost-class="ghost"
    item-key="id"
    :animation="200"
    handle="#drag-handle"
  >
    <template #item="{ element: condition, index }">
      <div class="d-flex">
        <forms-builder-drag-icon
          class="align-self-center flex-shrink-1"
          color="grey"
          style="margin-bottom: 14px;"
        />
        <forms-builder-condition 
          :key="index"
          v-model="field.conditions[index]"
          :field="field"
          v-bind="$attrs"
        />
        <v-btn
          size="x-small"
          icon="mdi-close"
          color="red"
          variant="plain"
          @click="removeCondition(condition)"
        />
      </div>
    </template>

    <template #footer>
      <div class="d-flex flex-column">
        <div class="d-flex">
          <v-switch
            v-if="field.conditions?.length > 1"
            v-model="field.condition_join"
            class="ml-3"
            density="compact"
            single-line
            hide-details
            true-value="or"
            false-value="and"
            :label="field.condition_join == 'or' ? 'Match any' : 'Match all'"
          />
          <v-switch
            v-if="hasFormConditions"
            v-model="field.condition_skip_parent"
            class="ml-3"
            density="compact"
            single-line
            hide-details
            :label="field.condition_skip_parent ? 'Allow hidden parent(s)' : 'Check parent conditions'"
          />
        </div>
        <slot name="add" :add="addCondition">
          <v-btn
            size="small"
            variant="flat"
            @click="addCondition"
          >
            <v-icon>mdi-plus</v-icon>
            Add Condition
          </v-btn>
        </slot>
      </div>
    </template>

  </draggable>
</template>

<script setup>

const field = defineModel({
  type: Object,
  required: true
});

const props = defineProps({
  chips: Boolean
})

const { defaultCondition } = useFormBuilder();

onMounted(() => field.value.conditions ||= []);

const editing = ref(null);

const isEditing = computed(() => editing.value !== null && editing.value !== undefined);
const editingIndex = computed(() => field.value.conditions.findIndex(e => e.id == editing.value));
const hasFormConditions = computed(() => field.value.conditions.some(e => e.source == 'form'));

function addCondition() {
  field.value.conditions.push(defaultCondition());
  if (props.chips) {
    editing.value = field.value.conditions[field.value.conditions.length - 1].id;
  }
}

function summarise(condition) {
  if (! condition.field) return 'Choose field...'
  return `${condition.source}.${condition.field} (${condition.match?.toLowerCase()}) ${condition.value || ''}`;
}

function removeCondition(condition) {
  const index = field.value.conditions.findIndex(e => e.id == condition.id);
  if (index !== -1) {
    field.value.conditions.splice(index, 1);
  }
  editing.value = null;
}

function flipJoin() {
  field.value.condition_join = field.value.condition_join == 'or' ? 'and' : 'or';
}

</script>

<style scoped>
.ghost {
  visibility: hidden;
}
</style>