<template>
  
  <div>

    <p>The following fields have failed validation:</p>

    <p style="margin-left: 16px; margin-bottom: 16px;">
      <ul>
        <li v-for="field in displayableFields" :key="field">
          {{ field }}
        </li>
        <li v-if="remainder > 0">
          ... and {{ remainder }} more
        </li>
      </ul>
    </p>
    
    <p>Please check your input and try again.</p>

  </div>
  
</template>

<script setup>

const props = defineProps({
  fields: {
    type: Array,
    required: true
  },
  max: {
    type: Number,
    default: 5
  }
});

const displayableFields = computed(() => props.fields.slice(0, props.max));
const remainder = computed(() => props.fields.length - props.max);

</script>