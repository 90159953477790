<template>

  <div v-if="summary">
    <template v-if="node?.data?.data?.form_key">
      <v-chip
        color="white"
        variant="outlined"
      >
        <v-icon>{{ selectedForm.icon }}</v-icon>
        {{ selectedForm.key }}
      </v-chip>
    </template>
    <template v-else>
      <v-icon color="red">
        mdi-alert
      </v-icon>
      No form selected.
    </template>
  </div>

  <v-form v-else @submit.prevent>

    <v-container fluid>
      <v-row dense no-gutters>
        <v-col>
          <v-autocomplete 
            v-model="node.data.data.form_key"
            label="Form"
            :items="forms.forms"
            :loading="pending"
            :item-title="(item) => `${item.title} [${item.key}]`"
            item-value="key"
            chips
            closable-chips
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense no-gutters>
        <v-col>

          <div class="d-flex">

            <v-select
              v-model="node.data.data.config.global.entity_resolution"
              label="Entity Resolution"
              :item-props="true"
              :items="[
                { value: 'none', title: 'No entity required', subtitle: 'No entity required for this form' },
                { value: 'user', title: 'Applicant home entity', subtitle: 'Deduce home entity from user who filled in form' },
                { value: 'form', title: 'Form field', subtitle: 'Use a field from the form to deduce the home entity' },
              ]"
            />

            <v-autocomplete
              v-if="node.data?.data?.config?.global?.entity_resolution == 'form'"
              v-model="node.data.data.config.global.entity_field"
              :items="formFields"
              label="Form entity field"
              class="ml-2"
              :item-value="item => `${graphStore.nodeHoldingGlobalConfig.data.key}.${item.group}.${item.name}`"
              :item-title="item => `[${item.name}] ${item.label}`"
            />

          </div>

        </v-col>
      </v-row>

      <template v-if="selectedForm?.id">

        <v-row dense no-gutters>
          <v-col>
            <v-checkbox
              v-model="node.data.data.send_receipt"
              label="Send a generic submission receipt to the applicant"
              density="compact"
              hide-details
              class="mb-4"
            />
          </v-col>
        </v-row>

        <v-row dense no-gutters>
          <v-col>
            <v-checkbox
              v-model="node.data.data.update_summary"
              label="Update the form summary"
              density="compact"
              hide-details
              class="mb-4"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="node.data.data.update_summary"
          dense
          no-gutters
        >
          <v-col>
            <v-text-field
              v-model="node.data.data.form_summary"
              label="Override form summary"
              hint="{submission_field}, {form.field}, {user.field}, and {workflow.[title,id]} are available. Defaults to form setting if left blank."
              persistent-hint
            />
          </v-col>
        </v-row>

      </template>

      <dynamic-inputs v-model="node.data.data" :inputs="node.data.inputs" />

      <v-row v-if="formFields.length > 0">
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel :title="`Fields (${formFields.length})`">
              <v-expansion-panel-text>
                <v-chip-group column>
                  <v-chip
                    v-for="field in formFields"
                    :key="field.name"
                    size="small"
                  >
                    {{ field.name }}
                  </v-chip>

                  <v-chip size="small" @click="reloadForm">
                    <v-icon>mdi-reload</v-icon>
                  </v-chip>

                </v-chip-group>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

    </v-container>

  </v-form>

</template>

<script setup>

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: {} })
});

const props = defineProps({
  summary: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const graphStore = useGraphStore();
const componentStore = useComponentStore();
const { replaceField, groupFields, clearGroup } = useNodeFields(node);

onMounted(() => {
  node.value.data.data ||= {};
  node.value.data.data.config ||= {}
  node.value.data.data.config.global ||= {
    entity_resolution: 'user',
    entity_field: null,
    entity_source: null,
  }
  nextTick(() => reloadForm());
  watch(formKey, () => refresh());
});

const { data: forms, pending } = useApi('/admin/forms');

const formKey = computed(() => node.value?.data?.data?.form_key);
const formFields = computed(() => groupFields('data'));

const selectedForm = computed(() => {
  if (! formKey.value) return {};
  if (! forms.value.forms) return {};
  return forms.value.forms?.find(form => form.key === formKey.value) || {};
});

async function reloadForm() {

  if (!selectedForm.value?.id) return;

  const response = await usePortalStore().get(`/admin/forms/${selectedForm.value.id}`);
  const index = forms.value.forms.findIndex(e => e.id === response.form.id);
  if (index === -1) {
    forms.value.forms.push(response.form);
  } else {
    forms.value.forms.splice(index, 1, response.form)
  }
  refresh();
}

function refresh() {

  if (props.summary) return;

  if (selectedForm.value?.id) {

    const { allFields } = useFormBuilder(selectedForm.value);

    node.value.data.data.form_summary ||= selectedForm.value.definition.summary;

    clearGroup('submission');
    allFields.value.forEach(e => {
      replaceField({
        group: 'submission',
        name: e.name, 
        label: e.label, 
        type: componentStore.fieldCastType(e)
      });
    });

  }

}

defineExpose({ refresh})

</script>