<template>
  <div>

    <ContactName
      v-if="$attrs.readonly"
      :user-id="$attrs.modelValue"
      as-field
    />

    <v-autocomplete
      v-else
      v-bind="$attrs"
      :items="items"
      :placeholder="$attrs.placeholder || 'Please select an option - click or start typing to search'"
      :item-title="studentName"
      item-value="id"
      :loading="loading"
      @update:model-value="$emit('update:modelValue', $event)"
    >

      <template #item="{ props, item }">
        <v-list-item 
          v-bind="props"
          :title="`${item.raw.last_name}, ${item.raw.first_name}`"
          :subtitle="`${item.raw.meta.studentNumber}`"
        />
      </template>

    </v-autocomplete>

    <Debug title="items">
      {{ items }}
    </Debug>
  </div>
</template>

<script>
export default {

  emits: ['update:modelValue'],

  data() {
    return {
      items: [],
      loading: true
    }
  },

  config: {
    defaults: {
      options: {
        type: 'custom',
      }
    },
    dataSource: {
      allowMultiselect: true,
      cast: 'string'
    }
  },

  computed: {
    fieldOptions() {
      return this.$attrs.fieldOptions || { config: {} };
    }
  },

  created() {
    if (!this.$attrs.readonly) {
      this.load();
    }
  },

  methods: {

    async load() {
      console.log('hdr attrs', this.$attrs);
      this.loading = true;
      try {
        const endpoint = '/hdr/supervision/students';
        const options = {}
        if (this.fieldOptions.config.only_supervised) {
          options.supervisor_id = this.portal.user.id;
          if (this.fieldOptions.config.only_primary) {
            options.only_primary = true;
          }
        }
        this.items = (await this.$portal.get(endpoint, options)).students
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }

    },

    studentName(item) {
      return `${item.last_name}, ${item.first_name} (${item.meta.studentNumber})`;
    }

  }

}
</script>