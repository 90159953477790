import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((nuxtApp) => {

  nuxtApp.vueApp.use(Toast, {
    hideProgressBar: false,
    position: POSITION.TOP_CENTER,
    maxToasts: 20,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
      if (toasts.filter(t => t.type == toast.type && t.message == toast.message).length !== 0) {
        return false;
      }
      return toast;
    }
  });
});
