<template>

  <v-alert v-if="fields.length < 1" type="warning">
    This node has no fields available. Make sure it is connected the the output of a previous node.
  </v-alert>
  
  <v-alert v-if="! currentNode" type="error">
    I don't know which node you are connected to!
  </v-alert>

  <v-autocomplete
    v-else
    label="Field"
    auto-select-first
    autofocus
    :items="fields"
    hint="If the step fields are out of date, open and close the source node config."
    persistent-hint
    class="ma-0 pa-0"
    v-bind="$attrs"
  >
    <template #item="data">
      <v-list-subheader v-if="data.props.header">
        {{ data.props.header }}
      </v-list-subheader>
      <v-divider v-else-if="data.props.divider" />
      <v-list-item
        v-else
        v-bind="data.props"
        :title="onlyCurrent ? data.item.raw.field : data.item.raw.path"
      />
    </template>

    <template #selection="data">
      {{ onlyCurrent ? data.item.raw.field : data.item.raw.path }}
    </template>

    <template v-for="name in Object.keys($slots).filter(e => e != 'default')" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>

  </v-autocomplete>
</template>

<script setup>

import { uniq } from 'lodash-es';

const props = defineProps({
  node: {
    type: Object,
    required: false,
    default: undefined
  },
  nodeId: {
    type: String,
    required: false,
    default: undefined
  },
  includeCurrent: {
    type: Boolean,
    default: true
  },
  onlyCurrent: Boolean,
});

const graphStore = useGraphStore();

const showingHelp = ref(false);

const currentNode = computed(() => props.node || props.nodeId || graphStore.currentNode);

const fields = computed(() => {

  const result = [];

  if (props.onlyCurrent) {
    result.push(...graphStore.nodeFields(currentNode.value));
  } else {
    
    const fields = graphStore.availableFields(currentNode.value);
    const sources = uniq(fields.map(e => e.source));
    
    let divide = false;
    sources.forEach(source => {
      
      const sourceFields = fields
        .filter(e => props.includeCurrent ? e : e.source != currentNode.value.data.key)
        .filter(e => e.source == source);

      if (sourceFields.length > 0) {
        if (divide) result.push({ props: { divider: true } });  
        result.push({ props: { header: source } });
        divide = true;
      }

      result.push(...sourceFields);

    });

  }

  return result;
  
});

</script>