<template>

  <div v-if="graphStore.currentNode">

    <v-icon v-bind="$attrs" @click="show">
      mdi-vector-polyline
    </v-icon>

    <v-dialog v-model="showing" :width="width">
      <v-sheet>
        <workflow-field-select
          v-model="model"
          :node="graphStore.currentNode"
          class="ma-2 pa-2"
          :hint="hint"
          @update:model-value="updated"
        />
        <div class="ma-2 pa-2">
          <v-icon color="grey" @click="showingHelp = !showingHelp">
            mdi-information
          </v-icon>
          <v-alert v-if="showingHelp">
            <small>
              For Object or Hash fields, you can append <strong>#attribute</strong> to lookup a key. For example:
              <strong><i>form_submission.form.applicant#email</i></strong> will lookup the <strong>email</strong> attribute of the <strong>user</strong> field.
            </small>
          </v-alert>
        </div>
      </v-sheet>
    </v-dialog>

  </div>
</template>

<script setup>

import { useParentElement } from '@vueuse/core'

const appendTo = defineModel({
  type: String,
  required: false,
  default: ''
});

const props = defineProps({
  width: {
    type: String,
    default: '50%'
  },
  hint: {
    type: String,
    required: false,
    default: 'Field will be inserted at the current cursor position'
  },
  onAccept: {
    type: Function,
    required: false,
    default: undefined
  },
  noDelimiters: Boolean,
});

const graphStore = useGraphStore();
const parentElement = useParentElement();

const model = ref('');
const showing = ref(false);
const showingHelp = ref(false);

defineEmits(['accept']);

const text = computed(() => {
  if (isEmpty(model.value)) {
    return '';
  }
  return props.noDelimiters ? model.value : `{{ ${model.value} }}`
});

function updated() {
  if (props.onAccept) {
    props.onAccept(text.value);
  } else {
    patch();
  }
  close();
}

function patch() {

  //TODO: probably won't work for textareas
  const el = parentElement.value.parentElement.querySelector('input');
  if (el) {
    const val = el.value;
    
    const a = val.substring(0, el.selectionStart);
    const b = val.substring(el.selectionEnd);
    appendTo.value = `${a}${text.value}${b}`;

    const newPos = el.selectionEnd + text.value.length;
    nextTick(() => {
      el.focus();
      el.setSelectionRange(newPos, newPos);
    });
  } else {
    //above should always work, but just in case
    appendTo.value = `${appendTo.value || ''}${text.value}`;
  }
}

function show() {
  model.value = null;
  showing.value = true
}

function close() {
  showing.value = false
}

defineExpose({ show, close })


</script>