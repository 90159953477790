<template>

  <div v-if="summary">
    <v-chip-group column>
      <v-chip 
        v-for="(field, f) in fields"
        :key="f"
        size="small"
        variant="outlined"
        prepend-icon="mdi-cog"
      >
        {{ field.label || field.name }}
      </v-chip>
    </v-chip-group>
  </div>

  <div v-else>

    <select-list
      v-model="fields"
      heading="Incoming fields"
      v-bind="$attrs"
      min="1"
      class="pa-2 ma-2"
      :default-value="{ name: '', label: '', type: 'String' }"
    >
      
      <template #item="{ index, rules }">
        <v-row>
          <v-col>
            <v-text-field
              v-model="fields[index].name"
              label="Name"
              :rules="[rules.required, rules.unique]"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="fields[index].label"
              label="Label"
              hint="Label (optional)"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="fields[index].type"
              label="Type"
              hint="Ruby type ('Boolean' will work)"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

      </template>

    </select-list>

  </div>

</template>

<script setup>

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({})
});

defineProps({
  summary: Boolean,
});

defineExpose({
  directlyManagesFields: true
});

const { fields } = useNodeFields(node);

</script>