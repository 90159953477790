
<template>

  <div>

    <template v-if="summary">
      <template v-if="! node.data.data.job_class">
        <v-icon color="red">
          mdi-alert
        </v-icon>
        No job class assigned
      </template>
      <template v-else>
        <v-chip>
          <v-icon>mdi-clock</v-icon>
          {{ node.data.data.job_class }}
        </v-chip>
        <v-chip v-if="node.data.data.job_args?.length">
          <v-icon>mdi-cog</v-icon>
          {{ node.data.data.job_args.length }} arg(s)
        </v-chip>
      </template>
    </template>

    <v-form v-else @submit.prevent>
      <job-select 
        v-model="node.data.data.job_class"
        v-model:args="node.data.data.job_args"
        v-model:outputs="outputs"
      />
    </v-form>
  </div>
</template>

<script setup>

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: { job_args: {} } })
});

const props = defineProps({
  summary: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const outputs = ref([]);

function refresh() {

  if (props.summary) return;

  const { replaceField } = useNodeFields(node);
  outputs.value?.forEach(output => {
    replaceField({
      group: 'result',
      name: output.name, 
      label: output.label || output.name,
      type: output.type 
    });
  })
}

defineExpose({ refresh });

</script>