<template>

  <v-form @submit.prevent>
    <v-table>
      <tbody>

        <dynamic-inputs
          v-model="node.data.data"
          :inputs="node.data.inputs"
          table
        />

        <tr>
          <td width="15%">
            Colour
          </td>
          <td>
            <v-color-picker 
              v-model="node.data.data.attributes.color" 
              hide-canvas
              show-swatches
              mode="hex"
              :swatches="swatches"
            />
          </td>
        </tr>

        <tr>
          <td>Test</td>
          <td>
            <v-btn :color="node.data.data.attributes.color">
              {{ node.data.title }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-form>

</template>

<script setup>

import { useTheme } from "vuetify";

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: {} })
});

defineProps({
  summary: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

onBeforeMount(() => {
  node.value.data.data ||= {}
  node.value.data.data.attributes ||= {}
  node.value.data.data.attributes.color ||= node.value.data.attributes.color;
  node.value.data.data.fields = [{
    name: 'event', 
    label: 'Event', 
    type: 'String'
  }];
});

const { current } = useTheme();

const swatches = [
  [current.value.colors.success, current.value.colors.primary],
  [current.value.colors.info, current.value.colors.secondary],
  [current.value.colors.warning, "#FFDF00"],
  ['#FF0000', '#0000FF']
]

</script>