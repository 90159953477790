<template>
  <v-checkbox 
    v-bind="$attrs"
    :true-value="trueValue"
    :false-value="falseValue"
    density="compact"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </v-checkbox>
</template>

<script>
export default {

  config: {
    isTrueFalse: true,
    instructionsBelow: true,
    inlineLabel: true,
    dataSource: {
      cast: 'boolean',
    }
  }

}

</script>

<script setup>

const props = defineProps({
  field: {
    type: Object,
    required: false,
    default: () => ({
      options: {
        type: 'boolean',
      }
    })
  }
});

const isBoolean = computed(() => props.field.options.type === 'boolean');
const trueValue = computed(() => isBoolean.value ? true : props.field.options.trueValue);
const falseValue = computed(() => isBoolean.value ? false : props.field.options.falseValue);

</script>