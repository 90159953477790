/* eslint-disable no-undef */
export const usePortalStore = defineStore("portal", {
  
  state: () => ({

    //user and their things
    user: {},
    applications: [],
    notifications: [],

    //impersonation stuff form admins
    //see usePortalHeaders for how this is used
    impersonateUsername: null,
    original_user: null,

    //the current application being used, determined by
    //matching the route with the app path
    application: null,

    //config from the server
    config: {
      direct_upload_url: null,
    },

    //messages
    narrative: null, //ie loading footer message
    defaultErrorMessage: "Something has gone wrong with this page. Please contact support@divinity.edu.au",

    //some global state
    initialised: false,
    debug: false,

  }),

  getters: {
    //spelling variation
    initialized: (state) => state.initialised,
    admin: (state) => [state.user, state.original_user].filter(e => e).some(e => e?.role_names?.includes('it_admin')),
  },

  actions: {

    limitedNotifications(max) {
      return [
        ...this.notifications.filter(e => e.urgent), 
        ...this.notifications.filter(e => ! e.urgent)
      ]?.slice(0, max) || [];      
    },

    ensureInitialised(markInitialised = true) {
      if (! this.initialised) {
        return this.init(markInitialised);
      } else {
        console.debug("Portal already initialised");
      }
      return Promise.resolve();
    },

    /**
     * Get the user profile, allowed apps, and initial notifications, etc.
     * and set up our action cable subscriptions.
     */
    async init(markInitialised = true) {
      console.debug("Initialising portal...");
      
      //FIXME: handle server down and retry

      return this.fetchProfile().then(() => {
        if (markInitialised) {
          this.initialised = true;
        }
        //TODO: auto-register these as plugins
        useCable("PresenceChannel", presenceConsumer);
        useCable("NotificationChannel", notificationConsumer);
      });
    },

    markInitialised() {
      this.initialised = true;
    },

    impersonate() {
      this.init();
    },

    stopImpersonating() {
      this.impersonateUsername = null;
      this.init();
    },

    clearNotifications() {
      this.notifications = [];
    },

    /**
     * Get user, their allowed apps, and current notifications and misc config.
     */
    async fetchProfile() {
      console.debug("Fetching user profile...");
      return this.get("/users/profile").then((response) => {
        this.user = response.user;
        this.original_user = response.original_user;
        this.notifications = response.notifications;
        this.config = response.config;
        this.roles = response.roles;

        const paths = useRouter().getRoutes().map(e => e.path);
        this.applications = response.applications
          .filter(e => ! e.hidden)
          .filter(e => e.path.includes('://') || paths.includes(e.path));

        const missingApps = response.applications.filter(e => !this.applications.includes(e));
        if (missingApps.length > 0) {
          console.warn(`Applications detected with missing routes`, missingApps.map(e => e.key));
        }

        console.debug(`Fetched profile for ${this.user.first_name}`, response);
      });
    },

    detectApplication(path, recursive = true) {

      let application = this.findApplication(path);
      if (! application && recursive) {
    
        const parts = path.split("/").filter(e => e)
        let n = parts.length
        
        while (n > 0) {
          const subpath = "/" + parts.slice(0, n).join("/")
          const found = this.findApplication(subpath);
          if (found) {
            application = found;
            break;
          }
          n--;
        }
      
      }

      if (application) {
        this.application = application;
      }

      return application;

    },

    findApplication(path) {
      return this.applications.find((e) => e.landing_page == path || e.path == path || e.config?.routes?.includes(path));
    },

    /**
     * Direct get, without nuxt caching stuff.
     *
     * To fetch in a view, use `useApi` directly for the fancy magic stuff.
     *
     * @param {String} endpoint
     */
    async get(endpoint, query = {}, extra = {}) {
      return this.request(
        endpoint,
        {
          method: "get",
          query,
          ...extra.options
        },
        extra
      );
    },

    async post(endpoint, data, extra = {}) {
      return this.request(
        endpoint,
        {
          method: "post",
          body: data,
          ...extra.options
        },
        extra
      );
    },

    async patch(endpoint, data, extra = {}) {
      return this.request(
        endpoint,
        {
          method: "patch",
          body: data,
          ...extra.options
        },
        extra
      );
    },

    async delete(endpoint, extra = {}) {
      return this.request(
        endpoint,
        {
          method: "delete",
          ...extra.options
        },
        extra
      );
    },

    async request(endpoint, options, extra = {}) {
      
      const config = useRuntimeConfig();
      const cache = useCacheStore();

      this.narrative = extra.narrative ?? endpoint;

      try {

        const payload = {
          ...options,
          baseURL: config.public.backendUrl,
          headers: {
            ...usePortalHeaders(),
            ...options.headers,
          },
        };

        const cacheKey = requestCacheKey(endpoint, options);

        if (extra.cacheTTL && options.method.toLowerCase() === "get") {
          const cached = cache.get(cacheKey);
          if (cached) {
            console.debug(`portal.request[${options.method}](${endpoint}): returning cached value`, cached);
            return cached;
          }
        }

        const response = await $fetch(endpoint, payload);
        console.debug(
          `>>> portal.request[${options.method}](${endpoint})`,
          payload,
          response
        );

        if (extra.cacheTTL && options.method.toLowerCase() === "get") {
          cache.set(cacheKey, response, extra.cacheTTL);
        }

        //pull out any messages
        if (extra.messages === false) {
          return response;
        }

        return this.processResponse(response);

      } catch (exception) {
        if (!this.processException(endpoint, options, exception)) {
          throw exception;
        }
      } finally {
        this.narrative = null;
      }
    },

    processResponse(response) {
      if (response) {
        const messageStore = useMessageStore();

        response.info && messageStore.info(response.info);
        response.message && messageStore.success(response.message);
        response.success && messageStore.success(response.success);
        response.warning && messageStore.warning(response.warning);

        //controller should throw an exception to send error messages
      }

      return response;
    },

    processException(endpoint, options, exception) {

      const messageStore = useMessageStore();
      console.error(`portal.request[${endpoint}]`, exception.data, options);

      //remove previous errors so they don't stack
      messageStore.clearSticky();

      //TODO: key by URL and clear errors on success
      if (exception.data?.friendly) {
        messageStore.error(exception.data.error);
      } else {
        messageStore.error(this.defaultErrorMessage);
      }

      //return true to stop error propagation
      return false;
    },
  },
});

//this is for dev-tools...
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePortalStore, import.meta.hot));
}
