<template>
  <v-form
    ref="formRef"
    v-model="valid"
    @submit.prevent
  >

    <json-tree v-if="$portal.debug" :value="task" />

    <v-table>
      <tbody>

        <template v-if="! hideHeader">

          <tr>
            <th>
              ID
            </th>
            <td>{{ task.id }}</td>
            <th>
              Status
            </th>
            <td width="15%">
              {{ task.status }}
            </td>
          </tr>

          <tr>
            <th>
              Task
            </th>
            <td colspan="3">
              {{ task.title }}
            </td>
          </tr>

          <tr v-if="task.detail">
            <th>
              Detail
            </th>
            <td colspan="3">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="task.detail" /></td>
          </tr>

        </template>

        <template v-if="! hideFiles && task.files?.length">
          <tr>
            <th>
              Files
            </th>
            <td colspan="3">
              <file-download 
                v-for="(file, f) in task.files" 
                :key="`file-${f}`"
                :file="file"
                variant="chip"
              />
            </td>
          </tr>
        </template>

        <tr v-if="task.actions && tasksAbove">
          <th>{{ actionsLabel }}</th>
          <td colspan="3">
            <task-actions
              v-model="taskResponse"
              :task="task"
              :type="actionsStyle"
              :disabled="disableActions"
            />
          </td>
        </tr>

        <template v-if="showForm">

          <tr v-if="inputs?.length > 0">
            <th
              style="vertical-align: top; padding-top: 12px;"
            >
              {{ responseLabel }}
            </th>
            <td colspan="3">
              
              <!-- we might have these from a job -->
              <dynamic-inputs 
                v-model="taskResponse"
                :inputs="inputs" 
                :disabled="task.finished || pending"
              />
            
            </td>
          </tr>

          <!-- forms are integrated directly into the table -->
          <tr v-for="(field, f) in filteredColumns" :key="`field-row-${f}`">
            <th>{{ field.component.includes('Checkbox') ? '' : field.label }}</th>
            <td colspan="3">
              <forms-fields-form-field 
                :key="`field-${field.id}`"
                v-model="taskResponse[field.name]"
                :field="field"
                v-bind="bindableAttributes[field.name]"
                :disabled="task.finished || pending"
                style="margin-top: 16px" 
                plain
              />
            </td>
          </tr>

        </template>

        <tr v-if="task.actions && ! tasksAbove">
          <th>{{ actionsLabel }}</th>
          <td colspan="3">
            <task-actions
              v-model="taskResponse"
              :task="task"
              :disabled="disableActions"
            />
          </td>
        </tr>

        <tr>
          <th>{{ finaliseLabel }}</th>

          <td colspan="3">

            <v-btn
              v-if="canFinalise"
              v-tooltip="selectedAction.description"
              :color="selectedAction.attributes?.color || selectedAction.color"
              :disabled="disableSubmit"
              variant="elevated"
              @click="execute"
            >
              Confirm {{ selectedAction.label }}
            </v-btn>

            <span v-else>
              Please select a response above
            </span>

          </td>
        </tr>

      </tbody>
    </v-table>

  </v-form>
</template>

<script setup>

const taskResponse = defineModel({
  type: Object,
  required: true,
  default: () => ({})
});

const props = defineProps({
  task: {
    type: Object,
    required: true,
    default: () => ({})
  },
  data: {
    type: Object,
    required: true,
    default: () => ({})
  },
  actionsLabel: {
    type: String,
    default: 'Actions'
  },
  responseLabel: {
    type: String,
    default: 'Response'
  },
  finaliseLabel: {
    type: String,
    default: 'Finalise'
  },
  actionsPosition: {
    type: String,
    default: undefined
  },
  actionsStyle: {
    type: String,
    default: undefined
  },
  startFormHidden: Boolean,
  pending: Boolean,
  hideHeader: Boolean,
  hideFiles: Boolean
});

const emit = defineEmits(['execute']);

const valid = ref(null);
const formRef = ref({});

/**
 * Data needed to fulfil form conditions.
 */
const workflow = computed(() => ({
  response: taskResponse.value, //for self-referencing tokens
  ...props.task?.data?.workflow, //to fulfil tokens from previous steps
}));

const inputs = computed(() => props.task?.data?.inputs || []);
const form = computed(() => props.task?.data?.form || {});
const { filteredColumns, bindableAttributes } = useFormBuilder(form, workflow, props.data);
const disableActions = computed(() => props.task.finished || props.pending);
const disableSubmit = computed(() => disableActions.value || (requiresValidation.value && valid.value === false));
const requiresValidation = computed(() => inputs.value.length > 0 || filteredColumns.value.length > 0);
const tasksAbove = computed(() => props.actionsPosition == 'above' || props.task?.data?.action_position == 'above');
const selectedAction = computed(() => props.task?.actions?.find(e => [e.key, e.event].includes(taskResponse.value?.action)));
const canFinalise = computed(() => ! props.task?.finished && taskResponse.value.action);
const showForm = computed(() => ! props.startFormHidden || taskResponse.value.action);

async function execute() {

  const validation = await useFormValidation(formRef);
  if (validation.valid !== true) {
    return;
  }

  emit('execute');

}

</script>

<style scoped>
.info-table {
    margin-top: 35px;
    margin-bottom: 35px;
}

th {
  width: 15%;
  background-color: #f5f5f5;
  font-weight: bold !important;
  vertical-align: top;
  padding-top: 10px !important;
}

</style>