import { useApi } from './useApi';

/**
 * Use this instead of portal.get for fancy nuxt stuff.
 * 
 * @param {String} request 
 * @param {Object} options 
 * @param {Object} extra 
 * @param {Number} cacheTTL default 6 hours
 */
export const useCachedApi = (request, options={}, extra={}, cacheTTL = 6 * 60 * 60 * 1000) => {
  if (extra.cacheTTL) {
    console.warn('useCachedApi: extra.cacheTTL is not needed with useCachedApi, use the cacheTTL argument instead');  
  }
  extra.cacheTTL = cacheTTL;
  return useApi(request, options, extra);
}