<template>
  <v-autocomplete
    :items="data.workflow_roles"
    :loading="pending"
    item-value="id"
    item-title="title"
  >
    <template #selection="{ item }">

      <v-chip size="small">
        {{ item.title }}
      </v-chip>

      <v-icon>mdi-arrow-right</v-icon>

      <v-chip
        v-if="item.raw.role_name"
        variant="elevated"
        color="blue"
        size="small"
      >
        <v-icon>mdi-account-search</v-icon>
        {{ item.raw.role_name }}
      </v-chip>

      <v-icon v-if="item.raw.role_name && item.raw.contact_uuid">
        mdi-chevron-double-right
      </v-icon>

      <v-chip
        v-if="item.raw.contact_uuid"
        variant="elevated"
        color="green"
        size="small"
      >
        <v-icon>mdi-account</v-icon>
        <contact-name :key="item.raw.contact_uuid" :user-id="item.raw.contact_uuid" />
      </v-chip>
      <span v-if="item.raw.data?.overrides?.length" style="margin-left: 6px;">
        ({{ item.raw.data?.overrides?.length }} override{{ item.raw.data?.overrides?.length == 1 ? '' : 's' }})
      </span>

    </template>

    <template #item="{ props, item }">
      <v-list-item
        v-bind="props"
        :title="item.title"
        :subtitle="item.raw.description"
      />
      
    </template>

  </v-autocomplete>
</template>

<script setup>

const { data, pending } = useCachedApi(`admin/workflow_roles`);

</script>