import { defineNuxtRouteMiddleware, abortNavigation } from "#app";
import { usePageStore } from "#imports";

export default defineNuxtRouteMiddleware((to) => {

  const page = usePageStore();

  //pages which update PortalPage:dirty prop must clear this prop 
  //before initiating navigation, eg by calling usePageStore().$reset();
  if (page.dirty) {
    page.target = to;
    page.confirmingCancel = true;
    return abortNavigation();
  }

});