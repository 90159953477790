<template>
  <v-textarea
    variant="outlined"
    :auto-grow="$attrs.autoGrow || $attrs.readOnly"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </v-textarea>
</template>

<script>
export default {
  config: {
    description: 'A standard textarea',
    dataSource: {
      cast: 'string',
    }
  }
}
</script>