<template>

  <div v-if="summary">
    <v-chip
      v-if="isModel && node.data.data.model_class"
    >
      <v-icon>mdi-database</v-icon>
      {{ node.data.data.model_class }}
    </v-chip>

    <v-chip
      v-else-if="isJob && node.data.data.job_class"
    >
      <v-icon>mdi-cog</v-icon>
      {{ node.data.data.job_class }}
    </v-chip>

    <template v-else>
      <v-icon color="red">
        mdi-alert
      </v-icon>
      No strategy defined.
    </template>

  </div>

  <v-form v-else @submit.prevent>
    <v-container fluid>

      <v-row>
        <v-col>
          <v-radio-group
            v-model="node.data.data.implementation_type"
            label="Integration Strategy"
            inline
          >
            <v-radio label="Model" value="model" />  
            <v-radio label="Job" value="job" />
            <!-- <v-radio label="Service" value="service" /> -->
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete 
            v-if="node.data.data.implementation_type === 'model'"
            v-model="node.data.data.model_class"
            label="Model Class"
            :items="data.model_classes"
            clearable
          />

          <job-select
            v-else-if="node.data.data.implementation_type === 'job'"
            v-model="node.data.data.job_class"
            v-model:args="node.data.data.job_args"
            v-model:outputs="outputs"
            requires-inputs
          />

          <!-- <workflow-service-class-select
            v-else-if="node.data.data.implementation_type === 'service'"
            v-model="node.data.data.service_class"
            v-model:method="node.data.data.service_method"
            :items="data.service_classes"
          /> -->

        </v-col>
      </v-row>

    </v-container>

  </v-form>

</template>

<script setup>

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: {} })
});

const props = defineProps({
  summary: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const outputs = ref([]);

const isModel = computed(() => node.value.data.data.implementation_type == 'model');
const isJob = computed(() => node.value.data.data.implementation_type == 'job');

onMounted(() => {
  node.value.data.data.implementation_type ||= 'model';
  node.value.data.data.job_class ||= '';
  node.value.data.data.model_class ||= '';
  node.value.data.data.job_args ||= {};
});

function refresh() {

  if (props.summary) return;

  const { replaceField, clearGroup } = useNodeFields(node);

  clearGroup('result');

  if (isModel.value) {
    replaceField({
      group: 'result',
      name: 'model', 
      label: node.value.data.data.model_class,
      type: node.value.data.data.model_class
    });
  }

  if (isJob.value) {
    outputs.value?.forEach(output => {
      replaceField({
        group: 'result',
        name: output.name, 
        label: output.label || output.name,
        type: output.type 
      });
    })
  }

}

defineExpose({ refresh })

</script>