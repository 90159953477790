import { useMessageStore } from "~/stores/message";
import { toValue } from "vue";
import ErrorToast from '~/components/ErrorToast.vue'

export const useFormValidation = async (form, showErrors = true, resetErrors = true) => {

  const messages = useMessageStore();
  if (resetErrors) {
    messages.clearSticky();
  }

  const validation = await toValue(form).validate();
  if (showErrors && validation.valid === false) {

    const fields = [... new Set(validation.errors
      .map(e => document.querySelectorAll(`[for="${e.id}"]`)[0]?.textContent)
    )].filter(e => e);
    
    if (fields.length) {
      messages.error({
        component: ErrorToast,
        props: {
          fields: fields,
        }
      });
    } else {
      messages.error(`There are ${validation.errors.length} fields that need attention. Please check any error messages and try again.`);
    }

  }

  return validation;

}