<template>
  <div>

    <v-progress-circular
      v-if="pending"
      indeterminate
      size="15"
      width="2"
    />

    <slot
      name="display"
      :user="user"
      :text="text"
    >
      <v-text-field
        v-if="asField"
        :model-value="text"
        v-bind="$attrs"
        disabled
      />

      <v-chip v-else-if="chip">
        {{ text }}
      </v-chip>

      <span v-else>{{ text }}</span>
    </slot>

    <Debug raw title="data">
      {{ data }}
    </Debug>

  </div>
</template>

<script setup>

const props = defineProps({
  userId: {
    type: [String, Number],
    required: true
  },
  chip: Boolean,
  asField: Boolean,
  plain: Boolean,
  showUsername: Boolean,
});

const user = ref({});
const { data, pending } = useCachedApi(`users/lookup/${props.userId}`); 

const text = computed(() => {
  if (! toValue(data)?.id) {
    return 'Loading...';
  }
  if (props.showUsername) {
    return toValue(data).username;
  } else {
    if (props.plain) {
      return `${toValue(data).first_name} ${toValue(data).last_name}`;
    }
    return `${toValue(data).last_name}, ${toValue(data).first_name}`;
  }
});

</script>