<template>

  <div v-if="task.finished">
    <v-chip tile :color="lastResponseAction?.color">
      <v-icon>mdi-check</v-icon>
      {{ lastResponseAction?.key }}
    </v-chip>
    You actioned this task as <b>{{ lastResponseAction?.key }}</b> on {{ formatDate(task.last_response.created_at) }}
  </div>

  <task-action-select
    v-else-if="asList"
    v-model="taskResponse.action"
    :items="task.actions"
    :disabled="disableActions"
    style="margin-top: 12px;"
  />
  
  <task-action-buttons
    v-else
    :items="task.actions"
    :disabled="disableActions"
    @execute="taskResponse.action = $event"
  />

</template>

<script setup>

const taskResponse = defineModel({
  type: Object,
  required: true,
  default: () => ({})
});

const props = defineProps({
  task: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: false,
    default: undefined
  },
  disabled: Boolean
});

const lastResponseAction = computed(() => props.task.actions.find(e => e.key == props.task.last_response?.action));
const disableActions = computed(() => props.disabled || props.task.finished);
const actionStyle = computed(() => props.type || props.task.data.action_style);
const asList = computed(() => actionStyle.value == 'list');

</script>