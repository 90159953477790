<template>
  <v-radio-group
    inline
  >
    <v-radio value="Yes" label="Yes" />
    <v-radio value="No" label="No" />
  </v-radio-group>
</template>

<script>
export default {

config: {
  rules: ['required'],
  conditions: ['any', 'none', 'check', 'ncheck'],
  dataSource: {
    cast: 'boolean',
    options: ['Yes', 'No']
  }
}

}
</script>