<template>
  
  <div @click="cancel">
    Click the item you want to connect to, or click me to cancel.
  </div>
  
</template>

<script setup>

const graphStore = useGraphStore();

function cancel() {
  graphStore.interactiveConnectCancel();
}

</script>