<template>

  <v-autocomplete
    v-model="model"
    v-model:search="search"
    :items="items"
    :loading="loading"
    :no-data-text="noDataText"
    :item-value="keyBy"
    :multiple="multiple"
    :chips="multiple"
    :closable-chips="multiple"
    auto-select-first
    v-bind="$attrs"
  >

    <template #item="{ props: listProps, item }">
      <v-list-item 
        v-bind="listProps"
        :title="item.raw.title"
        :subtitle="withDescription ? item.raw.description : null"
      >
        <template v-if="multiple" #prepend="{ isSelected }">
          <v-checkbox-btn
            :key="item.value"
            :model-value="isSelected"
            :ripple="false"
            tabindex="-1"
          />
        </template>
      </v-list-item>
    </template>

    <template #chip="{ item, props: chipProps }">
      <v-chip v-bind="chipProps">
        {{ itemLabel(item) }}
      </v-chip>
    </template>

    <template v-if="showMoreHint" #append-item>
      <v-list-item
        density="compact"
        disabled
      >
        <v-list-item-title>
          <small>Type to search {{ remaining }} more...</small>
        </v-list-item-title>
      </v-list-item>
    </template>

  </v-autocomplete>

  <debug title="list model" raw>
    {{ model }}
  </debug>
  <debug title="list">
    {{ items }}
  </debug>

</template>

<script setup>

import { refDebounced, whenever } from '@vueuse/core'
import { sortBy, uniqBy } from 'lodash-es'

const model = defineModel({
  type: [String, Number, Array],
});

onBeforeMount(() => {
  if (model.value === '') {
    model.value = null;
  }
});

const props = defineProps({
  listName: {
    type: String,
    required: true,
  },
  keyBy: {
    type: String,
    required: false,
    default: 'key'
  },
  minChars: {
    type: Number,
    required: false,
    default: 3
  },
  debounce: {
    type: Number,
    required: false,
    default: 500
  },
  limit: {
    type: [Number, String],
    required: false,
    default: 25
  },
  simple: {
    type: Boolean,
    required: false,
    default: true
  },
  searchFields: {
    type: Array,
    required: false,
    default: () => ['title']
  },
  cacheTtl: {
    type: Number,
    required: false,
    default: 300
  },
  withDescription: Boolean,
  multiple: Boolean,
  showMissingValue: Boolean
});

const portal = usePortalStore();

const MAX_SEARCH_CACHE = 50;

const search = ref(null);
const debouncedSearch = refDebounced(search, props.debounce);
const items = ref([]);
const loading = ref(false);
const loaded = ref(false);
const listCount = ref(0);
const searched = ref([]);

const showMoreHint = computed(() => ! fullyLoaded.value && ! search.value);
const searching = computed(() => loading.value || (! loaded.value && model.value));
const fullyLoaded = computed(() => items.value.length >= listCount.value);
const remaining = computed(() => listCount.value - items.value.length);
const searchIsSelection = computed(() => model.value && items.value.some(item => item.title === search.value));
const searchable = computed(() => debouncedSearch.value && debouncedSearch.value.length >= props.minChars);
const noDataText = computed(() => {
  
  if (searching.value) {
    return 'Loading...';
  }

  if (! fullyLoaded.value && ! searchable.value) {
    return `Enter at least ${props.minChars} characters`;
  }

  if (searchable.value && ! fullyLoaded.value) {
    return `No results found for '${search.value}'`;
  }

  return 'No results found';

});

function itemLabel(item) {
  
  if (searching.value) {
    return 'Loading...';
  }

  //only items found in the list will have a key
  if (item?.raw?.key) {
    return item.raw.title;
  }

  if (props.showMissingValue) {
    return `(Invalid item: '${model.value}')`;
  }

  return '(Invalid item)';

}

/**
 * The initial item load
 */
onMounted(() => doSearch());

/**
 * If items are all loaded but the model is missing, load that too
 */
const missing = computed(() => loaded.value && ! isEmpty(model.value) && ! items.value.some(item => item[props.keyBy] == model.value));
const cancelModelLoadWatcher = whenever(missing, () => {
  doSearch(model.value);
  cancelModelLoadWatcher(); //only run this once - if the model is still missing after the search, it's invalid
});

watch(debouncedSearch, () => {
  //search if there's a minimum search term and nothing selected
  if (searchable.value && ! fullyLoaded.value && ! searchIsSelection.value) {
    doSearch();
  }
});

async function doSearch(selected = undefined) {
  try {

    if (! selected && searched.value.includes(debouncedSearch.value)) {
      // console.debug('[list-item-select] already searched that, quitting', debouncedSearch.value);
      return;
    }

    loading.value = true;
    const query = { 
      selected,
      selected_field: props.keyBy,
      search: debouncedSearch.value, 
      limit: props.limit,
      simple: props.simple
    };

    const response = await portal.get(`/list_items/${props.listName}`, query, { cacheTTL: props.cacheTtl });
    // console.debug(`**** [list-item-select] FETCH: ${props.listName}(selected:${selected}) ****`, query, response);

    items.value = sortBy(
      uniqBy([...items.value, ...response[props.listName]], props.keyBy).map(e => ({ ...e, key: e[props.keyBy] })),
      ['custom_sort', 'title']
    );

    listCount.value = response.list_count;

    if (debouncedSearch.value) {
      searched.value.push(debouncedSearch.value);
      if (searched.value.length > MAX_SEARCH_CACHE) {
        searched.value.shift();
      }
    }

    //we have loaded the list at least once
    loaded.value = true;

  } catch (exception) {
    //FIXME: handle this better
    console.error(exception);
  } finally {
    loading.value = false;
  }
}

defineExpose({ missing });

</script>