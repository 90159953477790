import * as ActionCable from "@rails/actioncable"

/* eslint-disable no-undef */
export default defineNuxtPlugin(nuxtApp => {

  const config = useRuntimeConfig()
  const token = nuxtApp.$keycloak.token;
  const endpoint = `${config.public.backendUrl}/cable?token=${token}`;

  // ActionCable.logger.enabled = true
  const cable = ActionCable.createConsumer(() => endpoint);

  nuxtApp.provide('cable', cable);

});