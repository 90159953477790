<template>
  <div>
    <QuillEditor 
      v-model:content="content"
      :toolbar="toolbar"
      content-type="html"
      theme="snow" 
      v-bind="$attrs"
      @ready="ready"
    />
    <div class="d-flex justify-end">
      <workflow-field-icon @accept="insert" />
    </div>
  </div>
</template>

<script setup>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const content = defineModel({
  type: String
})

defineProps({
  withTokenSelect: Boolean
});

const quill = ref(null);

const toolbar = [
  [{ header: ['1', '2', '3', false] }],
  ['bold', 'italic', 'underline'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'align': [] }, /*{ 'background': [] }*/ ],          
  [{ 'color': [] }],
  // [{ 'indent': '-1'}, { 'indent': '+1' }],          
  ['link', /*'image'*/],
]

function ready(editor) {
  quill.value = editor;
}

function insert(text) {
  const range = quill.value.getSelection(true);
  quill.value.insertText(range.index, text);
}

</script>

<style>
  .ql-container {
    min-height: 130px;
  }
  
</style>