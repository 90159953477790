import { default as editbyahQ6CkrCMeta } from "/opt/render/project/src/pages/admin/applications/[id]/edit.vue?macro=true";
import { default as indexPXQqkoIMaPMeta } from "/opt/render/project/src/pages/admin/applications/index.vue?macro=true";
import { default as newgyidShngaHMeta } from "/opt/render/project/src/pages/admin/applications/new.vue?macro=true";
import { default as indexDkk88yNuEcMeta } from "/opt/render/project/src/pages/admin/audit_logs/[id]/index.vue?macro=true";
import { default as index4nSTR3dzOjMeta } from "/opt/render/project/src/pages/admin/audit_logs/index.vue?macro=true";
import { default as index1gS82PkShCMeta } from "/opt/render/project/src/pages/admin/backups/[id]/index.vue?macro=true";
import { default as indexvwAtbIQGhFMeta } from "/opt/render/project/src/pages/admin/backups/index.vue?macro=true";
import { default as edituHh42sNErSMeta } from "/opt/render/project/src/pages/admin/cron_jobs/[id]/edit.vue?macro=true";
import { default as indexvVtI9ac6rzMeta } from "/opt/render/project/src/pages/admin/cron_jobs/index.vue?macro=true";
import { default as newFkcYacBY3vMeta } from "/opt/render/project/src/pages/admin/cron_jobs/new.vue?macro=true";
import { default as editNyLL65QolNMeta } from "/opt/render/project/src/pages/admin/form_categories/[id]/edit.vue?macro=true";
import { default as indexzXNr8ty2WCMeta } from "/opt/render/project/src/pages/admin/form_categories/index.vue?macro=true";
import { default as newPaDFVUv56sMeta } from "/opt/render/project/src/pages/admin/form_categories/new.vue?macro=true";
import { default as editAb62QU6xuSMeta } from "/opt/render/project/src/pages/admin/forms/[id]/edit.vue?macro=true";
import { default as indexmwFNfR5y6sMeta } from "/opt/render/project/src/pages/admin/forms/index.vue?macro=true";
import { default as newlPBBub9uGbMeta } from "/opt/render/project/src/pages/admin/forms/new.vue?macro=true";
import { default as index7bX5qL9w3WMeta } from "/opt/render/project/src/pages/admin/index.vue?macro=true";
import { default as indexZKal8bVDVqMeta } from "/opt/render/project/src/pages/admin/usage_statistics/index.vue?macro=true";
import { default as indexmkrG6Jm7jJMeta } from "/opt/render/project/src/pages/admin/users/[id]/index.vue?macro=true";
import { default as indexX4siSRASy0Meta } from "/opt/render/project/src/pages/admin/users/index.vue?macro=true";
import { default as editkn2rImL41tMeta } from "/opt/render/project/src/pages/admin/workflow_definitions/[id]/edit.vue?macro=true";
import { default as indexTdJ29kLfihMeta } from "/opt/render/project/src/pages/admin/workflow_definitions/index.vue?macro=true";
import { default as new0Rvsbf2dIqMeta } from "/opt/render/project/src/pages/admin/workflow_definitions/new.vue?macro=true";
import { default as index9qXHQoSx2XMeta } from "/opt/render/project/src/pages/admin/workflow_processes/[id]/index.vue?macro=true";
import { default as indexIAZWn5CNowMeta } from "/opt/render/project/src/pages/admin/workflow_processes/index.vue?macro=true";
import { default as indexdY4r6A03coMeta } from "/opt/render/project/src/pages/admin/workflow/index.vue?macro=true";
import { default as indexgaiSkGapxaMeta } from "/opt/render/project/src/pages/forms/[id]/edit/[lookup_key]/index.vue?macro=true";
import { default as indexiN4F8Iky1VMeta } from "/opt/render/project/src/pages/forms/[id]/index.vue?macro=true";
import { default as indexwLB6l2eQSQMeta } from "/opt/render/project/src/pages/forms/index.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as indexZ6OReZGUndMeta } from "/opt/render/project/src/pages/my_profile/index.vue?macro=true";
import { default as indexbtQvgTTuhIMeta } from "/opt/render/project/src/pages/notifications/index.vue?macro=true";
import { default as edit8c3h2LUKDvMeta } from "/opt/render/project/src/pages/research/admin/repository_journals/[id]/edit.vue?macro=true";
import { default as indexrtFWczlQuJMeta } from "/opt/render/project/src/pages/research/admin/repository_journals/index.vue?macro=true";
import { default as editinGoxpFo5XMeta } from "/opt/render/project/src/pages/research/admin/repository_publishers/[id]/edit.vue?macro=true";
import { default as index45yq3D5M1EMeta } from "/opt/render/project/src/pages/research/admin/repository_publishers/index.vue?macro=true";
import { default as edit9sX4v8e8F5Meta } from "/opt/render/project/src/pages/research/college_research/[uuid]/edit.vue?macro=true";
import { default as indexBpHxIu4q0DMeta } from "/opt/render/project/src/pages/research/college_research/index.vue?macro=true";
import { default as newNA7JI9WYFaMeta } from "/opt/render/project/src/pages/research/college_research/new.vue?macro=true";
import { default as editjyBodYkjhyMeta } from "/opt/render/project/src/pages/research/my_research/[uuid]/edit.vue?macro=true";
import { default as indexU07Br4wHyoMeta } from "/opt/render/project/src/pages/research/my_research/index.vue?macro=true";
import { default as newTBaX4RWVbbMeta } from "/opt/render/project/src/pages/research/my_research/new.vue?macro=true";
import { default as editZfabbhETQkMeta } from "/opt/render/project/src/pages/research/repository_admin/[uuid]/edit.vue?macro=true";
import { default as indexOW8RxEgBE5Meta } from "/opt/render/project/src/pages/research/repository_admin/index.vue?macro=true";
import { default as newTGqNSqx3tWMeta } from "/opt/render/project/src/pages/research/repository_admin/new.vue?macro=true";
import { default as editOd2lrcoXgRMeta } from "/opt/render/project/src/pages/research/sgr_research/[uuid]/edit.vue?macro=true";
import { default as indexY7rK0MeLwEMeta } from "/opt/render/project/src/pages/research/sgr_research/index.vue?macro=true";
import { default as new3vSAVI87LeMeta } from "/opt/render/project/src/pages/research/sgr_research/new.vue?macro=true";
import { default as indexXoYKXtLnjIMeta } from "/opt/render/project/src/pages/students/extension_requests/index.vue?macro=true";
import { default as new85cHC7aCHZMeta } from "/opt/render/project/src/pages/students/extension_requests/new.vue?macro=true";
import { default as ShowLoV2SZJbQkMeta } from "/opt/render/project/src/pages/students/extension_requests/Show.vue?macro=true";
import { default as indexbFirdaL6k3Meta } from "/opt/render/project/src/pages/tasks/[id]/index.vue?macro=true";
import { default as indexiznjkFm8sgMeta } from "/opt/render/project/src/pages/tasks/index.vue?macro=true";
import { default as testSpfBPW5SdMMeta } from "/opt/render/project/src/pages/test.vue?macro=true";
export default [
  {
    name: "admin-applications-id-edit",
    path: "/admin/applications/:id()/edit",
    component: () => import("/opt/render/project/src/pages/admin/applications/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-applications",
    path: "/admin/applications",
    component: () => import("/opt/render/project/src/pages/admin/applications/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-applications-new",
    path: "/admin/applications/new",
    component: () => import("/opt/render/project/src/pages/admin/applications/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-audit_logs-id",
    path: "/admin/audit_logs/:id()",
    component: () => import("/opt/render/project/src/pages/admin/audit_logs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-audit_logs",
    path: "/admin/audit_logs",
    component: () => import("/opt/render/project/src/pages/admin/audit_logs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-backups-id",
    path: "/admin/backups/:id()",
    component: () => import("/opt/render/project/src/pages/admin/backups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-backups",
    path: "/admin/backups",
    component: () => import("/opt/render/project/src/pages/admin/backups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cron_jobs-id-edit",
    path: "/admin/cron_jobs/:id()/edit",
    component: () => import("/opt/render/project/src/pages/admin/cron_jobs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-cron_jobs",
    path: "/admin/cron_jobs",
    component: () => import("/opt/render/project/src/pages/admin/cron_jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-cron_jobs-new",
    path: "/admin/cron_jobs/new",
    component: () => import("/opt/render/project/src/pages/admin/cron_jobs/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-form_categories-id-edit",
    path: "/admin/form_categories/:id()/edit",
    component: () => import("/opt/render/project/src/pages/admin/form_categories/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-form_categories",
    path: "/admin/form_categories",
    component: () => import("/opt/render/project/src/pages/admin/form_categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-form_categories-new",
    path: "/admin/form_categories/new",
    component: () => import("/opt/render/project/src/pages/admin/form_categories/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-forms-id-edit",
    path: "/admin/forms/:id()/edit",
    component: () => import("/opt/render/project/src/pages/admin/forms/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-forms",
    path: "/admin/forms",
    component: () => import("/opt/render/project/src/pages/admin/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-forms-new",
    path: "/admin/forms/new",
    component: () => import("/opt/render/project/src/pages/admin/forms/new.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: index7bX5qL9w3WMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-usage_statistics",
    path: "/admin/usage_statistics",
    component: () => import("/opt/render/project/src/pages/admin/usage_statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "/admin/users/:id()",
    component: () => import("/opt/render/project/src/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/opt/render/project/src/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow_definitions-id-edit",
    path: "/admin/workflow_definitions/:id()/edit",
    component: () => import("/opt/render/project/src/pages/admin/workflow_definitions/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow_definitions",
    path: "/admin/workflow_definitions",
    component: () => import("/opt/render/project/src/pages/admin/workflow_definitions/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow_definitions-new",
    path: "/admin/workflow_definitions/new",
    component: () => import("/opt/render/project/src/pages/admin/workflow_definitions/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow_processes-id",
    path: "/admin/workflow_processes/:id()",
    component: () => import("/opt/render/project/src/pages/admin/workflow_processes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow_processes",
    path: "/admin/workflow_processes",
    component: () => import("/opt/render/project/src/pages/admin/workflow_processes/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-workflow",
    path: "/admin/workflow",
    meta: indexdY4r6A03coMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/workflow/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-id-edit-lookup_key",
    path: "/forms/:id()/edit/:lookup_key()",
    meta: indexgaiSkGapxaMeta || {},
    component: () => import("/opt/render/project/src/pages/forms/[id]/edit/[lookup_key]/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-id",
    path: "/forms/:id()",
    meta: indexiN4F8Iky1VMeta || {},
    component: () => import("/opt/render/project/src/pages/forms/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forms",
    path: "/forms",
    component: () => import("/opt/render/project/src/pages/forms/index.vue").then(m => m.default || m)
  },
  {
    name: "Home",
    path: "/",
    meta: indexYy8SidDWaAMeta || {},
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "my_profile",
    path: "/my_profile",
    component: () => import("/opt/render/project/src/pages/my_profile/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/opt/render/project/src/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "research-admin-repository_journals-id-edit",
    path: "/research/admin/repository_journals/:id()/edit",
    component: () => import("/opt/render/project/src/pages/research/admin/repository_journals/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-admin-repository_journals",
    path: "/research/admin/repository_journals",
    component: () => import("/opt/render/project/src/pages/research/admin/repository_journals/index.vue").then(m => m.default || m)
  },
  {
    name: "research-admin-repository_publishers-id-edit",
    path: "/research/admin/repository_publishers/:id()/edit",
    component: () => import("/opt/render/project/src/pages/research/admin/repository_publishers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-admin-repository_publishers",
    path: "/research/admin/repository_publishers",
    component: () => import("/opt/render/project/src/pages/research/admin/repository_publishers/index.vue").then(m => m.default || m)
  },
  {
    name: "research-college_research-uuid-edit",
    path: "/research/college_research/:uuid()/edit",
    component: () => import("/opt/render/project/src/pages/research/college_research/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-college_research",
    path: "/research/college_research",
    component: () => import("/opt/render/project/src/pages/research/college_research/index.vue").then(m => m.default || m)
  },
  {
    name: "research-college_research-new",
    path: "/research/college_research/new",
    component: () => import("/opt/render/project/src/pages/research/college_research/new.vue").then(m => m.default || m)
  },
  {
    name: "research-my_research-uuid-edit",
    path: "/research/my_research/:uuid()/edit",
    component: () => import("/opt/render/project/src/pages/research/my_research/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-my_research",
    path: "/research/my_research",
    component: () => import("/opt/render/project/src/pages/research/my_research/index.vue").then(m => m.default || m)
  },
  {
    name: "research-my_research-new",
    path: "/research/my_research/new",
    component: () => import("/opt/render/project/src/pages/research/my_research/new.vue").then(m => m.default || m)
  },
  {
    name: "research-repository_admin-uuid-edit",
    path: "/research/repository_admin/:uuid()/edit",
    component: () => import("/opt/render/project/src/pages/research/repository_admin/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-repository_admin",
    path: "/research/repository_admin",
    component: () => import("/opt/render/project/src/pages/research/repository_admin/index.vue").then(m => m.default || m)
  },
  {
    name: "research-repository_admin-new",
    path: "/research/repository_admin/new",
    component: () => import("/opt/render/project/src/pages/research/repository_admin/new.vue").then(m => m.default || m)
  },
  {
    name: "research-sgr_research-uuid-edit",
    path: "/research/sgr_research/:uuid()/edit",
    component: () => import("/opt/render/project/src/pages/research/sgr_research/[uuid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "research-sgr_research",
    path: "/research/sgr_research",
    component: () => import("/opt/render/project/src/pages/research/sgr_research/index.vue").then(m => m.default || m)
  },
  {
    name: "research-sgr_research-new",
    path: "/research/sgr_research/new",
    component: () => import("/opt/render/project/src/pages/research/sgr_research/new.vue").then(m => m.default || m)
  },
  {
    name: "students-extension_requests",
    path: "/students/extension_requests",
    component: () => import("/opt/render/project/src/pages/students/extension_requests/index.vue").then(m => m.default || m)
  },
  {
    name: "students-extension_requests-new",
    path: "/students/extension_requests/new",
    component: () => import("/opt/render/project/src/pages/students/extension_requests/new.vue").then(m => m.default || m)
  },
  {
    name: "students-extension_requests-Show",
    path: "/students/extension_requests/Show",
    component: () => import("/opt/render/project/src/pages/students/extension_requests/Show.vue").then(m => m.default || m)
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    component: () => import("/opt/render/project/src/pages/tasks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    component: () => import("/opt/render/project/src/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/opt/render/project/src/pages/test.vue").then(m => m.default || m)
  }
]