<template>
  <div>
    <v-autocomplete
      v-model="config.subform"
      :items="allowedForms"
      :loading="pending"
      :rules="[v => !!v || 'Form is required']"
      auto-select-first
      item-value="key"
      clearable
      label="Select a subform"
    >

      <template #item="{ props, item}">
        <v-list-item
          v-bind="props"
          :title="`[${item.raw.key}] ${item.raw.title}`"
          :subtitle="item.raw.description"
        >
          <template v-if="item.raw.icon" #prepend>
            <v-icon>{{ item.raw.icon }}</v-icon>
          </template>
        </v-list-item>
      </template>

      <template #selection="{ item }">
        <v-icon>{{ item.raw.icon }}</v-icon>
        {{ `[${item.raw.key}] ${item.raw.title}` }}
      </template>

    </v-autocomplete>
  </div>
</template>

<script setup>

const componentStore = useComponentStore();

const config = defineModel({
  type: Object
})

const { data, pending } = useApi('/admin/forms', { query: { simple: true }});

const allowedForms = computed(() => data.value?.forms?.filter(e => e.key !== componentStore.form?.key));

</script>