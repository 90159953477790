<template>
  <div>
    <v-table>
      <thead>
        <tr>
          <th width="75%">
            Target
          </th>
          <th>Planned Completion</th>
          <th width="1%" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="`row_${index}`">
          <td>
            <v-text-field
              v-model="rows[index].target"
              :rules="[v => !!v || 'This field is required']"
              :disabled="readOnly"
            />
          </td>
          <td>
            <v-text-field
              v-model="rows[index].date"
              :rules="[v => !!v || 'This field is required']"
              :disabled="readOnly"
              type="date"
            />
          </td>
          <td v-if="!readOnly">
            <v-icon @click="removeRow(index)">
              mdi-close
            </v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-chip v-if="!readOnly" @click="addRow">
      <v-icon>mdi-plus</v-icon>
    </v-chip>
  </div>
</template>

<script setup>

const rows = defineModel({
  type: Array
});

function addRow() {
  if (! rows.value || ! Array.isArray(rows.value)) {
    rows.value = [];
  }
  rows.value.push({
    target: null,
    date: null
  })
}

function removeRow(index) {
  rows.value.splice(index, 1);
}
</script>