<template>

  <!-- TODO: tasks for due date (ie report in 12 months)-->
  <workflow-notification-target-summary 
    v-if="summary" 
    :target="node?.data?.data?.assignee"
  />

  <template v-else>

    <v-form @submit.prevent>

      <debug title="computedActions">
        {{ computedActions }}
      </debug>

      <v-container fluid>

        <v-row dense no-gutters>
          <v-col>
            <v-card variant="outlined">
              <v-card-text>
                <workflow-notification-target 
                  v-model="node.data.data.assignee" 
                  :node="node"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <slot name="title" :node="node">
          <v-row>
            <v-col>
              <v-text-field
                v-model="node.data.data.task_title" 
                label="Task Title"
              >
                <template #append-inner>
                  <workflow-field-icon v-model="node.data.data.task_title" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </slot>

        <slot name="detail" :node="node">
          <v-row>
            <v-col>
              <html-editor v-model="node.data.data.task_detail" />
            </v-col>
          </v-row>
        </slot>

        <v-row>
          <v-col>
            <v-autocomplete
              v-model="node.data.data.component"
              :items="taskComponents"
              label="Custom Component"
              :hint="componentHint"
              persistent-hint
              clearable
            />
          </v-col>
        </v-row>
        <slot
          v-if="! hideActions"
          name="actions"
          :node="node"
        >
          <v-row>
            <v-col>
              <v-card
                flat
                title="Actions"
                variant="outlined"
              >

                <v-card-subtitle>
                  <slot name="actions.subtitle">
                    The following actions will be available based on the next workflow steps:
                  </slot>
                </v-card-subtitle>

                <v-card-text>

                  <v-row
                    dense
                    no-gutters
                  >
                    <v-col>

                      <div v-if="! hideActionStyle || ! hideActionLocation" class="d-flex">

                        <v-switch
                          v-if="! hideActionStyle"
                          v-model="node.data.data.action_style"
                          true-value="buttons"
                          false-value="list"
                          :label="node.data.data.action_style == 'buttons' ? 'Buttons' : 'List'"
                          class="mr-2"
                          hide-details
                        />

                        <v-switch
                          v-if="! hideActionLocation"
                          v-model="node.data.data.action_position"
                          true-value="above"
                          false-value="below"
                          :label="node.data.data.action_position == 'above' ? 'Above form' : 'Below form'"
                          hide-details
                        />

                      </div>

                    </v-col>
                  </v-row>
                  
                  <v-row
                    dense
                    no-gutters
                  >
                    <v-col>
            
                      <task-action-select
                        v-if="node.data.data.action_style == 'list'"  
                        :items="computedActions"
                      />

                      <div v-else>
                        <task-action-button
                          v-for="(action, index) in computedActions"
                          :key="index"
                          :action="action"
                          class="mr-2"
                          @click="configureTargetButton(action)"
                        />
                      </div>

                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </slot>

        <slot name="actions.after" :actions="computedActions" />

      </v-container>
    </v-form>

  </template>

</template>

<script setup>

import { omit, sortBy } from 'lodash-es';

const node = defineModel({
  type: Object,
  required: true,
});

const props = defineProps({
  summary: Boolean,
  hideActions: Boolean,
  hideActionStyle: Boolean,
  hideActionLocation: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  },
  actions: {
    type: Array,
    required: false,
    default: undefined
  }
});

const graphStore = useGraphStore();
const componentStore = useComponentStore();

onMounted(() => {
  node.value.data.data ||= {}
  node.value.data.data.assignee ||= {}
  node.value.data.data.job_assignment ||= {}
  node.value.data.data.form ||= {}
  node.value.data.data.form.definition ||= {}
  refresh();
});

const formFields = computed(() => node.value?.data?.data?.form?.definition?.rows?.flatMap(r => r.columns) || []);
const taskComponents = computed(() => componentStore.taskComponents());

const componentHint = computed(() => {
  if (node.value?.data?.data?.component) {
    return "This component will display the task, actions, and maybe the form (check the component)."
  }
  return "Select a custom component to display the task (optional)";
});

watch(formFields, () => refresh());

const targets = computed(() => node.value?.id ? graphStore.targets(node.value.id) : []);

const computedActions = computed(() => {

  if (props.actions) {
    return props.actions;
  }

  return sortBy(
    targets.value.map(e => ({
      edge_id: e.id,
      label: e.data.title,
      attributes: omit({ ...e.data.attributes, ...e.data.data.attributes }, ['icon']),
      ...e.data.data
    })
  ), ['order', 'edge_id']);
});


function configureTargetButton(target) {
  //nothing to do yet
}

function refresh() {

  if (props.summary) return;

  const { replaceField } = useNodeFields(node);

  //extra mapping to allow easier selection in other steps
  replaceField({
    group: 'response',
    name: 'action', 
    label: 'Selected Action', 
    type: 'String',
    options: computedActions.value?.map(e => ({ 
      value: e.event || e.key || e.label, 
      text: e.label,
      title: e.label,
      subtitle: e.description,
      color: e.attributes?.color || e.color
    }))
  });

  for (const field of formFields.value) {
    replaceField({
      group: 'response',
      name: field.name, 
      label: field.label || field.name, 
      type: field.options?.dataSource?.cast || 'String',
    });
  }

}

defineExpose({ refresh })

</script>