import { defineStore } from 'pinia';

export const useCacheStore = defineStore('cache', {
  state: () => ({
    cache: {},
  }),

  actions: {
    set(key, value, ttl) {
      this.cache[key] = {
        value,
        expiresAt: Date.now() + (ttl * 1000),
      };
    },
    get(key) {
      const item = this.cache[key];
      if (item && item.expiresAt > Date.now()) {
        return item.value;
      }
      return null;
    },
    remove(key) {
      delete this.cache[key];
    },
    clear() {
      this.cache = {};
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCacheStore, import.meta.hot));
}