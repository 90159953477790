import { usePortalStore } from "~/stores/portal";

export default {

  received(event) {
    console.log("received", event);
    const portal = usePortalStore();
    if (event.action == 'create') {
      portal.notifications.push(event.notification);
    } else if (event.action == 'destroy') {
      const index = portal.notifications.findIndex(n => n.id == event.notification.id);
      if (index !== -1) {
        portal.notifications.splice(index, 1);
      }
    } else {
      console.warn("Unhandled notification event", event);
    }
  },
};
