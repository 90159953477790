<template>
  <v-btn 
    :color="data.data?.attributes?.color || data.attributes.color"
    size="x-large"
    @click="$emit('edit')"
  >
    {{ data.title }} {{ order }}
  </v-btn>
</template>

<script setup>

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const order = computed(() => props.data.data.order ? `(${props.data.data.order})` : '');

defineEmits(['edit']);

</script>
