import { defineNuxtRouteMiddleware } from "#app";
import { useMessageStore } from "#imports";

export default defineNuxtRouteMiddleware(() => {

  /**
   * 'Sticky' toasts means they were added without a timeout
   * and will stay until dismissed. This clears those out on
   * page change, but keeps timed toasts (like edit success).
   */
  useMessageStore().clearSticky();

});