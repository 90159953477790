<template>
  <v-container v-if="notification.target" fluid>
    <v-row
      v-if="notification.mailer_class"
      dense
      no-gutters
    >
      <v-col>
        <v-icon size="small">
          mdi-email
        </v-icon>
        {{ notification.mailer_class }}
      </v-col>
    </v-row>

    <v-row dense no-gutters>
      <v-col>
        <workflow-notification-target-summary
          :target="notification.target"
        />
      </v-col>
    </v-row>
  </v-container>

</template>

<script setup>

const props = defineProps({
  node: {
    type: Object,
    required: true,
  },
});

const notification = computed(() => props.node?.data?.data?.notification || {});

</script>