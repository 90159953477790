<template>

  <template v-if="Array.isArray(value)">
    <template v-if="isFile(value)">
      <div class="mt-2">
        <file-download 
          v-for="(file, f) in value"
          :key="f"
          :file="file"
          class="mr-2 mb-2"
          size="small"
        />
      </div>
    </template>
    <ul v-else class="ml-3">
      <li v-for="(item, i) in value" :key="i">
        {{ item }}
      </li>
    </ul>
  </template>

  <file-download 
    v-else-if="isFile(value)" 
    :file="value"
  />

  <span v-else>{{ value }}</span>

</template>

<script setup>

defineProps({
  value: {
    type: [String, Array, Object],
    required: true
  }
});

function isFile(value) {
  return [value].flat().every(v => !isEmpty(v.download_url));
}

</script>