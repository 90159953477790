import { defineStore } from 'pinia'
import { useNuxtApp, acceptHMRUpdate } from '#imports';
import { camelize, isEmpty } from '#imports';

/**
 * This is only to look up components. For actually dealing with forms
 * you want useFormBuilder() which handles building and displaying dynamic forms.
 */
export const useComponentStore = defineStore('component', {

  state: () => ({
    ...useNuxtApp().$imports
  }),

  actions: {

    formComponents(categorised = false) {
      const result = Object.keys(this.fields).map((e) =>
        e.substring("/components/forms/fields/".length, e.length - ".vue".length)
      );
      if (categorised) {
        const list = [];
        let currentFolder = null;
        result.forEach((e) => {
          const index = e.indexOf("/");
          const folder = e.substring(0, index);
          const component = e.substring(index + 1);
          if (currentFolder != folder) {
            list.push({ header: folder });
            currentFolder = folder;
          }
          list.push({ value: `${e}.vue`, title: component });
        });
        return list;
      } else {
        return result;
      }
    },

    /**
     * Gets a bit of extra checking because it's used in so many places.
     * @param {String} name 
     * @param {Boolean} required default false
     * @returns 
     */
    resolveField(name, required = false) {
      if (! name) return undefined;
      if (! name.includes("/")) {
        name = `standard/${name}`;
      }
      name = this._component(name);
      const found = this.fields[`/components/forms/fields/${name}`];
      if (found) {
        return found.default
      } else {
        if (required) {
          throw new Error(`Component not found: ${name}`);
        }
        return undefined;
      }
    },

    /**
     * Figure out what data type this field is supposed to return.
     * 
     * @param {*} field 
     * @returns {string}
     */    
    fieldCastType(field) {

      let cast = this.resolveField(field.component)?.config?.dataSource?.cast;
      if (isEmpty(cast)) {
        //TODO: all fields should specify a cast, this won't really work anymore
        //because the workflow system assumes types can be cast to Ruby types
        // cast = parameterize(field.component.split("/").pop().split(".").shift());
        cast = 'string';
        console.warn(`No cast type found for ${field.component}, defaulting to '${cast}'`);
      }

      return cast;
    },

    resolveTask(name) {
      if (! name) return undefined;
      name = this._component(name);
      return this.tasks[`/components/task/display/${name}`]?.default;
    },

    taskComponents() {
      return Object.keys(this.tasks).map((e) =>
        e.substring("/components/task/display/".length, e.length - ".vue".length)
      );
    },

    resolveLayout(name) {
      if (! name) return undefined;
      name = this._component(name);
      return this.layouts[`/components/forms/${name}`]?.default;
    },

    resolveWorkflowAction(name) {
      if (! name) return undefined;
      return this.actions[`/components/workflow/action/${camelize(name)}.vue`]?.default;
    },

    resolveNode(name) {
      if (! name) return undefined;
      return this.nodes[`/components/workflow/node/${camelize(name)}Node.vue`]?.default;
    },

    _component(name) {
      if (! name) return undefined;
      if (! name.endsWith(".vue")) {
        name = `${name}.vue`;
      }
      return name;
    }

  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useComponentStore, import.meta.hot));
}