<!-- eslint-disable vue/no-v-html -->
<template>
  <v-sheet>
    <div
      :id="$style.htmlContainer"
      v-html="processedContent"
    />
  </v-sheet>
</template>

<script setup>

const props = defineProps({
  content: {
    type: String,
    default: ""
  }
});

const processedContent = computed(() => {
  return (props.content || "")
    .replaceAll('<a', '<a target="_blank"')
    .trim();
});

</script>

<style module>
  #htmlContainer {
    /* https://stackoverflow.com/questions/18891755/force-word-wrap-through-css */
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap; 
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    ol {
      margin-left: 36px;
    }
    ul {
      margin-left: 36px;
    }
    p {
      margin-bottom: 12px;
    }
  }
</style>