<template>
  <SimpleRoleSelect
    v-model="localValue.allowed_roles"
    label="Limit to Roles"
    multiple
  />
</template>

<script setup>
const localValue = defineModel({
  type: Object
})
</script>