<template>
  <div class="d-flex">
    <task-action-button
      v-for="(action, a) in items"
      :key="a"
      :action="action"
      v-bind="$attrs"
      class="mr-2"
      @click="execute(action)"
    />
  </div>
</template>

<script setup>

defineProps({
  items: {
    type: Array,
    required: false,
    default: () => []
  },
})

const emit = defineEmits(['execute']);

function execute(action) {
  emit('execute', action.event || action.key)
}

</script>