<template>
  <div>

    <workflow-approval-summary :task="task" />
    
    <v-tabs 
      v-model="tab"
      bg-color="grey-lighten-4"
      color="primary"
      :items="tabs"
    >

      <template #item.submission>
        <v-divider />
        <v-row>
          <v-col class="overflow-y-auto" max-height="300px">
            <v-card
              variant="outlined"
              class="ma-6 bg-grey-lighten-5"
            >
              <v-card-title>
                <v-icon>{{ form.icon }}</v-icon>
                {{ form.title }}
              </v-card-title>
              <v-card-subtitle>
                {{ form.description }}
                <v-divider />
              </v-card-subtitle>

              <forms-layouts-minimal-form
                v-model="submission"    
                :form="form"
                format="default"
                read-only
              />
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template #item.files>
        <v-card flat class="ma-5">
          <v-card-subtitle>
            The following files were uploaded by the applicant:
          </v-card-subtitle>
          <v-card-text>
            <div class="d-flex">
              <v-chip-group column>
                <v-chip
                  v-for="attachment in attachments"
                  :key="attachment.id"
                  @click="viewFile(attachment)"
                >
                  <v-icon>mdi-paperclip</v-icon>
                  {{ attachment.filename }}
                </v-chip>     
              </v-chip-group>         
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template #item.history>
        <v-timeline 
          side="end" 
          align="center"
          truncate-line="both"
          class="pt-8"
        >

          <approval-timeline-item
            v-for="(item, i) in approvalHistory"
            :key="i"
            :response="item"
            fill-dot
            @click="tab = $event"
          />
          
        </v-timeline>
      </template>

      <template #item.response>
        <v-divider />
        
        <task-response-form
          v-model="response"
          :task="task"
          :data="data"
          :pending="pending"
          actions-position="above"
          actions-label="Your decision"
          hide-header
          hide-files
          start-form-hidden
          @execute="$emit('execute')"
        />

        <!-- <v-form ref="form" v-model="valid">
          <v-table>
            <tbody>

              <tr v-if="hasActions && ! task.finished">
                <th>Your decision</th>
                <td>
                  <div class="pt-2">
                    <task-actions
                      v-model="response"
                      :task="task"
                    />
                  </div>
                  
                </td>
              </tr>

              <template v-if="response.action">
                <tr v-for="field in filteredColumns" :key="field.id">
                  <th>{{ field.component.includes('Checkbox') ? '' : field.label }}</th>
                  <td style="padding: 10px">
                    <forms-fields-form-field
                      v-model="response[field.name]"
                      v-bind="{...bindableAttributes[field.name], ...$attrs}"
                      :plain="true"
                      :field="field"
                    />
                  </td>
                </tr>

              </template>

              <tr v-if="! task.finished">
                <th>Finalise</th>
                <td>

                  <v-btn
                    v-if="response.action"  
                    :color="selectedAction.attributes.color"
                    :disabled="! valid"
                    variant="elevated"
                    @click="execute"
                  >
                    Confirm {{ selectedAction.label }}
                  </v-btn>

                  <p v-else>
                    Please select a response above
                  </p>

                </td>
              </tr>
            
            </tbody>
          </v-table>
        </v-form> -->

      </template>

    </v-tabs>

  </div>
</template>

<script setup>

import ApprovalTimelineItem from './components/ApprovalTimelineItem.vue';
import { pick } from 'lodash-es';

const response = defineModel({
  type: Object,
  required: true,
  default: () => ({})
})

const props = defineProps({
  task: {
    type: Object,
    required: true,
    default: () => ({})
  },
  data: {
    type: Object,
    required: false,
    default: () => ({})
  },
  pending: Boolean
});

const tab = ref("submission");

const approval = computed(() => props.task.data.approval);
const form = computed(() => approval.value.form_submission.form);
const submission = computed(() => approval.value.form_submission.data);
const attachments = computed(() => {
  return Object.values(submission.value)
    .filter(e => Array.isArray(e) && e.length > 0)
    .flat()
    .filter(e => e.attachable_sgid) || [];
});
const hasAttachments = computed(() => attachments.value.length > 0);
const approvalHistory = computed(() => props.task?.data?.approval?.history || []);

const tabs = computed(() => {
  const result = [];
  let i = 1;
  result.push({ "prepend-icon": 'mdi-information', value: "submission", text: `${i++}. Submitted Information` });
  if (hasAttachments.value) {
    result.push({ "prepend-icon": 'mdi-paperclip', value: "files", text: `${i++}. Attached Files (${attachments.value.length})` });
  }
  result.push({ "prepend-icon": 'mdi-message-text-outline', value: "history", text: `${i++}. Approval History (${approvalHistory.value.length})` });
  result.push({ "prepend-icon": 'mdi-clipboard-check', value: "response", text: `${i++}. Your Response` });
  return result;
});

const { fieldList } = useFormBuilder(
  props.task.data.form, 
  response, 
  { 
    readOnly: props.task.finished, 
    ...props.task.data 
  }
);

defineEmits(['execute']);

onBeforeMount(() => {
  if (props.task.last_response) {
    Object.assign(response.value, props.task.last_response.response);
  } else {
    //only fill in form fields, not the whole submission
    Object.assign(response.value, pick(submission.value, fieldList.value.map(e => e.value)));
  }
});

function viewFile(file) {
  useFile(file.key).download();
}

</script>

<style scoped>
.info-table {
    margin-top: 35px;
    margin-bottom: 35px;
}

th {
  width: 15%;
  background-color: #f5f5f5;
  font-weight: bold !important;
  vertical-align: top;
  padding-top: 10px !important;
}

</style>