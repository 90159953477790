<template>
  <v-text-field>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  config: {
    dataSource: {
      cast: 'string',
    }
  }
}
</script>