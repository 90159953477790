import { usePortalStore, useComponentStore } from '#imports';
import { compareDate, isEmpty, deepClone, underscore, toBool } from '#imports';
import { computed, toValue, isRef, ref } from 'vue'
import { uniq, max, get } from 'lodash-es'

/**
 * All utility functions need to support dynamic forms.
 * 
 * @param {Object} form The form definition
 * @param {Object} model The model to fill in
 * @param {Object} data Extra stuff returned during form submission process
 */
export const useFormBuilder = (form, model, data) => {

  const componentStore = useComponentStore();

  const nextFieldIndex = ref(undefined);
  let conditionCache = {};

  if (model && ! isRef(model)) {
    console.error("The model must be a ref()")
  }

  if (! form) {
    form = ref({ definition: { rows: [] } });
  } else {
    toValue(form).definition ||= {};
    toValue(form).definition.rows ||= [];
  }

  /**
   * Rule factory functions ready to be curried for an individual field.
   */
  const ruleFactory = {
    required: (f, arg, msg) => v => (! isEmpty(arg) ? v == arg : ! isEmpty(v)) || (msg || 'This field is required.'),
    _requiredFiles: (f, arg, msg) => () => Array.isArray(fieldValue(f)) && fieldValue(f).length > 0 || (msg || 'This field is required.'),
    filesMin: (f, arg, msg) => () => Array.isArray(fieldValue(f)) && fieldValue(f).length >= Number(arg) || (msg || `At least ${arg} files are required.`),
    filesMax: (f, arg, msg) => () => Array.isArray(fieldValue(f)) && fieldValue(f).length <= Number(arg) || (msg || `Maximum ${arg} files are allowed.`),
    email: (f, arg, msg) => v => /^[^\s@]+@[^\s@]+$/.test(v) || (msg || 'Please enter a valid email address.'), //https://stackoverflow.com/questions/50039793/email-validation-n-vuetify-js                
    minWords: (f, arg, msg) => v => (v || '').split(' ').length >= Number(arg) || (msg || `Please enter at least ${arg} words.`),
    maxWords: (f, arg, msg) => v => (v || '').split(' ').length <= Number(arg) || (msg || `Please enter no more than ${arg} words.`),
    number: (f, arg, msg) => v => Number.isInteger(Number(v)) || (msg || 'Please enter a valid number.'),
    numberMin: (f, arg, msg) => v => v >= Number(arg) || (msg || `Please enter at least ${arg}`),
    numberMax: (f, arg, msg) => v => v <= Number(arg) || (msg || `Please enter no more than ${arg}`),
    countMin: (f, arg, msg) => v => v && v.length >= Number(arg) || (msg || `Please enter at least ${arg}`),
    countMax: (f, arg, msg) => v => v && v.length <= Number(arg) || (msg || `Please enter no more than ${arg}`),
    //TODO: an 'all-checked' condition
    dateBefore: (f, arg, msg) => v => compareDate(v, dateFieldValue(arg), 'lt') || (msg || `Date must be before ${fieldLabel(arg)}`),
    dateOnOrBefore: (f, arg, msg) => v => compareDate(v, dateFieldValue(arg), 'lte') || (msg || `Date must be on or before ${fieldLabel(arg)}`),
    dateAfter: (f, arg, msg) => v => compareDate(v, dateFieldValue(arg), 'gt') || (msg || `Date must be after ${fieldLabel(arg)}`),
    dateOnOrAfter: (f, arg, msg) => v => compareDate(v, dateFieldValue(arg), 'gte') || (msg || `Date must be on or after ${fieldLabel(arg)}`),
  }

  const matchStrategies = [
    
    { value: 'ANY', title: 'Has any value', parameter: false },
    { value: 'NONE', title: 'Has no value', parameter: false },

    { value: 'EXACT', title: 'Is value', deny: ['boolean']},
    { value: 'NEXACT', title: 'Is not value', deny: ['boolean']},

    { value: 'MATCH', title: 'Contains', allow: ['string'] },
    { value: 'NMATCH', title: 'Not contains', allow: ['string'] },
    
    { value: 'CHECK', title: 'True', parameter: false , allow: ['boolean'] },
    { value: 'NCHECK', title: 'False', parameter: false , allow: ['boolean'] },
      
    //TODO: these are good suggestions!

    // { value: 'DATE_BEFORE', title: 'Before', allow: ['date'] },
    // { value: 'DATE_ON_OR_BEFORE', title: 'On or before', allow: ['date'] },
    // { value: 'DATE_AFTER', title: 'After', allow: ['date'] },
    // { value: 'DATE_ON_OR_AFTER', title: 'On or after', allow: ['date'] },
    
    // { value: 'NUMBER_MIN', title: 'At least', allow: ['number'] },
    // { value: 'NUMBER_MAX', title: 'No more than', allow: ['number'] },

    // { value: 'COUNT_MIN', title: 'At least', allow: ['array'] },
    // { value: 'COUNT_MAX', title: 'No more than', allow: ['array'] },
  ];

  function matchRequiresParameter(matchStrategy) {
    if ((typeof matchStrategy.match) == 'string') matchStrategy = matchStrategy.match;
    return matchStrategies.find(e => e.value == matchStrategy)?.parameter !== false;
  }

  /**
   * Get the allowed match strategies for a field.
   * 
   * @param {String|Object} field
   * @returns Array
   */
  function allowedMatchStrategies(field) {

    //TODO: custom checkbox values

    if (! field) {
      return matchStrategies;
    }

    if (! field.name) {
      field = columns.value.find(e => e.name == field);
    }

    if (field) {
      const component = componentStore.resolveField(field.component);
      if (Array.isArray(component.config?.conditions)) {
        const allowedStrategies = component.config.conditions.map(e => e.toUpperCase());
        return matchStrategies.filter(e => allowedStrategies.includes(e.value.toUpperCase()));
      } else if (component.config?.dataSource?.cast) {
        const matches = matchesForCast(component.config.dataSource.cast);
        return matches
      }
    }

    return matchesForCast('string');

  }

  function fieldHasStaticValues(field) {
    
    if (! field.name) {
      field = columns.value.find(e => e.name == field);
    }

    return field?.options?.type == 'static';

  }

  /**
   * 
   * @param {Object|String} field
   * @returns array|undefined
   */
  function fieldStaticValues(field) {

    if (field) {

      if (! field.name) {
        field = columns.value.find(e => e.name == field);
      }
      if (field) {
        if (fieldHasStaticValues(field)) {
          return field.options.options;
        }
      } else {
        console.debug("Couldn't find a form field matching ", field);
      }

    }

  }

  function fieldIsListItem(field) {

    if (! field.name) {
      field = columns.value.find(e => e.name == field);
    }

    return field?.options?.type == 'list_item';

  }

  function fieldListItemName(field) {
    
    if (! field.name) {
      field = columns.value.find(e => e.name == field);
    }

    if (field?.options?.type != 'list_item') {
      return undefined;
    }

    return field?.options?.listItem;
  }

  function matchesForCast(cast) {
    return matchStrategies.filter(e => {
      
      const allow = e.allow || [];
      const deny = e.deny || [];

      return (
        (allow.length == 0 && deny.length == 0) ||
        (allow.includes('*')) ||
        (
          (deny.length == 0 || ! deny.includes(cast)) &&
          (allow.length == 0 || allow.includes(cast))
        )
      )

    });
  }

  /**
   * Get the allowed rules for a field.
   * 
   * @param {Object|String} field 
   * @returns Array
   */
  function allowedRules(field) {

    if (! field) {
      return ruleNames.value;
    }

    if (! field.name) {
      field = columns.value.find(e => e.name == field);
    }

    const component = componentStore.resolveField(field.component);
    let allowedRuleNames = [];

    if (component.config?.rules === false) {
      return [];

    } else if (Array.isArray(component.config?.rules)) {
      //rules explicitly listed
      allowedRuleNames = component.config.rules.map(e => e.toUpperCase());
      return ruleNames.value.filter(e => allowedRuleNames.includes(e.toUpperCase()));

    } else if (component.config?.dataSource?.cast) {
      //figure out from the cast type
      switch (component.config.dataSource.cast) {
        case 'date':
          allowedRuleNames.push(...dateRules.value);
          break;
        case 'file':
          allowedRuleNames.push(...fileRules.value);
          break;
        case 'boolean':
          allowedRuleNames.push(...checkboxRules.value);
          break;
        case 'number':
        case 'currency':
          allowedRuleNames.push(...numberRules.value);
          break;
        default:
          allowedRuleNames.push(...stringRules.value);
      }
      if (component.config?.dataSource.allowMultiselect && field.options?.config?.multiple) {
        allowedRuleNames.push(...countRules.value);
      }
      return uniq(allowedRuleNames);
    }

    //dunno, return all rules
    return ruleNames.value;
  }

  const rows = computed(() => toValue(form).definition?.rows || []);
  const columns = computed(() => rows.value?.flatMap(e => e.columns) || []);
  const allFields = computed(() => columns.value);
  
  /**
   * The form fields as a v-list-item array.
   */
  const fieldList = computed(() => {
    return allFields.value.map((c) => ({
      title: `${c.label} [${c.name}]`,
      value: c.name,
      component: c.component,
    })) || [];
  });

  //only relevant during an actual form submission
  const submission = computed(() => toValue(data)?.submission);
  const readOnly = computed(() => toValue(data)?.read_only || toValue(data)?.readonly || toValue(data)?.readOnly || false);
  const editing = computed(() => toValue(data)?.editing);

  //rules 
  const ruleNames = computed(() => Object.keys(ruleFactory).filter(e => ! e.startsWith('_')));
  const dateRules = computed(() => ruleNames.value.filter(e => e.toLowerCase().includes('date') || e == 'required'));
  const fileRules = computed(() => ruleNames.value.filter(e => e.toLowerCase().includes('file') || e == 'required'));
  const checkboxRules = computed(() => ruleNames.value.filter(e => e == 'required'));
  const numberRules = computed(() => ruleNames.value.filter(e => e.toLowerCase().includes('number') || e == 'required'));
  const countRules = computed(() => ruleNames.value.filter(e => e.toLowerCase().includes('count') || e == 'required'));
  const stringRules = computed(() => ruleNames.value.filter(e => ['word', 'email'].some(f => e.toLowerCase().includes(f)) || e == 'required'));

  /**
   * Find the next number to add to new field names.
   * 
   * Assuming fields end with a number, find the max number.
   * Otherwise, just count the fields.
   */
  function nextNumber() {
    if (nextFieldIndex.value == undefined) {
      nextFieldIndex.value = max(columns.value
        .map(e => e.name)
        .filter(e => e.match(/\d+/))
        .map(e => parseInt(underscore(e).split("_").pop()))
      );
      if (isEmpty(nextFieldIndex.value)) {
        nextFieldIndex.value = columns.value.length;
      }
    }
    nextFieldIndex.value = nextFieldIndex.value + 1;
    return nextFieldIndex.value;
  }

  function addRow(after = undefined) {
    const row = defaultRow();
    const afterIndex = lastIndex(rows, after);
    toValue(form).definition.rows.splice(afterIndex + 1, 0, row);
  }

  function addColumn(row, after = undefined) {
    if (row.id) {
      row = row.id
    }
    const column = defaultColumn();
    const rowIndex = rows.value.findIndex(e => e.id == row);
    if (rowIndex !== -1) {
      const found = toValue(form).definition.rows[rowIndex];
      const afterIndex = lastIndex(found.columns, after);
      found.columns.splice(afterIndex + 1, 0, column);
    } else {
      console.error("Couldn't find row", row);
    }
  }

  function lastIndex(container, afterId = undefined) {
    if (afterId?.id) {
      afterId = afterId.id;
    }
    const containerLength = toValue(container)?.length || 0;
    if (afterId === undefined) {
      return containerLength;
    } else if (afterId === -1) {
      return afterId;
    } else {
      const foundIndex = toValue(container)?.findIndex(e => e.id == afterId);
      return foundIndex != -1 ? foundIndex : containerLength;
    }
  }

  function removeColumn(column) {
    if (column.id) {
      column = column.id
    }
    const row = rows.value.find(e => e.columns.find(f => f.id == column));
    if (row) {
      const index = row.columns.findIndex(e => e.id == column);
      if (index !== -1) {
        row.columns.splice(index, 1);
      }
      if (row.columns.length == 0) {
        removeRow(row);
      }
    }

  }

  /**
   * Item can be a row or id
   * @param {Object|String} item 
   */
  function removeRow(item) {
    
    if (item.id) {
      item = item.id
    }

    const rowIndex = rows.value.findIndex(e => e.id == item);
    if (rowIndex !== -1) {
      toValue(form).definition.rows.splice(rowIndex, 1);
    }

  }

  /**
   * Form definition rows filtered by conditions.
   * 
   * Rows with no columns/fields that are displayed will be removed.
   */
  const filteredRows = computed(() => {
    conditionCache = {};
    return rows.value
      .map(r => ({ ...r, columns: r.columns.filter(c => checkConditions(c)) }))
      .filter(e => e.columns.length > 0);
  });
  
  const filteredColumns = computed(() => {
    return filteredRows.value.flatMap(e => e.columns);
  });

  function findFieldByName(name) {
    return allFields.value.find(e => e.name === name);
  }

  /**
   * Map of all attributes for fields in this form.
   */
  const bindableAttributes = computed(() => {
    const result = {};
    allFields.value.forEach(field => {
      result[field.name] = buildFieldAttributes(field);
    });
    return result;
  });

  /**
   * Internal
   */
  function checkConditions(container) {

    if (conditionCache[container.id] !== undefined) {
      // console.log(`* cache hit for field/rule ${container.id}: ${conditionCache[container.id]}`);
      return conditionCache[container.id];
    }

    const conditions = container.conditions || [];
    const condition_join = container.condition_join
    const skipParent = container.condition_skip_parent;

    if (isEmpty(conditions)) {
      return true;
    }

    //OR only makes sense for multiple fields, so treat a single OR as an AND
    const matchOr = conditions.length > 1 && condition_join?.toLowerCase() == "or";

    for (const condition of conditions) {

      if (isEmpty(condition.source)) {
        console.warn(`condition[${condition.id}] has no source, assuming form!`)
      }

      //check that the parent form field is displayed
      if (!skipParent && (condition.source && condition.source == 'form')) {
        const parent = findFieldByName(condition.field);
        //this will recurse up 
        if (parent && !checkConditions(parent)) {
          if (matchOr) {
            //don't bother checking the actual condition, parent is hidden
            continue;
          }
          //for AND conditions, no point continuing
          conditionCache[container.id] = false;
          return false;
        }
      }

      if (conditionMatches(condition)) {
        if (matchOr) {
          conditionCache[container.id] = true;
          return true;
        }
      } else {
        if (!matchOr) {
          conditionCache[container.id] = false;
          return false;
        }
      }

    }
    
    //OR: no conditions matched or it would have returned true
    //AND: no conditions failed or it would have returned false
    conditionCache[container.id] = !matchOr;
    return conditionCache[container.id];

  }

  /**
   * Internal
   * 
   * @param {Object} condition 
   */
  function conditionMatches(condition) {

    const conditionStr = new String(condition.value).trim().toLowerCase();
    const value = resolveConditionValue(condition);
    const empty = isEmpty(value);
    const valueStr = new String(value).trim().toLowerCase();
    
    let exact = valueStr == conditionStr;
    let matches = exact || (value && valueStr.includes(conditionStr));

    //TODO: allow regex
    if (Array.isArray(value)) {
      matches = exact = value.some((e) =>
        e?.trim().toLowerCase().includes(conditionStr)
      );
      //TODO: won't match k/v
    }

    //TODO: handle model text/id matching

    switch (condition.match) {
      case "ANY":
        return !empty;
      case "NONE":
        return empty;
      case "EXACT":
        return exact;
      case "NEXACT":
        return !exact;
      case "CHECK":
        //NOTE: won't work with custom checkboxes, caveat emptor
        return toBool(value) === true;
      case "NCHECK":
        return toBool(value) === false;
      case "MATCH":
        return matches;
      case "NMATCH":
        return !matches;
      default:
        return matches;
    }
  }

  /**
   * Internal
   * 
   * @param {Object} condition 
   */
  function resolveConditionValue(condition) {
    
    if (!condition.id) {
      console.warn("resolveConditionValue: condition has no id", condition);
    } else {
      const cached = conditionCache[condition.id];
      if (cached !== undefined) {
        // console.log(`* cache hit for resolved value ${condition.id}: ${cached}`);
        return cached;
      }
    }

    let result = undefined;

    if (condition.source == "role") {
      result = usePortalStore().user?.role_names?.includes(condition.field);

    } else {

      let target = toValue(model);
      if (condition.source == "user") {
        target = usePortalStore().user;
      } else if (condition.source != "form") {
        const source = toValue(data)
        //TODO: this almost works, except for tasks it's buried in source.task.data
        target = (source && source[condition.source]) || {};
      }

      //dot-notation get from the target
      result = get(target, condition.field, 
        //default to model in all cases (in case workflow referring to current model)
        get(toValue(model), condition.field, null)
      );

      if (isEmpty(result) && condition.source == "workflow") {
        //special case when workflow refers to the current step, which is actually in the model
        const parts = condition.field.split(".");
        for (let i = parts.length; i > 0; i--) {
          const value = get(toValue(model), parts.slice(-i).join("."));
          if (! isEmpty(value)) {
            result = value;
            break;
          }
        }
      }

    }

    //TODO: this is getting called a lot, check if it can be optimised
    // console.log(`resolveConditionValue: [${condition.source}]${condition.field} = ${result}`);
    conditionCache[condition.id] = result;
    return result;

  }

  /**
   * Internal
   * 
   * @param {Object} field 
   */
  function buildFieldAttributes(field) {

    const attrs = [];

    //bind all config options and component defaults
    attrs.componentConfig = resolveComponent(field)?.config;
    attrs.fieldOptions = field.options;
    attrs.form = toValue(form);

    //custom attributes
    field.attributes.forEach(e => {
      attrs[e.key] = e.value || ""
    });

    //special handling for certain types
    if (field.options.type == 'static') {
      if (isCheckbox(field)) {
        console.warn("Checkbox should be boolean or custom, not static; making it boolean", field, toValue(form).key);
        field.options.type = 'boolean';
      } else {
        attrs.chips = true;
        attrs.items = field.options.options;
        if (field.options.config.titleAsKey) {
          attrs.items = attrs.items.map(e => ({ ...e, key: e.title, value: e.title }));
        }
      }
    }

    if (field.options.type == 'boolean') {
      field.options.trueValue = true;
      field.options.falseValue = false;
    }

    attrs.trueValue = field.options.trueValue;
    attrs.falseValue = field.options.falseValue;

    //ok to bind everything else, unused props are ignored
    attrs.readonly = readOnly.value;
    attrs.editing = editing.value;
    attrs.plain = toValue(form)?.definition?.format == 'plain';
    attrs.inline = field.options.inline;
    attrs.clearable = attrs.componentConfig?.dataSource?.clearable && ! attrs.readonly;
    attrs.multiple = attrs.componentConfig?.dataSource?.allowMultiselect && field.options.config.multiple;

    if (field.options.whole_numbers) {
      attrs.onkeypress='return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57';
    }

    //rules
    attrs.rules = attrs.readonly ? [] : buildFieldRules(field);

    //existing submission
    attrs.submission = submission.value;

    const hideLabel = attrs.componentConfig?.hideLabel && (
      attrs.componentConfig.hideLabel === true || 
      (Array.isArray(attrs.componentConfig.hideLabel) && attrs.componentConfig.hideLabel.includes(toValue(form).definition.format))
    );

    if (hideLabel) {
      attrs.label = null;
    }

    return attrs;
  
  }

  /**
   * Build rules for this field from the rule factory.
   * 
   * @param {Object} fieldRef 
   */
  function buildFieldRules(fieldRef) {

    const field = toValue(fieldRef);
    const ruleDefs = field.rules;

    const result = [];
    if (Array.isArray(ruleDefs)) {
      for (const rule of ruleDefs.filter(e => e.rule)) {
        
        if (rule.rule == 'required') { 
          if (isFileUpload(field)) {
            //file uploads need a special rule
            rule.rule = '_requiredFiles'; 
          } else if (isCheckbox(field)) {
            //handle custom true values
            rule.arg = field.options.type == 'custom' ? field.options.trueValue : true;
          }
        }

        //check that the rule conditions allow this rule to apply
        if (checkConditions(rule)) {
          result.push(ruleFactory[rule.rule](field.name, rule.arg, rule.message));
        }

      }
    } else {
      console.error('[useFormHelper] invalid rules definition', ruleDefs);
    }

    return result;

  }

  function fieldLabel(f) {
    return allFields.value[f]?.label || f;
  }

  function dateFieldValue(arg) {
    if (arg == 'today') {
      return arg;
    }
    return fieldValue(arg);
  }

  function fieldValue(f) {
    return toValue(model)[f];
  }

  function resolveComponent(column) {
    return componentStore.resolveField(column.component);
  }

  function isTab(field) {
    return toValue(field).component.includes('DivinityTab');
  }

  function isFileUpload(field) {
    return toValue(field).component.includes('FileUpload');
  }

  function isCheckbox(field) {
    return toValue(field).component.includes('Checkbox');
  }

  function generateId() {
    return crypto.randomUUID();
  }

  function copy(column) {
    if (! column.id) {
      column = columns.value.find(e => e.id == column);
    } 
    const copied = deepClone(column);
    do {
      copied.name += '_copy';
      copied.label += ' Copy';
    } while (columns.value.find(e => e.name == copied.name));
    copied.id = generateId();
    return copied;
  }


  function defaultFieldOptions() {
    return deepClone({
      type: null,
      url: null,
      listItem: null,
      options: [],
      config: {
        format: null,
        multiple: false,
        titleAsKey: false,
        regex: null,
        regex_filter: "include",
        regex_target: "text",
      },
      trueValue: "Yes",
      falseValue: "No",
    });
  }

  function defaultRule() {
    
    return deepClone({
      id: generateId(),
      type: "rule",
      rule: "required",
      message: null,
      arg: null,
      conditions: [],
      condition_join: "and",
    });
  }

  function defaultCondition() {
    return deepClone({
      id: generateId(),
      type: 'condition',
      source: null,
      field: null,
      match: 'ANY',
      value: null,
    })
  }

  function defaultColumn(index = undefined) {

    if (index === undefined) {
      index = nextNumber();
    }

    return deepClone({
      id: generateId(),
      type: "column",
      label: `New Field #${index}`,
      name: `new_field_${index}`,
      instructions: null,
      component: "standard/TextField.vue",
      rules: [defaultRule()],
      conditions: [],
      attributes: [],
      options: defaultFieldOptions()
    });

  }
  
  function defaultRow(column = undefined) {

    if (column === undefined) {
      column = defaultColumn();
    }

    return deepClone({
      id: generateId(),
      type: "row",
      columns: [column]
    });
    
  }

  return { 
    resolveComponent,
    isTab,
    isFileUpload,
    isCheckbox,
    defaultFieldOptions,
    matchStrategies,
    fieldHasStaticValues,
    fieldStaticValues,
    matchRequiresParameter,
    allowedMatchStrategies,
    allowedRules,
    rows, 
    columns,
    addRow,
    addColumn,
    copy,
    removeRow,
    removeColumn,
    defaultRow,
    defaultColumn,
    defaultRule,
    defaultCondition,
    nextNumber,
    fieldIsListItem,
    fieldListItemName,
    matchesForCast,
    editing,
    submission,
    readOnly,
    ruleNames,
    dateRules,
    fileRules,
    numberRules,
    checkboxRules,
    countRules,
    stringRules,
    bindableAttributes, 
    allFields, 
    fieldList,
    filteredRows,
    filteredColumns,
    generateId
  }

};
