<template>
  <slot :input="input">
    <component
      :is="inputComponent(input)"
      v-model="model"
      v-bind="{...input.attributes, ...$attrs}"
      :label="input.attributes.label || input.name"
      :rules="dynamicRules(input)"
    >
      <template v-if="graphStore.currentNode" #append-inner>
        <workflow-field-icon v-model="model" />
      </template>
    </component>
  </slot> 
</template>

<script setup>

const graphStore = useGraphStore();

defineOptions({
  inheritAttrs: false
});

const model = defineModel();

defineProps({
  input: {
    type: Object,
    required: true,
    default: () => ({})
  },
});

const componentStore = useComponentStore();
const rules = useFormRules();

function dynamicRules(input) {
  const result = [];
  if (input.required) {
    result.push(rules.required);
  }
  return result;
}

function inputComponent(input) {
  //migrating from 'type' to 'component'
  const lookup = input.component || input.type;
  const component = componentStore.resolveField(lookup);
  if (! component) {
    alert(`Component not found: ${lookup}`)
  }
  return component;
}

</script>