import { usePortalStore } from "~/stores/portal";

export const useFile = (fileKey) => {

  const portal = usePortalStore();

  const download = async () => {
    const response = await portal.get(`direct_uploads/${fileKey}`);
    const href = response.file.download_url;
    if (href) {
      window.open(href, "_blank");
    } else {
      console.error("useFile.download() failed to get download_url", response);
    }
  };

  const remove = async () => {
    return portal.delete(`direct_uploads/${fileKey}`);
  }

  return { download, remove };

};