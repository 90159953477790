import type { Node } from '@vue-flow/core';
import { computed, toValue } from 'vue'

interface NodeField {
  name: string;
  label?: string;
  type?: string;
  group?: string;
  options?: any[];
  lookup?: string;
}

export const useNodeFields = (node: Ref<Node>) => {

  toValue(node).data.data.fields ||= [];

  const fields = computed({
    get: () => toValue(node).data.data.fields,
    set(value) {
      toValue(node).data.data.fields = value;
    }
  });

  const ungroupedFields = computed(() => groupFields());

  /**
   * Add the field if it's not already present.
   * @param {NodeField} field  
   */
  function ensureField(field: NodeField) {
    if (! fieldExists(field)) {
      fields.value.push(field);
    }
  }

  /**
   * Overwrite the field if it's already present.
   * 
   * @param {NodeField} field  
   */
  function replaceField(field: NodeField) {
    const index = findFieldIndex(field);
    if (index !== -1) {
      fields.value[index] = field
    } else {
      fields.value.push(field);
    }
  }

  function fieldExists(field: NodeField) : boolean {
    return findFieldIndex(field) !== -1;
  }

  function findField(field: NodeField) : NodeField {
    return fields.value[findFieldIndex(field)];
  }

  function findFieldIndex(field: NodeField) : number{
    return fields.value.findIndex((f: NodeField) => match(f, field));
  }

  function match(field1: NodeField, field2: NodeField) : boolean {
    return field1.name == field2.name && matchGroup(field1.group, field2.group);
  }

  function matchGroup(group1?: string, group2?: string) : boolean {
    return (isEmpty(group1) && isEmpty(group2)) || group1 == group2;
  }

  function groupFields(group?: string) : NodeField[] {
    return fields.value.filter((f: NodeField) => matchGroup(f.group, group));
  }

  function clearGroup(group: string) {
    fields.value = fields.value.filter((f: NodeField) => f.group !== group);
  }

  function clearFields() {
    fields.value = [];
  }

  return {
    fields,
    ungroupedFields,
    ensureField,
    findField,
    replaceField,
    groupFields,
    clearGroup,
    clearFields
  }

}