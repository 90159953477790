import revive_payload_client_4sVQNw7RlN from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_RMzWu406ID from "/opt/render/project/src/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/render/project/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/opt/render/project/src/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_8SbxDRbG6Y from "/opt/render/project/src/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import _00_errorHandler_client_5Aukke6iw7 from "/opt/render/project/src/plugins/00.errorHandler.client.js";
import _01_keycloak_client_ElGzMA0zbu from "/opt/render/project/src/plugins/01.keycloak.client.js";
import _02_portal_client_mJvlHyuD7m from "/opt/render/project/src/plugins/02.portal.client.js";
import cable_client_YkXGw7NRyT from "/opt/render/project/src/plugins/cable.client.js";
import draggable_client_TCXd72fVBT from "/opt/render/project/src/plugins/draggable.client.js";
import imports_client_OlzWc19XqT from "/opt/render/project/src/plugins/imports.client.js";
import jsonEditor_client_TwGg0OfGrs from "/opt/render/project/src/plugins/jsonEditor.client.js";
import toast_client_437j7GlIrd from "/opt/render/project/src/plugins/toast.client.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/opt/render/project/src/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  plugin_8SbxDRbG6Y,
  _00_errorHandler_client_5Aukke6iw7,
  _01_keycloak_client_ElGzMA0zbu,
  _02_portal_client_mJvlHyuD7m,
  cable_client_YkXGw7NRyT,
  draggable_client_TCXd72fVBT,
  imports_client_OlzWc19XqT,
  jsonEditor_client_TwGg0OfGrs,
  toast_client_437j7GlIrd,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]