<template>
  <NuxtLoadingIndicator :height="5" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.Vue-Toastification__toast--warning {
  background-color: #ff8000;
}

</style>