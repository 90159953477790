// import dayjs from "dayjs";

/**
 * Dayjs timezone not working??
 *
 * @param {*} input
 * @param {*} fallback
 */
export default function (value, fallback = undefined) {
  
  // const tested = dayjs(value);

  if (!value) return fallback;
  if (value instanceof Date) return value;
  if (parseInt(value) == value) {
    return new Date(value);
  }

  value = new String(value);
  if (value.trim().toLowerCase() == "today") {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
  }

  let sep = "-";
  if (value.includes("/")) {
    sep = "/";
  } else if (value.includes(".")) {
    sep = ".";
  }

  let parts = value.split(sep);
  let month = parts[1] - 1; //months are 0-based
  let year = 0;
  let day = 0;
  if (value.indexOf(sep) > 2) {
    //yyyy-mm-dd
    year = parts[0];
    day = parts[2];
  } else {
    //dd-mm-yyyy
    year = parts[2];
    day = parts[0];
  }

  return new Date(year, month, day);
}
