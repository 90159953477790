<template>
  <html-editor />
</template>

<script>
export default {
  config: {
    dataSource: {
      cast: 'string'
    }
  }
}
</script>