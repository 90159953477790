<template>
  <v-progress-circular
    v-if="pending"
    indeterminate
  />
  <span v-else>{{ data.workflow_role.title }}</span>
</template>

<script setup>

const props = defineProps({
  roleId: {
    type: [Number, String],
    required: true
  }
});

const { data, pending } = useCachedApi(`admin/workflow_roles/${props.roleId}`);

</script>