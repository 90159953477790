import { isEmpty } from 'lodash-es'
import { toRaw, toValue } from 'vue'

/**
 * Lodash isEmpty only works for collections/objects, so
 * test primitives first.
 * 
 * @param {any} v 
 * @see https://www.sitepoint.com/testing-for-empty-values/
 */
export default function(value) {

  const check = toRaw(toValue(value));

  //these can never be 'empty' (0/false are still real values)
  if (typeof check === 'number' || typeof check === 'boolean') {
    return false;
  }
  
  //check if data is undefined or null
  if (typeof check === 'undefined' || check === undefined || check === null) {
    return true;
  }

  //empty(ish) string
  if (typeof check === 'string') {
    return check.trim().length === 0;
  }

  //objects or collections
  return isEmpty(check);

}