<template>
  <component
    :is="rowComponent"
    v-for="(input, index) in inputs" 
    :key="index"
    dense
  >
    <slot name="before" :input="input" />

    <th v-if="table">
      {{ input.attributes.label || input.name }}
    </th>

    <component :is="colComponent">
      <div>
        <slot name="before-input" :input="input" />
        <slot name="input" :input="input">
          <dynamic-input
            v-model="model[input.name]"
            :input="input"
          />
        </slot>
        <slot name="after-input" :input="input" />
      </div>
    </component>
    <slot name="after" :input="input" />
  </component>
</template>

<script setup>

defineOptions({
  inheritAttrs: false
});

const model = defineModel({ 
  type: Object,
  required: true,
  default: () => ({test: 'hi'}) 
});

const props = defineProps({
  inputs: {
    type: Array,
    required: true,
    default: () => []
  },
  table: Boolean,
});

const rowComponent = computed(() => {
  return props.table ? 'tr' : 'v-row';
});

const colComponent = computed(() => {
  return props.table ? 'td' : 'v-col';
});

</script>