<template>

  <v-container fluid>
    
    <v-row dense>
      <v-col v-if="supervision?.primary_supervisor">
        <ContactName
          :key="supervision?.primary_supervisor.uuid"
          label="Primary Supervisor"
          :user-id="supervision?.primary_supervisor.uuid"
          as-field
        />
      </v-col>
    </v-row>

    <v-row
      v-for="(supervisor, index) in supervision?.secondary_supervisors"
      :key="index"
      dense
    >
      <v-col>
        <ContactName
          :key="supervisor.uuid"
          :label="`Secondary Supervisor ${index + 1}`"
          :user-id="supervisor.uuid"
          as-field
        />
      </v-col>
    </v-row>

  </v-container>

</template>

<script setup>

//TODO: field config

const props = defineProps({
  submission: {
    type: Object,
    required: false,
    default: () => {}
  },
  primary: Boolean
});

const { data, pending } = useApi('/hdr/supervision/student_supervisors', {
  query: {
    user_id: props.submission?.user_id || usePortalStore().user.id,
    primary: props.primary
  }
});

const supervision = computed(() => data.value.supervision);

</script>