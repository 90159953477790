<template>
  <v-container fluid class="pa-0">

    <v-row dense no-gutters>
      <v-col>
        <v-autocomplete
          v-model="jobClass"
          label="Job Class" 
          :items="jobClasses"
          :loading="pending"
          :rules="required ? [v => !!v || 'Job class is required'] : []"
          v-bind="$attrs"
          @update:model-value="onUpdateJobClass"
        >
          <template #item="{ props: itemProps, item }">
            <v-list-item 
              v-bind="itemProps"   
              :base-color="isRunnable(item.raw) ? null : 'red'"
              :subtitle="inputSummary(item.raw)"
            />
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row
      v-if="filterRunnable || inlineable"
      dense
      no-gutters
      style="margin-top: -12px;"
    >
      <v-col v-if="filterRunnable">
        <v-switch
          v-model="runnable" 
          :label="runnableLabel" 
        />
      </v-col>
      <v-col v-if="inlineable" cols="3">
        <v-switch
          v-model="jobMethod" 
          label="Perform inline" 
          true-value="perform_now"
          false-value="perform_later"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="summariseInputs"
      dense
      no-gutters
    >
      <v-col>
        <v-chip-group column>
          <v-chip
            v-for="input in selectedJob.inputs" 
            :key="input.name"
            size="small"
          >
            {{ input.name }}
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <template v-else-if="! hideInputs">
      <dynamic-inputs
        v-model="jobArgs"
        :inputs="selectedJob.inputs"
        :disabled="disableInputs"
        v-bind="$attrs"
      />

      <dynamic-inputs-missing 
        :missing="actualMissing(selectedJob)"
      />
      
    </template>

  </v-container>
</template>

<script setup>

const jobClass = defineModel({
  type: String,
  required: false,
  default: undefined
});

const jobMethod = defineModel('method', {
  type: [String,null,undefined],
  required: false,
  default: 'perform_later'
});

const jobArgs = defineModel('args', {
  type: Object,
  required: false,
  default: () => ({})
});

const jobOutputs = defineModel('outputs', {
  type: Array,
  required: false,
  default: () => []
})

const props = defineProps({
  jobs: {
    type: Array,
    required: false,
    default: () => []
  },
  withInputs: {
    type: Array,
    required: false,
    default: () => []
  },
  filterRunnable: {
    type: Boolean,
    default: true
  },
  inlineable: Boolean,
  summariseInputs: Boolean,
  hideInputs: Boolean,
  disableInputs: Boolean,
  required: Boolean,
  requiresInputs: Boolean
});

const runnable = ref(props.filterRunnable);
const jobsSource = ref(props.jobs || []);
const noJobsSupplied = computed(() => props.jobs.length < 1);

const { data, pending, execute } = useApi('/jobs', { immediate: false, default: [] });
if (noJobsSupplied.value) {
  execute();
}

const jobClasses = computed(() => jobsSource.value?.filter(e => runnable.value ? isRunnable(e) : e) || []);
const selectedJob = computed(() => jobClasses.value.find(e => e.name == jobClass.value) || {});
const runnableLabel = computed(() => {
  const inputList = props.withInputs.map(e => `'${e}'`).join(', ');
  const supplied = props.withInputs.length > 0 ? ` or accept ${inputList}` : '';
  return `Only show runnable jobs (required args have UI inputs${supplied})`;
});

watch(selectedJob, () => jobOutputs.value = selectedJob.value.outputs)

watch(data, ()=> {
  if (noJobsSupplied.value) {
    jobsSource.value = data.value
  }
});

function onUpdateJobClass() {
  jobArgs.value = {};
}

function inputNames(job) {
  return job?.inputs?.map(e => e.name) || [];
}

function missingArgs(job) {
  const inputs = inputNames(job);
  return job?.required_args?.filter(e => !inputs.includes(e)) || [];
}

function actualMissing(job) {
  return missingArgs(job).filter(e => !props.withInputs.includes(e));
}

function suppliedArgs(job) {
  return job?.perform_args?.filter(e => props.withInputs.includes(e));
}

function hasRequestedArgs(job) {
  if (props.withInputs.length < 1 || job.perform_args?.includes('kwargs')) {
    return true;
  }
  return props.withInputs.every(e => job.perform_args.includes(e));
}

function isRunnable(job) {
  if (props.requiresInputs && job.perform_args?.length < 1) {
    return false;
  }
  return actualMissing(job).length < 1 && hasRequestedArgs(job);
}

function inputSummary(job) {

  if (job.perform_args.length < 1) {
    return 'No job args';
  }

  const inputPlural = job.inputs.length == 1 ? '' : 's';
  const inputs = `${job.inputs.length} input${inputPlural}`;
  const argPlural = job.perform_args.length == 1 ? '' : 's';
  const kwargs = job.perform_args?.includes('kwargs') ? ', accepts kwargs' : '';
  const normal = `${job.perform_args.length} job arg${argPlural} (${job.required_args.length} required, ${inputs}${kwargs})`;

  const _suppliedArgs = suppliedArgs(job);
  let supplied = _suppliedArgs.length ? `, ${_suppliedArgs.length} supplied` : '';
  const missingSupplied = suppliedArgs(job).filter(e => ! job.perform_args?.includes(e));
  if (missingSupplied.length) {
    supplied = `${supplied} (missing: ${missingSupplied.join(', ')})`;
  }

  const missingArgs = actualMissing(job);
  const missing = missingArgs.length ? `, ${missingArgs.length} missing (${missingArgs.join(', ')})` : '';
  
  return `${normal}${supplied}${missing}`

}

</script>