<template>

  <component
    :is="component"
    style="margin-right: 6px;"
    @click="download"
  >
    <v-icon>{{ computedIcon }}</v-icon>
    {{ file.filename }}
  </component>

</template>

<script setup>

const portal = usePortalStore();

const props = defineProps({
  file: {
    type: Object,
    required: true
  },
  variant: {
    type: String,
    required: false,
    default: 'chip'
  },
  icon: {
    type: String,
    required: false,
    default: 'mdi-download'
  }
});

const downloaded = ref(false);

const computedIcon = computed(() => downloaded.value ? 'mdi-check' : props.icon);
const component = computed(() => props.variant === 'chip' ? 'v-chip' : 'v-btn');
const url = computed(() => `${portal.config.base_url}${props.file.url || props.file.download_url}`)

function download() {
  window.open(url.value, "_blank");
  downloaded.value = true;
}

</script>