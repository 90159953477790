<template>
  <div>

    <!-- note no rules on the inner components -->
    <v-checkbox
      v-for="(item, index) in $attrs.items"
      :key="index"
      v-bind="checkboxAttrs"
      multiple
      hide-details
      single-line
      density="compact"
      :label="item.text || item.title"
      :true-value="item.value || item.key || item.text || item.title"
      :error="isError"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData || {}" />
      </template>
    </v-checkbox>

    <debug title="modelValue" raw>
      {{ $attrs.modelValue }}
    </debug>

    <debug title="checkboxAttrs">
      {{ checkboxAttrs }}
    </debug>

    <!-- hidden input to track and display errors -->
    <v-input
      v-model="$attrs.modelValue"
      :rules="$attrs.rules"
      @update:error="onError"
    />

  </div>
</template>

<script>

/**
 * TODO: isError is false on first load even if errors present (only a problem for draft loading)
 */
export default {
  config: {
    rules: ['required', 'countMin', 'countMax'],
    dataSource: {
      required: true,
      cast: 'array'
    }
  },
}
</script>

<script setup>

import { pickBy } from 'lodash-es'

const isError = ref(false);
const attrs = useAttrs();

const forbidAttrs = ['items', 'rules', 'id'];
const checkboxAttrs = computed(() => pickBy(attrs, (_, k) => !forbidAttrs.includes(k)));

function onError(err) {
  isError.value = err;
}
</script>