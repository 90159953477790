import {VDataTable,VDataTableServer} from 'vuetify/components/VDataTable'
import {VRow,VCol} from 'vuetify/components/VGrid'
import {VChip} from 'vuetify/components/VChip'
import {VBtn} from 'vuetify/components/VBtn'
import * as labsComponents from 'vuetify/labs/components'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"display":{"mobileBreakpoint":"sm"},"defaults":{"VCheckbox":{"color":"blue-darken-1"},"VBtn":{"variant":"elevated"},"VAutoComplete":{"autoSelectFirst":true},"VSelect":{"autoSelectFirst":true},"VCombobox":{"autoSelectFirst":true}},"theme":{"defaultTheme":"divinity","themes":{"divinity":{"variables":{"disabled-opacity":0.68}}}}}
  
  
  options.components = {VDataTable,VDataTableServer,VRow,VCol,VChip,VBtn,...labsComponents}
  
  return options
}

