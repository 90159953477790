<template>
  <div class="d-flex flex-column">
    <v-input 
      v-model="signed"
      :rules="($attrs.readonly || $attrs.previewing) ? [] : [v => !!v || 'Please sign this form to continue.']"
    />

    <div class="d-flex">
      <v-text-field
        :model-value="signatureText"
        disabled
        prepend-inner-icon="mdi-signature-freehand"
        class="mr-3"
      />
      
      <div v-if="! $attrs.readonly" style="margin-top: 12px; margin-right: 6px">
        <v-icon v-if="signed" @click="sign(false)">
          mdi-close
        </v-icon>

        <v-btn
          v-else
          color="primary"
          v-bind="$attrs"
          @click="sign(true)"
        >
          <v-icon>mdi-pen</v-icon>
          Click to Sign
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {

  config: {
    defaults: {
      label: 'Signature',
      instructions: 'Click the button below to sign this form. This is the equivalent of your digital signature.'
    },
    dataSource: {
      cast: 'string',
    },
    rules: false
  },

  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    }
  },

  emits: ['update:modelValue'],

  computed: {
    signatureText() {
      return this.signed ? this.modelValue : 'Awaiting your signature...';
    },

    signatureValue() {
      const date = dayjs().format('dddd D MMMM YYYY, h:mm A');
      return `Signed by ${this.$portal.user.full_name} on ${date}`;
    },

    signed() {
      return !! this.modelValue; // && this.fieldValue?.includes('Signed by');
    }
  },

  methods: {
    sign(val = true) {
      this.$emit('update:modelValue', val ? this.signatureValue : null);
    }
  }
}
</script>