<template>
  <v-select
    :item-value="e => e.event || e.key"
    item-title="label"
    label="Action"
    single-line
    chips
  >

    <template #item="{ props, item }">
      <v-list-item 
        v-bind="props" 
        :subtitle="item.raw.description" 
      >
        <template #title>
          <v-chip
            variant="elevated"
            tile
            :color="item.raw.attributes.color"
            :text="item.raw.label"
            :prepend-icon="item.raw.attributes.icon"
          />
        </template>
      </v-list-item>
    </template>

    <template #chip="{ props, item }">
      <v-chip 
        v-bind="props" 
        tile
        variant="elevated"
        :color="item.raw.attributes.color" 
        :text="item.raw.label" 
        :prepend-icon="item.raw.attributes.icon"
        style="margin-right: 6px;"
      />
      {{ item.raw.description }}
    </template>

  </v-select>
</template>

<script setup>
</script>