<template>
  <v-container fluid class="no-padding">

    <v-row dense no-gutters>

      <v-col cols="12">

        <v-card
          variant="outlined"
          style="border-style: dashed"
          :class="{ 'grey lighten-2': dragover }"
          :ripple="false"
          @drop.prevent="onDrop"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          @click="cardClick"
        >
          <v-card-text>

            <v-row
              class="d-flex flex-column"
              dense
              align="center"
              justify="center"
            >
              <v-icon v-if="dragover">
                mdi-plus-circle
              </v-icon>
              <v-icon v-else>
                mdi-cloud-upload
              </v-icon>
              <v-file-input
                :id="inputName"
                v-model="filesTemp"
                style="display: none;"
                :prepend-icon="dragover ? 'mdi-plus-circle' : 'mdi-cloud-upload'"
                :multiple="multiple"
                :accept="accept"
                @update:model-value="onUpload"
              />
              <template v-if="errors.length > 0">
                <v-alert
                  v-for="(error, index) in errors"
                  :key="error.name"
                  type="info"
                  density="compact"
                  text
                  variant="outlined"
                  @click.stop.prevent="errors.splice(index, 1)"
                >
                  {{ error.message }}

                  <template #close>
                    <v-icon size="small" @click.stop.prevent="errors.splice(index, 1)">
                      mdi-close-circle
                    </v-icon>
                  </template>

                </v-alert>
              </template>
              <p v-else>
                {{ hint }}
              </p>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="withChips">

      <slot name="chips.column">
        <v-col>
          <slot name="chips.group">

            <v-chip-group column>

              <template v-for="(file, index) in files">
                <slot
                  name="chip"
                  :file="file"
                  :index="index"
                >

                  <v-chip
                    :key="file.name"
                    :closable="!isUploading(file.name) && !readonly"
                    :color="isUploading(file.name) ? null : 'green'"
                    @click:close="onRemove(file, index)"
                    @click="isUploading(file.name) ? null : downloadFile(file)"
                  >

                    <template v-if="isUploading(file.name)">
                      <v-progress-circular
                        :model-value="uploadProgress(file.name)"
                        :indeterminate="uploadProgress(file.name) == 0"
                        size="15"
                        width="2"
                      />
                      <i>{{ file.name }}...</i>
                    </template>

                    <template v-else>
                      <v-icon>mdi-paperclip</v-icon>
                      {{ file.name }}
                    </template>

                  </v-chip>

                </slot>
              </template>
            </v-chip-group>
          </slot>
        </v-col>
      </slot>
    </v-row>

  </v-container>
</template>

<script>

import { DirectUpload } from 'activestorage'

/**
 * Uploads raw files to vultr and replaces the model with the rails blob record.
 * @see https://george-hadjigeorgiou97.medium.com/step-by-step-custom-drag-drop-upload-component-in-vuetify-vue-2-43c99794643d
 */
export default {

  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    accept: {
      type: String,
      required: false,
      default: undefined
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    withChips: {
      type: Boolean,
      required: false,
      default: true
    },
    autoUpload: {
      type: Boolean,
      required: false,
      default: true
    },
    hint: {
      type: String,
      required: false,
      default: 'Drop your file(s) here, or click to select them.'
    },
    readonly: Boolean
  },

  emits: ['click:file', 'uploading', 'progress', 'upload', 'update:modelValue', 'remove'],

  data() {
    return {
      dragover: false,
      filesTemp: [],
      errors: [],
      progress: []
    }
  },

  computed: {
    files: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    inputName() {
      return `file-input-${crypto.randomUUID()}`;
    }
  },

  watch: {
    progress: {
      handler() {
        let progressSum = 0;
        for (const p of this.progress) {
          progressSum = parseFloat(progressSum) + parseFloat(p.progress);
        }
        const progressCount = this.files.length;
        const totalPercent = (progressSum / progressCount).toFixed(0);
        this.$emit('progress', parseFloat(totalPercent));
        this.$emit('uploading', this.progress.some(e => e.uploading));
      },
      deep: true
    }
  },

  methods: {
    onDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach(f => this.doUpload(f));
    },
    onUpload() {
      this.filesTemp.forEach(f => this.doUpload(f));
      this.filesTemp.splice();
    },
    onRemove(file, index) {
      this.doRemove(file, index);
    },

    allowed(file) {
      if (file.name) {
        file = file.name;
      }
      if (isEmpty(this.accept)) {
        return true;
      }
      return this.accept.split(',').some(type => file.endsWith(type));
    },

    async doUpload(file) {

      if (! this.allowed(file)) {
        this.errors.push({ message: `File '${file.name}' is not an allowed type.`, name: file.name });
        return;
      }

      //prevent duplicate filenames
      if (this.files.some(e => e.name == file.name)) {
        this.errors.push({ message: `File '${file.name}' has already been uploaded.`, name: file.name });
        return;
      }

      //add directly, we may replace it with a rails blob record later
      this.files.push(file);

      if (this.autoUpload) {

        this.startProgressFor(file.name);

        const upload = new DirectUpload(file, this.$portal.config.direct_upload_url, this.listener(file));
        upload.create((error, uploaded) => {
          if (error) {
            this.addError(error, file.name);
            this.removeProgressFor(file.name);
            const foundIndex = this.files.findIndex(e => e.name == file.name);
            if (foundIndex != -1) {
              this.files.splice(foundIndex, 1);
            }
          } else {
            //replace the raw file with our uploaded blob record
            uploaded.name = uploaded.filename;
            const index = this.files.findIndex(e => e.name == uploaded.name);

            //if we can't find it, user removed the raw file, so ignore uploaded blob;
            //it will have to be cleaned up later with an orphan blob job
            if (index != -1) {
              this.files.splice(index, 1, uploaded);
              this.$emit('upload', uploaded);
            }
          }
        });

      } else {
        this.$emit('upload', file);
      }

      this.$emit('update:modelValue', this.files);
    },

    async doRemove(file, index) {

      this.files.splice(index, 1);
      this.$emit('remove', file, index);
      this.$emit('update:modelValue', this.files);
      this.removeProgressFor(file.name);

      if (this.autoUpload) {
        if (file.key) {
          //remove from server if it's a real file 
          // this.$portal.delete(`/direct_uploads/${file.key}`);
          useFile(file.key).remove();
        } else {
          //TODO: ideally cancel the upload, but otherwise blob upload will still finish, 
          //but won't populate the file array; this will make unattached blobs which 
          //can be cleaned up in a job.
        }
      }

    },

    downloadFile(file) {
      useFile(file.key).download();
    },

    cardClick() {
      //a bit sneaky...
      document.getElementById(this.inputName).click();
    },

    addError(message, file) {
      this.errors.push({
        message, name: file.name
      });
    },

    hasError(name) {
      return this.errors.some(e => e.name == name);
    },

    errorFor(name) {
      return this.errors.find(e => e.name == name)?.message;
    },

    isUploading(name) {
      return !!this.progressFor(name)?.uploading;
    },

    uploadProgress(name) {
      return this.progressFor(name)?.progress;
    },

    startProgressFor(name) {
      this.removeProgressFor(name);
      this.progress.push({
        name: name,
        progress: 0,
        uploading: true
      });
    },

    progressFor(name) {
      return this.progress.find(e => e.name == name)
    },

    removeProgressFor(name) {
      const index = this.progress.findIndex(e => e.name == name);
      if (index != -1) {
        this.progress.splice(index, 1);
      }
    },

    listener(file) {
      const that = this;
      return {

        directUploadWillCreateBlobWithXHR(request) {
          console.debug('directUploadWillCreateBlobWithXHR', request);
        },

        /**
         * @see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/upload
         */
        directUploadWillStoreFileWithXHR(request) {
          request.upload.addEventListener("progress", event => this.progress(event, file));
          request.upload.addEventListener("loadend", event => this.loadend(event, file));

          request.upload.addEventListener("loadstart", event => console.debug('loadstart '+ file.name, event));
          request.upload.addEventListener("abort", event => console.debug('abort '+ file.name, event));
          request.upload.addEventListener("error", event => console.debug('error '+ file.name, event));
          request.upload.addEventListener("load", event => console.debug('load '+ file.name, event));
          request.upload.addEventListener("timeout", event => console.debug('timeout '+ file.name, event));

        },
        loadend(event, file) {
          const progress = that.progressFor(file.name);
          if (progress) {
            progress.uploading = false;
          } else {
            // console.debug('loadend: missing progress for ' + file.name)
          }
        },

        progress(event, file) {
          const percent = ((event.loaded / event.total) * 100).toFixed(1);
          // console.debug(`[${file.name}] ${percent}%`);
          const progress = that.progressFor(file.name);
          if (progress) {
            progress.progress = percent;
          } else {
            // console.debug('progress: missing progress for ' + file.name)
          }
        },
      }
    }
  }

}
</script>

<style scoped>
.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
</style>