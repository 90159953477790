import { usePortalStore } from "~/stores/portal";

export default {
  online: true,

  // Called once when the subscription is created.
  initialized() {
    this.update = this.update.bind(this);
  },

  // Called when the subscription is ready for use on the server.
  connected() {
    this.install();
    this.update();
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    this.uninstall();
  },

  // Called when the subscription is rejected by the server.
  rejected() {
    this.uninstall();
  },

  update() {
    const newState = this.documentIsActive();
    if (this.online !== newState) {
      this.online = newState;
    //   console.debug(`presence.update documentIsActive:${this.documentIsActive()}, visibilityState:${document.visibilityState}, hasFocus:${document.hasFocus()}`)
    //   console.debug("\tpresence.update state ->", this.online, newState)
      this.online ? this.appear() : this.away();
    }
  },

  appear() {
    this.perform("appear");
  },

  away() {
    this.perform("away");
  },

  received(event) {
    const portal = usePortalStore();

    if (event.class == "User") {
      // console.debug(`[cable] userUpdateConsumer received`, event);

      if (portal.user.id == event.item.id) {
        portal.user = event.item;
      } else {
        //TODO: this shouldn't really happen, but pinia is losing the user, perhaps from hot reload in dev
        console.error(
          `Invalid user update (wrong user: event.item.id[${event.item.id}] != portal.user.id[${portal.user.id}]):`,
          event.item
        );
        // portal.fetchProfile();
      }

      //TODO: listen for application changes and reload profile?
    } else if (this.isUserMeta(event)) {
      if (event.action == "destroy") {
        delete portal.user.meta[event.item.key];
      } else {
        portal.user.meta[event.item.key] = event.item.value;
      }
    }
  },

  isUserMeta(event) {
    const portal = usePortalStore();
    return (
      event.class == "MetaValue" &&
      event.item.target_type == "User" &&
      event.item.target_id == portal.user.id
    );
  },

  install() {
    window.addEventListener("focus", this.update);
    window.addEventListener("blur", this.update);
    document.addEventListener("visibilitychange", this.update);
  },

  uninstall() {
    window.removeEventListener("focus", this.update);
    window.removeEventListener("blur", this.update);
    document.removeEventListener("visibilitychange", this.update);
  },

  documentIsActive() {
    return document.visibilityState === "visible" && document.hasFocus();
  },
};
