<template>
  <v-timeline-item
    fill-dot
    tile
    width="70vw"
    :dot-color="response.attributes?.color"
    :icon="response.attributes?.icon"
  >
    <v-expansion-panels
      v-if="response.data" 
      variant="accordion"
      bg-color="grey-lighten-3"
      elevation="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-title>
          <v-list-item
            :title="response.text"
            :subtitle="formatDate(response.date)"
            class="pl-2"
          />
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-card variant="outlined">
            <v-sheet>
              <v-table>
                <tbody>
                  <tr v-for="(field, index) in orderedResponse" :key="index">
                    <th>
                      {{ field.label }}
                    </th>
                    <td>
                      <response-value :value="field.value" />  
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-sheet>
          </v-card>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-alert v-else>
      <v-list-item
        :title="response.text"
        :subtitle="formatDate(response.date)"
        @click="clicked(response)"
      />
    </v-alert>
    
    <debug title="response">
      {{ response }}
    </debug>
  </v-timeline-item>
</template>

<script setup>

import { sortBy } from 'lodash-es';
import ResponseValue from './ResponseValue.vue';

const props = defineProps({
  response: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['click']);

const orderedResponse = computed(() => sortBy(props.response.data, 'order'));

function clicked(response) {
  if (isEmpty(response.data)) {
    emit('click', response.action);
  }
}

</script>

<style scoped>

/* .v-expansion-panel-text >>> .v-expansion-panel-text__wrapper {
  padding: 0;
} */

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

th {
  width: 25%;
  background-color: #f5f5f5;
  font-weight: bold !important;
}

</style>