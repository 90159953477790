/* eslint-disable no-undef */
import { defineStore } from 'pinia'

export const useBreadcrumbsStore = defineStore('breadcrumbs', {

  state: () => ({
    crumbs: []
  }),

  actions: {
    
    add(application) {

      if (! application) {
        return;
      }

      if (application.path == '/') {
        return;
      }

      if (this.crumbs.some(e => e.path == application.path)) {
        return;
      }

      this.crumbs.push(application);

    },

    removeCurrentRoute() {
      this.removeByRoute(useRoute());
    },

    removeByRoute(route) {
      this.remove({ path: route.path });
    },

    remove(application) {
      const index = this.crumbs.findIndex(e => e.path == application.path)
      if (index != -1) {
        this.crumbs.splice(index, 1);
      }
    },

    clear() {
      this.crumbs = [];
    }

  }

});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBreadcrumbsStore, import.meta.hot));
}