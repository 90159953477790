<template>
  <file-upload-area />
</template>

<script>

//FIXME: replace with standard FileUploadSingle

export default {
  config: {
    dataSource: {
      cast: 'file',
      allowMultiselect: true,
    }
  }
}
</script>