import { useRuntimeConfig } from '#app';
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'

const toast = useToast();

export const useMessageStore = defineStore('message', {

  state: () => ({
    sticky: []
  }),

  actions: {

    /**
     * Show a toast message.
     * 
     * @param {String|Component} simple message, or vue component to display
     * @param {String} type - match to method on toast (info, success, error, warning)
     * @param {Number|Boolean} timeout - false will create a sticky message, leave undefined for .env default
     */
    add(message, type='info', props = {}) {

      const runtimeConfig = useRuntimeConfig();
      if (props.timeout === undefined) {
        props.timeout = runtimeConfig.public.messageTimeout;
      }

      const id = toast[type](message, props);
      console.debug('toast', { type, message, props, id });

      if (props.timeout === false) {
        this.sticky.push(id);
      }

      return id;

    },

    success(message, options = { timeout: undefined }) {
      return this.add(message, 'success', options);
    },

    info(message, options = { timeout: undefined }) {
      return this.add(message, 'info', options);
    },

    warn(message, options = { timeout: undefined }) {
      return this.warning(message, options);
    },

    warning(message, options = { timeout: undefined }) {
      return this.add(message, 'warning', options);
    },

    error(message, options = { timeout: false }) {
      return this.add(message, 'error', options);
    },

    dismiss(id) {
      if (! Array.isArray(id)) {
        id = [id];
      }
      id.forEach(e => {
        console.debug('toast.dismiss', e);
        toast.dismiss(e)
      });
    },

    clear() {
      return toast.clear();
    },

    clearSticky() {
      const count = this.sticky.length;
      this.sticky.forEach(id =>{
        toast.dismiss(id);
      });
      this.sticky = [];
      return count;
    }
  }

});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessageStore, import.meta.hot));
}