<template>
  <h1>This is a placeholder component. It should not be used directly</h1>
</template>

<script>
export default {
  config: {
    placeholder: true
  }
}
</script>