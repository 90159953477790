<template>
  <html-display :content="message" />
</template>

<script>
export default {
  config: {
    fieldName: false,
    label: false,
    rules: false,
    attributes: false
  }
}
</script>

<script setup>

const message = defineModel({
  type: String
})

defineExpose({ 
  config: {
    dataSource: {
      cast: 'string',
    }
  } 
});
</script>