<template>

  <div>

    <workflow-notification-summary 
      v-if="summary"
      :node="node"
    />

    <v-form v-else @submit.prevent>
      <v-container fluid>

        <workflow-notification-target 
          v-model="node.data.data.notification.target" 
          :node="node"
        />

        <workflow-mailer-classes 
          v-model="node.data.data.notification" 
          :mailer-definitions="data.mailer_definitions"
          :node="node"
        />

        <dynamic-inputs 
          v-model="node.data.data.notification.inputs" 
          :inputs="inputs" 
        />

        <select-list
          v-model="node.data.data.notification.attachments"
          heading="Attachments"
          :min="0"
          :required="false"
        >
          <template #heading.text="{ text }">
            {{ text }}
          </template>
        </select-list>

      </v-container>
    </v-form>

  </div>

</template>

<script setup>

const node = defineModel({
  type: Object,
  required: true,
});

onMounted(() => {
  node.value.data.data ||= {}
  node.value.data.data.fields ||= [];
  node.value.data.data.notification ||= {}
  node.value.data.data.notification.inputs ||= {}
  node.value.data.data.notification.target ||= {}
  node.value.data.data.notification.attachments ||= []
});

const props = defineProps({
  summary: Boolean,
  data: {
    type: Object,
    required: false,
    default: () => ({})
  }
});

const selectedMailerClass = computed(() => node.value.data.data.notification.mailer_class); 
const selectedDefinition = computed(() => props.data.mailer_definitions.find(e => e.mailer_class === selectedMailerClass.value));
const inputs = computed(() => selectedDefinition.value?.inputs || []);

</script>