<template>

  <list-item-select
    list-name="staffplus_entity"
    :placeholder="$attrs.placeholder || 'Please select an option - click or start typing to search'"
  />

</template>

<script>
/**
 * TODO: options in forms admin
 * - limit to appointments
 * - limit to entity types
 */
export default {

  config: {
    defaults: {
      options: {
        type: 'list_item',
        listItem: 'staffplus_entity'
      }
    },
    dataSource: {
      required: false,
      allowMultiselect: true,
      cast: 'string'
    }
  },
    
}
</script>