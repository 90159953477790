<template>
  <div v-if="portal.debug">
    <p v-if="raw" class="bg-yellow">
      {{ realTitle }}{{ realTitle ? ": " : "" }} 
      <slot />
    </p>
    <v-expansion-panels v-else>
      <v-expansion-panel>
        <v-expansion-panel-title color="yellow">
          Debug {{ realTitle }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <pre>
            <slot />
          </pre>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>

const portal = usePortalStore();

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: ""
  },
  name: {
    type: String,
    required: false,
    default: ""
  },
  raw: {
    type: Boolean,
    required: false,
    default: false
  }
});

const realTitle = computed(() => props.title || props.name);

</script>