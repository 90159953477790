import { useNuxtApp, defineNuxtRouteMiddleware, useRuntimeConfig } from "#app";

/**
 * Check whether the KC token needs to be refreshed.
 * 
 * Will run on every route. Nothing will happen unless
 * token is about to expire.
 * 
 * We need async/await here because we want to request
 * to stop until the token is refreshed if it needs to be.
 */
export default defineNuxtRouteMiddleware(async () => {

    const app = useNuxtApp();
    const minValidity = useRuntimeConfig().public.keycloakMinValidity;
    const refreshed = await app.$keycloak.updateToken(minValidity);

    if (refreshed) {
        console.debug('IDP Token refreshed', Date.now());
    }

});