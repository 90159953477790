<template>

  <div>

    <!-- TODO: option for multiple output to yes/no? -->

    <template v-if="summary">
      <template v-if="! conditions.length">
        <v-icon color="red">
          mdi-alert
        </v-icon>
        No conditions.
      </template>
      
      <template v-else>
        <p v-if="conditions.length > 1">
          {{ conditionJoin.toUpperCase() }}:
        </p>
        <p
          v-for="condition in conditions"
          :key="condition.id"
        >
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="conditionText(condition)" />
        </p>
      </template>

    </template>

    <v-container v-else fluid>

      <v-alert
        v-if="!isEmpty(node.data.data.mappings)"
        type="info"
        class="mb-6"
      >
        Please note: {{ node.data.data.mappings.length }} variable(s) will only be mapped if the condition is true.
      </v-alert>

      <forms-builder-conditions 
        v-model="node.data.data"
        :allowed-sources="['workflow']"
      />

      <v-row class="mt-4">
        <v-col>
          <v-text-field
            v-model="node.data.data.true_text"
            label="True text"
            hint="Optional output field for true condition"
            persistent-hint
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="node.data.data.false_text"
            label="False text"
            hint="Optional output field for false condition"
            persistent-hint
          />
        </v-col>
      </v-row>

      <json-tree v-if="$portal.debug" :value="node.data.data" />

    </v-container>

  </div>

</template>

<script setup>

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: {} })
});

defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({})
  },
  summary: Boolean,
  mouseOver: Boolean,
});

const { matchStrategies, matchRequiresParameter } = useFormBuilder();

onMounted(() => node.value.data.data.conditions ||= []);

const conditions = computed(() => node.value.data.data.conditions || []);
const conditionJoin = computed(() => node.value.data.data.condition_join || 'and');

function conditionText(condition) {
  const title = matchStrategies.find(s => s.value === condition.match)?.title;
  const param = matchRequiresParameter(condition.match) ? ` '${condition.value}''` : '';
  return `<strong>${condition.field}</strong> ${title.toLowerCase()}${param}`;
}

</script>