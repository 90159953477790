/**
 * Use this instead of portal.get for fancy nuxt stuff.
 * 
 * TODO: could probably make this defer to portal.get/etc.
 * 
 * @param {*} request 
 * @param {*} options 
 * @param {*} extra 
 */
/* eslint-disable no-undef */
export const useApi = (request, options={}, extra={}) => {

  options ||= {}
  extra ||= {}

  const portal = usePortalStore();
  const config = useRuntimeConfig()
  const cache = useCacheStore();
  
  console.debug(`useApi(${toValue(request)})`, options, extra);

  return useFetch(request, {

    //the supplied options
    ...options,

    //always build our own cache/request key
    key: requestCacheKey(request, options),

    //our auth options
    baseURL: config.public.backendUrl,
    headers: usePortalHeaders(),
    
    //don't block nav
    lazy: true,

    //don't use ssr
    server: false,

    //provide an object while waiting, prevents undefined
    default: () => options.default || ({ __blank: true }),

    getCachedData(key) {
      if (extra.cacheTTL) {
        const cached = cache.get(key);
        if (! isEmpty(cached)) {
          console.debug(`useApi[${request}]: returning cached value`, cached);
          return cached;
        }
      }
    },

    onRequest({ request, options: _options }) {
      if (options.onRequest) {
        options.onRequest({ request, options: _options })
      }
      portal.narrative = extra.narrative ?? request
    },

    onResponse({ request, response, options: _options }) {

      console.log(`>>> useApi(${toValue(request)}) response`, response)

      if (extra.cacheTTL) {
        cache.set(_options.key, response._data, extra.cacheTTL);
      }

      if (options.onResponse) {
        options.onResponse({ request, response, options: _options })
      }

      portal.narrative = null;

    },

  });

}