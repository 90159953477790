/* eslint-disable no-undef */
import { defineStore } from 'pinia'
import { MarkerType, useVueFlow } from '@vue-flow/core';
import { uniqBy, omit } from 'lodash-es';
import { deepClone } from '#imports';
import ConnectingNodesToast from '~/components/ConnectingNodesToast.vue';

/**
 *
 */
export const useGraphStore = defineStore('graph', {

  state: () => ({
      actions: [],
      graph: [],
      currentNode: null,
      interactiveConnectionSourceId: null,
      interactiveConnectionToastId: null
  }),

  undo: {
    omit: ['actions', 'currentNode', 'interactiveConnectionSourceId', 'interactiveConnectionToastId'],
  },

  getters: {
    
    nodes: (state) => state.graph.filter(e => ! e.source && ! e.target),
    edges: (state) => state.graph.filter(e => e.source || e.target),
    nodeTypes: (state) => [...new Set(state.nodes.map(e => e.data?.attributes?.type || "custom").filter(e => e))],
    interactivelyConnecting: (state) => !! state.interactiveConnectionSourceId,
    
    /**
     * @return Global config object in the first node that has one.
     */
    globalConfig: (state) => {
      return state.nodeHoldingGlobalConfig?.data?.data?.config?.global || {};
    },

    nodeHoldingGlobalConfig: (state) => state.nodes.find(e => e.data?.data?.config?.global),

    /**
     * currentNode is set when editing a node,
     * and we don't want keyboard interactions to
     * affect the graph (ie if you use backspace)
     */
    locked: (state) => !! state.currentNode,
    unlocked: (state) => ! state.locked,
  },

  actions: {

    nextKeyNumber() {
      if (this.graph.length == 0) {
        return 1;
      }
      return 1 + this.graph
        .map(e => e.data?.key?.replace(/\D/g, ''))
        .filter(e => e)
        .reduce((a, b) => Math.max(a, b), -1)
    },

    keyExists(key) {
      console.log('keyExists', key, this.nodes.map(e => e.data.key))
      return this.nodes.some(e => e.data.key == key);
    },

    findNodeByKey(key) {
      return this.nodes.find(e => e.data.key == key);
    },

    previousNodes(node, includeCurrent = false) {
      if (! node.id) {
        node = this.find(node);
      }
      const result = [];
      if (includeCurrent) {
        result.push(node);
      }
      this.sources(node).forEach(e => {
        result.push(e);
        result.push(...this.previousNodes(e));
      });
      return result.reverse();
    },

    nodeFields(node, withGlobal = true, withProcess = true) {
      
      if (! node) {
        debugger
        console.error('Who called nodeFields with an empty node?');
      }

      if (! node?.id) {
        node = this.find(node);
      }
      
      const fields = node.data?.data?.fields?.map(e => {
        const value = e.group ? `${e.group}.${e.name}` : e.name;
        const source = e.key || node.data.key;
        const path = `${source}.${value}`
        return {
          ...e,
          path,
          field: value,
          source,
          props: {
            title: path,
            value: path,
            subtitle: `${e.label} (${e.type})`
          }
        }
      }) || [];

      if (withProcess) {
        fields.push({
          path: 'process.id',
          field: 'id',
          source: 'process',
          props: {
            title: 'process.id',
            value: 'process.id',
            subtitle: 'Process ID'
          }
        });
        fields.push({
          path: 'process.key',
          field: 'key',
          source: 'process',
          props: {
            title: 'process.id',
            value: 'process.id',
            subtitle: 'Workflow Key'
          }
        });
        fields.push({
          path: 'process.group',
          field: 'group',
          source: 'process',
          props: {
            title: 'process.group',
            value: 'process.group',
            subtitle: 'Workflow Group'
          }
        });
        fields.push({
          path: 'process.title',
          field: 'title',
          source: 'process',
          props: {
            title: 'process.title',
            value: 'process.title',
            subtitle: 'Workflow Title'
          }
        });
        fields.push({
          path: 'process.user',
          field: 'user',
          source: 'process',
          props: {
            title: 'process.user',
            value: 'process.user',
            subtitle: 'Workflow User'
          }
        })
      }

      if (withGlobal) {
        node.data?.data?.mappings?.filter(e => e.variable).forEach(e => {
          fields.push({
            path: `variables.${e.variable}`,
            field: e.variable,
            source: 'variables',
            props: {
              title: `variables.${e.variable}`,
              value: `variables.${e.variable}`,
              subtitle: e.variable
            }
          });
        });
      }

      return fields;

    },

    /**
     * Fields available from this node and back.
     */
    availableFields(node, fields=[]) {
      if (! node.id) {
        node = this.find(node);
      }
      
      fields.push(...this.nodeFields(node));
      this.previousNodes(node).forEach(e => {
        fields.push(...this.availableFields(e));
      });

      return uniqBy(fields, 'path');
      
    },

    nodeForField(field, checkCurrentNode = true) {
      const fieldName = field.split('.').shift();
      if (checkCurrentNode && this.currentNode?.data?.key == fieldName) {
        return this.currentNode;
      }
      return this.nodes.find(e => e.data.key == fieldName);
    },

    /**
     * Some fields may append their options for convenience.
     * @param {String} field 
     */
    optionsForField(field) {
      const node = this.nodeForField(field);
      if (node) {
        const fields = this.nodeFields(node, false, false);
        if (fields) {
          return fields.find(e => e.path == field)?.options || undefined;
        }
      }
      return undefined
    },

    fieldObject(fieldName) {
      const node = this.nodeForField(fieldName);
      if (! node) {
        return [];
      }
      return this.nodeFields(node, false, false).find(e => e.path == fieldName);
    },

    /**
     * Connect source node to target node.
     * 
     * Pass in optional edge to prefill edge properties.
     * 
     * @param {String|Node} sourceId 
     * @param {String|Node} targetId 
     * @param {Edge} edge (optional)
     * @returns 
     */
    connect(source, target, edge=undefined) {
      
      if (! source.id) source = this.find(source);
      if (! target.id) target = this.find(target);
      edge ||= {}

      if (target.data.attributes.type == 'initial') {
        return;
      }

      edge.id = `${source.id}:${target.id}`;
      if (this.edges.some(e => e.id == edge.id)) {
        useMessageStore().error(`Edge already exists! ${source.data.key} -> ${target.data.key}`);
        return;
      }

      edge.source = source.id;
      edge.target = target.id;
      edge.markerEnd = MarkerType.ArrowClosed;
      Object.assign(edge, source.data?.attributes?.edge || {});
      edge.data ||= {};
      
      const edgeAccept = [source.data?.attributes?.edge?.accept].flat().filter(e => e);
      if (edgeAccept.length > 0) {
        if ( ! edgeAccept.includes(target.data.component_key)) {
          useMessageStore().error('Target node must be of type ' + edgeAccept.join(' or '));
          return;
        }
      }

      const edgeType = source.data?.attributes?.edge_type;
      if (edgeType == 'conditional') {
        const targets = this.outgoingEdges(source.id);
        if (targets.length == 2) {
          useMessageStore().error('Condition can have only two outgoing edges (Yes/No)');
          return;
        } else if (targets.length == 1) {
          edge.label = 'No';
          edge.data.successful = false;
        } else {
          edge.label = 'Yes';
          edge.data.successful = true;
        }
      }

      this.add(edge);

    },

    isConnected(source, target) {
      if (source.id) source = source.id;
      if (target.id) target = target.id;
      return this.edges.some(e => e.source == source && e.target == target);
    },

    interactiveConnectBegin(sourceId) {
      if (sourceId.id) sourceId = sourceId.id;
      const { nodesDraggable } = useVueFlow();
      nodesDraggable.value = false;
      this.interactiveConnectionSourceId = sourceId;
      this.interactiveConnectionToastId = useMessageStore().info(ConnectingNodesToast, { 
        timeout: false,
        position: 'bottom-center',
        closeOnClick: false,
        closeButton: false
      })
    },

    interactiveConnectCancel() {
      const { nodesDraggable } = useVueFlow();
      nodesDraggable.value = true;
      this.interactiveConnectionSourceId = null;
      if (this.interactiveConnectionToastId) {
        useMessageStore().dismiss(this.interactiveConnectionToastId);
      }
    },

    interactiveConnectCommit(targetId) {
      if (this.interactivelyConnecting) {
        if (targetId.id) targetId = targetId.id;
        const source = this.interactiveConnectionSourceId;
        if (source && source != targetId) {
          this.connect(source, targetId);
        }
        this.interactiveConnectCancel();
      }
    },

    /**
     * Reload basic node properties from the actions store,
     * in case node config has changed on server since last load.
     * 
     * Not to be confused with refresh() in the action config,
     * which rebuilds the output fields.
     */
    reloadNode(node, actions = undefined) {
      actions ||= this.actions;
      const action = this.actions.find(e => e.key == node.data.component_key);
      if (action && action.key == node.data.component_key) {
        const copyProps = omit(action, ['key', 'title', 'data']);
        Object.assign(node.data, copyProps);
        console.debug('[graph] Refreshed node', node.data.key);
      }
    },

    reloadNodes(actions = undefined) {
      this.nodes.forEach(e => this.reloadNode(e, actions));
    },

    validateEdges() {

      const nodeIds = this.nodes.map(e => e.id);
      const orphans = this.edges.filter(edge => ! nodeIds.includes(edge.target) || ! nodeIds.includes(edge.source));
      for (const orphan of orphans) {
        console.warn('Deleting orphaned edge:', orphan.id);
        this.remove(orphan);
      }

      const edgeIds = this.edges.map(e => e.id);
      const seen = [];
      const duplicates = [];
      for (const id of edgeIds) {
        if (seen.includes(id)) {
          duplicates.push(id);
        }
        seen.push(id);
      }
      for (const duplicate of duplicates) {
        console.warn('Deleting duplicate edge:', duplicate);
        this.remove(duplicate);
      }

      console.debug('[graph] Validated edges, removed', orphans.length, 'orphans and', duplicates.length, 'duplicates.');

    },

    validateNode(node, returnMessage = false) {
      if (node.key == 'process' || node.key == 'variables') {
        const message = 'Node key cannot be "process" or "variables"'
        if (returnMessage) {
          return message;
        }
        useMessageStore().error(message);
        return false;
      }
      return true;
    },

    add(node) {
      if (this.validateNode(node)) {
        return this.graph.push(node);
      }
    },

    copy(node) {
      
      if (! node.id) {
        node = this.find(node);
      }

      const copied = deepClone(node);
      copied.data.key = `${node.data.component_key}_${this.nextKeyNumber()}`;
      copied.id = `node_${Date.now()}`;
      
      copied.position.x = this.rightBoundary() + copied.dimensions.width;

      this.add(copied);

    },

    rightBoundary() {
      return Math.max(...this.nodes.map(e => e.position.x));
    },

    remove(node) {
      return this._patch(node, 'remove');
    },

    update(node) {
      if (this.validateNode(node)) {
        return this._patch(node, 'update');
      }
    },

    sources(node) {
      return this._connections(node, 'target');
    },

    targets(node) {
      return this._connections(node, 'source');
    },

    incomingEdges(node, resolveNodes = false) {
      return this._connections(node, 'target', resolveNodes);
    },

    disconnectIncomingEdges(node) {
      this.incomingEdges(node).forEach(e => this.remove(e));
    },

    outgoingEdges(node, resolveNodes = false) {
      return this._connections(node, 'source', resolveNodes);
    },

    find(nodeId) {
      if (! nodeId) {
        debugger
        console.error('Who called find with an empty node?');
      }
      if (nodeId.id) {
        return nodeId;
      }
      return this.graph.find(e => e.id == nodeId);
    },

    findByKey(key) {
      return this.nodes.find(e => e.data.key == key);
    },

    _connections(nodeId, direction, resolveNodes=true) {

      if (nodeId.id) {
        nodeId = nodeId.id;
      }

      const inverse = direction == 'target' ? 'source' : 'target';
      //NOTE: we should not need uniqBy, but during dev sometimes duplicates get in there
      const edges = uniqBy(this.graph.filter(e => e[direction] == nodeId), 'id');

      if (resolveNodes) {
        return edges.map(e => e[inverse]).map(id => this.find(id));
      }

      return edges;
        
    },

    _patch(node, action) {
      if (! node.id) {
        if (action == 'update') {
          console.error('Must pass a full node object to patch update');
          return;
        }
        node = this.find(node);
      }
      const index = this.graph.findIndex(e => e.id == node.id);
      if (index != -1) {
        if (action == 'update') {
          Object.assign(this.graph[index], node);
        } else {
          this.graph.splice(index, 1);
        }
      }
      return node;
    }

  }

});

//this is for dev-tools...
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGraphStore, import.meta.hot));
}
