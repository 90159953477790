<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-checkbox
          v-model="config.whole_numbers"
          label="Limit to whole numbers"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

const config = defineModel({
  type: Object
})

//onkeypress=return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57
</script>
