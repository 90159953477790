<template>

  <div>

    <template v-if="summary">
      <v-container fluid>
        <v-row
          v-for="topic in node.data.data.topics"
          :key="topic"
        >
          <v-chip 
            size="x-small"
            variant="outlined"
            class="mb-1"
          >
            {{ topic }}
          </v-chip>
        </v-row>
        <v-row>
          <v-chip 
            prepend-icon="mdi-account"
            size="x-small"
            variant="outlined"
            class="mb-1"
          >
            <span v-if="node.data.data.user_resolution_strategy == 'owner'">Use Workflow Owner</span>
            <span v-else>Resolve payload[:{{ node.data.data.user_field }}]</span>
          </v-chip>
        </v-row>
        <v-row v-if="node.data.data.detect_models">
          <v-chip 
            prepend-icon="mdi-magnify"
            size="x-small"
            variant="outlined"
          >
            Detect Model <v-icon size="x-small">
              mdi-arrow-right
            </v-icon> :{{ node.data.data.model_field }} 
          </v-chip>
        </v-row>
      </v-container>
    </template>
      
    <template v-else>
      
      <v-container fluid>
        <v-row>
          <v-col>

            <select-list
              v-model="node.data.data.topics"
              min="1"
              heading="Topics"
              hide-requirements
              hint="eg: backend.model.form_submission.*.create"
            >
              <template #heading.text="{ text }">
                {{ text }}
              </template>
              <template #heading.right>
                <v-icon 
                  color="grey-darken-1"
                  @click="showingTopicHelp = ! showingTopicHelp"
                >
                  mdi-help-circle
                </v-icon>
              </template>
            </select-list>

            <v-alert
              v-if="showingTopicHelp"
              closable
              @click:close="showingTopicHelp = false"
            >
              <small>
                <p>
                  Use <b>*</b> to match any single segment, or <b>#</b> to match zero or more segments.
                  <br>
                  Try to be as specific as you can, or you might get too many messages (eg. <b>backend.#</b> is a bad idea!)
                </p>
                <br><b>backend.model.*.*.create</b> will match all create events for all models
                <br><b>backend.model.user.*.update</b> will match all user update events
                <br><b>backend.model.role.#</b> will match all events for the role model
                <br><br><p>See <a target="_blank" href="https://www.rabbitmq.com/tutorials/tutorial-five-ruby#topic-exchange">RabbitMQ Topics</a> for more information.</p>
              </small>
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels>
              <v-expansion-panel title="Sample Payload">
                <v-expansion-panel-text>
                  <JsonEditorVue
                    v-model="node.data.data.payload"
                    mode="text"
                    :main-menu-bar="false"
                    :stringified="false"
                  />
                  <v-alert>
                    <small>
                      Paste a sample JSON payload here so we can map the payload fields to workflow fields, 
                      eg <b>{{ node.data.key }}.payload.some_field</b>.
                      You don't have to, you can always use <b>{{ node.data.key }}.payload#some_field</b> to access the message fields.
                    </small>
                  </v-alert>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox
              v-model="node.data.data.detect_models"
              label="Automatically resolve models"
              hint="Detect model references (such as global_id) in the payload and resolve the model"
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="node.data.data.detect_models">
            <v-text-field
              v-model="node.data.data.model_field"
              label="Model field"
              hint="Model will be passed to workflow with this key"
              persistent-hint
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="node.data.data.user_resolution_strategy"
              :items="resolutionStrategies"
              label="Workflow user"
            />
          </v-col>

          <v-col v-if="node.data.data.user_resolution_strategy == 'payload'">
            <v-text-field
              v-model="node.data.data.user_field"
              label="Payload user field"
              persistent-hint
              hint="Payload field containing user id, global id, uuid, username, or email"
              :rules="[rules.required]"
            />
          </v-col>
          
        </v-row>

        <v-row>
          <v-col>
            Message Fields
            <v-chip-group column>
              <v-chip
                v-for="field in fields"
                :key="field"
                size="small"
                class="mr-1"
              >
                <span v-if="fullPath">{{ node.data.key }}.{{ field.group }}.</span>
                {{ field.name }}
              </v-chip>
            </v-chip-group>
            
            <v-switch
              v-model="fullPath"
              label="Show full path"
              hide-details
              density="compact"
              style="margin-bottom: -26px;"
            />
          </v-col>
        </v-row>

      </v-container>
    </template>

  </div>

</template>

<script setup>

import { flatten } from 'flat'

const node = defineModel({
  type: Object,
  required: false,
  default: () => ({ data: {} })
});

const props = defineProps({
  summary: Boolean,
  mouseOver: Boolean,
});

onMounted(() => {
  node.value.data ||= {};
  node.value.data.data ||= {};
  node.value.data.data.user_field ||= 'user';
  node.value.data.data.user_resolution_strategy ||= 'owner';
  if (! Array.isArray(node.value.data.data.topics) || node.value.data.data.topics.length < 1) {
    node.value.data.data.topics = [''];
  }
  refresh()
});

const rules = useFormRules();

const resolutionStrategies = [
  { value: 'owner', title: 'Use definition owner' },
  { value: 'payload', title: 'Resolve a payload field' },
]

const showingTopicHelp = ref(false);
const fullPath = ref(false);

const fields = computed(() => {
  
  const result = Object.keys(flatten(node.value.data.data.payload || {}))
    .map(e => ({
      name: e,
      label: e,
      group: 'payload',
      type: 'String'
    }))

  if (node.value.data.data.detect_models) {
    result.push({
      name: node.value.data.data.model_field,
      label: 'Resolved model',
      group: 'payload',
      type: node.value.data.data.model_class || 'ApplicationRecord',
    });
  }

  return result;
});

function refresh() {
  if (props.summary) return;
  node.value.data.data.fields = deepClone(fields.value);
}

defineExpose({ refresh })

</script>