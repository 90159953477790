/* eslint-disable no-undef */
import { defineStore } from 'pinia'

export const usePageStore = defineStore('page', {

  state: () => ({
    dirty: false,
    target: null,
    confirmingCancel: false,
  }),

});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePageStore, import.meta.hot));
}