<template>

  <list-item-select
    v-if="field.options.type === 'list_item'"
    :list-name="field.options.listItem"
    :key-by="field.options.config.titleAsKey ? 'title' : 'key'"
    :placeholder="$attrs.placeholder || 'Please select an option - click or start typing to search'"
    v-bind="$attrs"
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </list-item-select>

  <v-select 
    v-else
    auto-select-first
  >
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </v-select>
</template>

<script>
export default {
  config: {
    dataSource: {
      cast: 'string',
      required: true,
      allowMultiselect: true,
      clearable: true,
      managesItems: true
    }
  },

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({})
    },
  }
}
</script>