/* eslint-disable no-undef */

import { defineNuxtPlugin } from '#app'
export default defineNuxtPlugin((nuxtApp) => {

  /**
   * We need component lists in some places, only import them once.
   */
  nuxtApp.provide('imports', {
    //note that vite needs string literals, so we can't do this in a config
    configs: import.meta.glob('~/components/forms/builder/config/*.vue', { eager: true }),
    fields: import.meta.glob('~/components/forms/fields/*/**/*.vue', { eager: true }),
    layouts: import.meta.glob('~/components/forms/layouts/*.vue', { eager: true }),
    actions: import.meta.glob('~/components/workflow/action/*.vue', { eager: true }),
    nodes: import.meta.glob('~/components/workflow/node/*.vue', { eager: true }),
    tasks: import.meta.glob('~/components/task/display/*.vue', { eager: true }),
  });

})