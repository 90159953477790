<template>
  <div>
    <v-checkbox
      v-model="localValue.config.only_supervised"
      label="Limit to supervised students"
    />
    <v-checkbox
      v-if="localValue.config.only_supervised"
      v-model="localValue.config.only_primary"
      label="Only where user is primary supervisor"
    />
  </div>
</template>

<script setup>
const localValue = defineModel({
  type: Object
})
</script>