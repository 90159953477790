<template>

  <!-- TODO: option to change display (eg show in box or merge in)-->
  
  <div>
    <v-progress-circular
      v-if="pending"
      indeterminate
    />
  
    <div v-else-if="$attrs.previewing">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            <v-icon>{{ subform.icon }}</v-icon>
            <b>{{ subform.title }}</b>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <forms-layouts-minimal-form
              v-model="model"
              :form="subform"
              read-only
            />
            <!-- <v-list>
              <v-list-item
                v-for="column in subform?.definition?.rows?.flatMap(e => e.columns)"
                :key="column.id"
                :title="column.label"
                :subtitle="`[${column.name}] ${column.component}`"
              />
            </v-list> -->
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-card 
      v-else-if="subform"
      variant="outlined"
    >
      <json-tree v-if="$portal.debug" :value="model" />

      <forms-layouts-minimal-form
        v-model="model"
        :form="subform"
        v-bind="$attrs"
      />
    </v-card>
  </div>
  
</template>

<script>

export default {
  config: {
    rules: false,
    attributes: false,
  }
}
</script>

<script setup>

const portal = usePortalStore();

const parentModel = defineModel({
  type: Object,
  required: true,
  default: () => ({})
})

const props = defineProps({
  field: {
    type: Object,
    required: true
  },
  form: {
    type: Object,
    required: true,
    default: () => ({})
  },
});

onBeforeMount(async () => {
  const formKey = props.field.options.subform;
  const loaded = props.form.subforms?.[formKey];
  if (loaded?.id) {
    subform.value = loaded;
  } else {
    try {
      pending.value = true;
      const response = await portal.get(`/forms/${formKey}`);
      subform.value = response.form;
    } finally {
      pending.value = false;
    }
  }
});

const pending = ref(false);
const subform = ref({});

//NOTE: binding directly to the model seems to not work
const model = ref(parentModel.value);
watch(
  () => ({...model.value}), 
  () => nextTick(() => parentModel.value = model.value)
);

defineExpose({ 
  config: {
    dataSource: {
      cast: 'Hash',
    }
  } 
});

</script>