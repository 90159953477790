/**
 * @see https://github.com/rohmanhm/force-boolean
 * 
 * @param {*} value 
 * @param {Array<String>} matches Default: ['true', '1', 'yes', 'on', 't', 'y']
 * @returns 
 */
export default function(value, matches = ['true', '1', 'yes', 'on', 't', 'y']) {
  switch (typeof value) {
    case "boolean":
      return value.valueOf();
    case "number":
      return value > 0;
    case "object":
      return !(value === null);
    case "string":
      return matches.includes(value.toLowerCase());
    case "undefined":
    default:
      return false;
  }
}