<template>
  <v-container fluid class="pa-1 ma-1">
    <v-row
      v-for="(row, r) in filteredRows"
      :key="`row-${r}`"
      dense
      no-gutters
    >
      <v-col
        v-for="(column, c) in row.columns"
        :key="`col-${c}`"
        :cols="$vuetify.display.mobile ? 12 : (column.cols || null)"
      >
        <forms-fields-form-field
          v-model="model[column.name]"
          v-bind="bindableAttributes[column.name]"
          :plain="computedPlain"
          :field="column"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>

const model = defineModel({
  type: Object,
  required: true,
})

const props = defineProps({
  form: {
    type: Object,
    required: true,
    default: () => {}
  },
  format: {
    type: String,
    required: false,
    default: undefined
  },
  readOnly: Boolean
});

const formRef = toRef(props, 'form');

const { filteredRows, bindableAttributes } = useFormBuilder(
  formRef, 
  model, 
  { readOnly: props.readOnly }
);

const computedPlain = computed(() => props.format == 'plain' || props.form.definition.format == 'plain');

</script>