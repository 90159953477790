<template>
  <list-item-select
    list-name="user"
    :placeholder="$attrs.placeholder || 'Please select an option - click or start typing to search'"
  />
</template>

<script>

/**
 * @deprecated
 */
export default {

  config: {
    defaults: {
      options: {
        type: 'list_item',
        listItem: 'user'
      }
    },
    dataSource: {
      allowMultiselect: true,
      cast: 'string'
    }
  },

}
</script>