<template>
  <v-row dense>

    <v-col cols="2">
      <v-select
        v-model="condition.source"
        variant="underlined"
        single-line
        density="compact"
        label="Source"
        :items="sources"
        :rules="internalRules"
      />
    </v-col>

    <v-col>
      <v-autocomplete
        v-if="condition.source == 'form'"
        v-model="condition.field"
        autofocus
        variant="underlined"
        single-line
        density="compact"
        label="Field"
        :items="formFields"
        :rules="internalRules"
      />
      
      <workflow-field-select 
        v-else-if="condition.source == 'workflow'"
        v-model="condition.field"
        autofocus
        variant="underlined"
        single-line
        density="compact"
        label="Workflow field"
        hint=""
        :persistent-hint="false"
        :rules="internalRules"
      />

      <v-autocomplete
        v-else-if="condition.source == 'role'"
        v-model="condition.field"
        :items="$portal.roles"
        single-line
        item-title="name"
        density="compact"
        variant="underlined"
        label="Role name"
      />

      <v-combobox
        v-else
        v-model="condition.field"
        autofocus
        variant="underlined"
        single-line
        density="compact"
        label="Field"
        :items="userFields"
        :rules="internalRules"
        hint="Choose a user field, or type any meta field name."
        persistent-hint
      />

    </v-col>

    <v-col cols="2">
      <v-select
        v-model="condition.match"
        variant="underlined"
        single-line
        density="compact"
        label="Match"
        :disabled="! condition.field"
        :items="availableMatchStrategies"
        :rules="internalRules"
      />
    </v-col>

    <v-col>

      <v-autocomplete
        v-if="hasMatchChoices"
        v-model="condition.value"
        variant="underlined"
        :disabled="! requiresValue || ! condition.match"
        :loading="matchesLoading"
        :items="matchChoices"
        single-line
        density="compact"
        label="Value"
        :rules="internalRules"
      />

      <list-item-select
        v-else-if="listItemName"
        v-model="condition.value"
        :list-name="listItemName"
        :disabled="! requiresValue || ! condition.match"
        variant="underlined"
        single-line
        density="compact"
        label="Value"
        :rules="internalRules"
      /> 

      <v-text-field
        v-else
        v-model="condition.value"
        variant="underlined"
        :disabled="! requiresValue || ! condition.match"
        single-line
        density="compact"
        label="Value"
        :rules="requiresValue ? internalRules : []"
      >
        <template #append-inner>
          <workflow-field-icon 
            v-model="condition.value" 
            size="small" 
          />
        </template>
      </v-text-field>

    </v-col>
  </v-row>
</template>

<script setup>

import { computedAsync } from '@vueuse/core';
import { keys } from 'lodash-es';

const graphStore = useGraphStore();
const portal = usePortalStore();

const condition = defineModel({
  type: Object,
  required: true,
  default: () => ({})
})

const props = defineProps({
  field: {
    type: Object,
    required: false,
    default: () => ({})
  },
  form: {
    type: Object,
    required: false,
    default: () => ({})
  },
  allowedSources: {
    type: Array,
    default: undefined
  }
});

defineEmits(['remove']);

onBeforeMount(() => {
  condition.value.source ||= sources.value[0].value
});

const { matchStrategies, matchRequiresParameter } = useFormBuilder();

const matchChoices = ref([]);
const matchesLoading = ref(false);
const listItemName = ref(null);
const remembered = ref({});

const hasMatchChoices = computed(() => Array.isArray(matchChoices.value) && matchChoices.value.length > 0);
const requiresValue = computed(() => matchRequiresParameter(condition.value));

const userFields = computed(() => {
  return keys(portal.user);
});

const internalRules = [
  v => !!v || 'Value required'
];

const computedAllowed = computed(() => {
  const allowed = [];
  if (props.allowedSources === undefined) {
    allowed.push('user');
    allowed.push('role');
    if (graphStore.currentNode) {
      allowed.push('workflow');
    }
    if (props.form?.definition) {
      allowed.push('form');
    }
  } else {
    allowed.push(...props.allowedSources);
  }
  return allowed;
});

const sources = computed(() => {
  return [
    { title: 'Form', value: 'form' },
    { title: 'Workflow', value: 'workflow' },
    { title: 'User', value: 'user' },
    { title: 'User role', value: 'role' },
  ].filter(e => computedAllowed.value.includes(e.value));
});

const availableMatchStrategies = computedAsync(
  async () => {
  
    const source = condition.value.source;
    const field = condition.value.field;

    //FIXME: not getting proper date strategies

    if (field) {
      if (source == 'role') {
        return useFormBuilder().matchesForCast('boolean');
      } else if (source == 'form') {
        const { allowedMatchStrategies } = useFormBuilder(props.form);
        return allowedMatchStrategies(field);
      } else if (source == 'workflow') {
        const form = await resolveForm(field);
        if (form) {
          const { allowedMatchStrategies } = useFormBuilder(form);
          return allowedMatchStrategies(field.split('.').pop());
        }
      }
    }
    return matchStrategies;
  }, 
  matchStrategies
);

watch(
  () => condition.value.field, 
  async (newVal, oldVal) => {

    if (oldVal) {
      remembered.value[oldVal] = condition.value.value;
      condition.value.value = remembered.value[newVal];
    }

    matchChoices.value = [];
    listItemName.value = null;

    const field = condition.value.field;
    if (field) {

      matchesLoading.value = true;

      try {

        const fieldObject = graphStore.fieldObject(field);

        if (fieldObject?.options) {

          //the field has the options added - handy!
          matchChoices.value = fieldObject?.options;

        } else if (fieldObject?.lookup) {

          //a special lookup
          if (fieldObject.lookup == 'roles') {
            matchChoices.value = portal.roles.map(e => ({
              id: e.name,
              title: e.name
            }));
          } else {
            console.error('Unknown lookup', fieldObject.lookup);
          }

        } else {

          //form might be local, might be buried in another node or a subform
          const form = await resolveForm(field);
          if (form) {
            const formField = field.split('.').pop();
            const { fieldHasStaticValues, fieldStaticValues, fieldIsListItem, fieldListItemName } = useFormBuilder(form);
            if (fieldIsListItem(formField)) {
              listItemName.value = fieldListItemName(formField);
            } else if (fieldHasStaticValues(formField)) {
              matchChoices.value = fieldStaticValues(formField);
            }
          }

        }
        
      } catch (e) {
        console.error('Error loading match choices', e);
      } finally {
        matchesLoading.value = false;
      }
      
    }

  }, 
  { immediate: true }
);

async function resolveForm(field) {

  if (condition.value.source == 'form') {
    return props.form;
  } else if (condition.value.source == 'user') {
    return undefined
  }

  //find node containing the form
  const sourceNode = graphStore.nodeForField(field);
  if (! sourceNode) {
    console.error('Could not find node for field', field);
    return;
  }

  let form = sourceNode.data.data?.form;

  //if it's referring to a real form, load it 
  if (! form && sourceNode.data.data?.form_key) {
    console.debug('loading form', sourceNode.data.data.form_key)
    const result = await portal.get(`/forms/${sourceNode.data.data.form_key}`, {}, { cacheTTL: 300 });
    form = result.form;
  }
  
  if (form?.definition) {
    return form;
  }

  return undefined;

}

const formFields = computed(() => {
  const { fieldList } = useFormBuilder(props.form);
  return fieldList.value.filter(e => e.value != props.field.name)
});

</script>