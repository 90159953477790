<template>

  <v-btn
    v-if="confirming"
    ref="warningButton"
    :color="confirmColor"
    :variant="confirmVariant"
    v-bind="confirmAttrs"
    :disabled="! armed"
    @click="perform"
  >
    <v-progress-circular
      :model-value="percent"
      size="15"
      width="2"
    />
    {{ confirmText }}
  </v-btn>

  <v-btn
    v-else
    v-bind="buttonAttrs"
    @click="confirm"
  >
    <slot name="default" />
  </v-btn>

</template>

<script setup>

import { onClickOutside } from '@vueuse/core'

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  confirmText: {
    type: String,
    required: false,
    default: "Really?"
  },
  confirmColor: {
    type: String,
    required: false,
    default: "red"
  },
  confirmVariant: {
    type: String,
    required: false,
    default: "elevated"
  },
  armedDelay: {
    type: [Number, String],
    required: false,
    default: 500
  },
  confirmTimeout: {
    type: [Number, String],
    required: false,
    default: 3
  }
})

const confirming = ref(false);
const started = ref(null);
const percent = ref(100);
const seconds = ref(props.confirmTimeout);
const warningButton = ref({});
const armed = ref(false);

const prefix = 'confirm-'
const attrs = useAttrs();
const click = attrs.onClick;
const buttonAttrs = computed(() => Object.fromEntries(Object.entries(attrs).filter(e => e[0] != 'onClick' && ! e[0].startsWith(prefix))));

onClickOutside(warningButton, () => {
  if (armed.value) {
    confirming.value = false;
  }
});

const confirmAttrs = ref({});
for (const [key,val] of Object.entries(attrs)) {
  if (key.startsWith(prefix)) {
    confirmAttrs.value[key.substring(prefix.length)] = val;
  }
}

if (! confirmAttrs.value.size) {
  confirmAttrs.value.size = buttonAttrs.value.size
}

let interval1 = null;
let interval2 = null;
function confirm() {
  
  armed.value = false;
  confirming.value = true;

  setTimeout(() => armed.value = true, props.armedDelay);

  started.value = Date.now();
  interval1 = setInterval(() => {
    if (maybeStopTimers()) {
      return;
    }
    const diff = Date.now() - started.value;
    const total = props.confirmTimeout * 1000;
    const value = 100 - (Math.round((diff / total) * 100));
    percent.value = value;
  }, 100)
  
  interval2 = setInterval(
    () => {
      if (maybeStopTimers()) {
        return;
      }
      if (seconds.value > 1) {
        seconds.value--;
      } else {
        confirming.value = false;
        seconds.value = props.confirmTimeout;
        percent.value = 0;
      }
    }, 
    1000
  );

}

function maybeStopTimers() {
  if (! confirming.value) {
    clearInterval(interval1);
    clearInterval(interval2);
    percent.value = 100;
    return true;
  }
  return false;
}

function perform(...args) {
  confirming.value = false;
  click(...args);
}

</script>