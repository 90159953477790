// eslint-disable-next-line no-undef
export default defineNuxtPlugin((nuxtApp) => {

  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.error('global errorHandler', error, instance, info);
  }

  // Also possible
  // nuxtApp.hook('vue:error', (error, instance, info) => {
  //   console.error('global vue:error', error, instance, info);
  // })
  
})