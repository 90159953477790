<template>
  <assign-task
    ref="taskForm"
    v-model="node"
    :actions="filteredActions"
    :summary="summary"
    v-bind="$attrs"
    hide-action-location
  >
    <template #actions.after>
      <div class="mt-4">
        <v-checkbox
          v-model="node.data.data.allow_query"
          label="Allow Query"
          hint="Should approvers be able to ask for a resubmission?"
          persistent-hint
          density="compact"
        />

        <v-alert v-if="node.data.data.allow_query" type="warning">
          NOTE: resubmitted forms will come back to this step for re-approval.
          This can result in changes being made that previous approvers are
          unaware of. If the submission requires major changes, please consider
          rejecting the submission and requesting a fresh attempt.
        </v-alert>
        
      </div>
    </template>
  </assign-task>
</template>

<script setup>

import AssignTask from './AssignTask.vue';

const node = defineModel({
  type: Object,
  required: true,
});

const props = defineProps({
  summary: Boolean
});

const taskForm = ref({});

onMounted(() => {
  node.value.data.data ||= {
    allow_query: true,
    component: "WorkflowApprovalTask"
  }
});

const filteredActions = computed(() => {
  return node.value.data.attributes.actions.filter(e => node.value.data.data.allow_query ? e : e.key != 'queried')
});

function refresh() {
  if (! props.summary) {
    taskForm.value.refresh();
  }
}

defineExpose({ refresh })

</script>