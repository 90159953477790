<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-radio-group
          v-model="checkboxConfig.type"
          inline
          :rules="[rules.required]"
        >
          <v-radio label="Boolean (true/false)" value="boolean" />
          <v-radio label="Custom Values" value="custom" />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <p>{{ description }}</p>
      </v-col>
    </v-row>

    <template v-if="isCustom">

      <v-alert type="warning">
        Warning: conditions may not work as expected with custom values
      </v-alert>

      <v-row dense>
        <v-col>
          <v-text-field
            v-model="checkboxConfig.trueValue"
            label="True value"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="checkboxConfig.falseValue"
            label="False value"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

    </template>

  </v-container>
</template>

<script setup>

const checkboxConfig = defineModel({
  type: Object
});

onMounted(() => {
  checkboxConfig.value.type ||= "boolean";
  checkboxConfig.value.trueValue ||= isCustom.value ? "Yes" : true;
  checkboxConfig.value.falseValue ||= isCustom.value ? "No" : false;
});

const rules = {
  required: v => !!v || 'This field is required'
}

const isCustom = computed(() => checkboxConfig.value.type == 'custom');
const description = computed(() => {
  switch (checkboxConfig.value.type) {
    case "boolean":
      return "Checkbox will return boolean true/false values.";
    case "custom":
      return "Checkbox will return the following values:";
    default:
      return "Please specify the configuration for this checkbox.";
  }
});
</script>