<template>

  <component 
    :is="component"
    v-if="plain"
    v-model="model"
    :label="field.label"
    :field="field" 
    :readonly="readonly"
    v-bind="$attrs"
  />

  <v-container
    v-else
    fluid
    class="divinity-form-field-wrapper"
  >

    <v-row
      v-if="field.label && ! hideLabel && ! inlineLabel"
      dense
      no-gutters
    >
      <v-col>
        <FieldTitle 
          :for="`field-${field.id}`"
          :content="field.label" 
          @click="$emit('click:title')"
          @mouseover="$emit('mouseover:title')"
          @mouseout="$emit('mouseout:title')"
        >
          <template v-for="name in Object.keys($slots).filter(e => e != 'default')" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}" />
          </template>
        </FieldTitle>
      </v-col>
    </v-row>

    <v-row
      v-if="! instructionsBelow"
      dense
      no-gutters
    >
      <v-col style="margin-bottom: 12px;">
        <FieldInstructions 
          :content="field.instructions" 
          @click:instructions="$emit('click:instructions')"
          @mouseover:instructions="$emit('mouseover:instructions')"
          @mouseout:instructions="$emit('mouseout:instructions')"
        >
          <template v-for="name in Object.keys($slots).filter(e => e != 'default')" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}" />
          </template>
        </FieldInstructions>
      </v-col>
    </v-row>

    <v-row dense no-gutters>
      <v-col>
        <component 
          :is="component"
          :id="`field-${field.id}`"
          v-model="model"
          :label="inlineLabel ? field.label : undefined"
          :field="field" 
          :readonly="readonly"
          v-bind="$attrs"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="instructionsBelow"
      dense
      no-gutters
    >
      <v-col style="margin-top: -24px; margin-left: -2px;">
        <FieldInstructions 
          :content="field.instructions" 
          @click:instructions="$emit('click:instructions')"
          @mouseover:instructions="$emit('mouseover:instructions')"
          @mouseout:instructions="$emit('mouseout:instructions')"
        >
          <template v-for="name in Object.keys($slots).filter(e => e != 'default')" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}" />
          </template>
        </FieldInstructions>
      </v-col>
    </v-row>

    <Debug title="model" raw>
      {{ model }}
    </Debug>

    <json-tree v-if="$portal.debug" :value="field" />

  </v-container>

</template>

<script setup lang="ts">

import FieldTitle from './FieldTitle.vue';
import FieldInstructions from './FieldInstructions.vue';

const model = defineModel<any>();

const props = defineProps({
  field: {
    type: Object,
    required: true
  },
  plain: Boolean,
  readonly: Boolean
});

const componentStore = useComponentStore();

defineEmits([
  'click:title',
  'mouseover:title',
  'mouseout:title',
  
  'click:instructions', 
  'mouseover:instructions', 
  'mouseout:instructions', 
]);

const component = computed(() => componentStore.resolveField(props.field.component));
const hideLabel = computed(() => component.value.config?.label === false);
const inlineLabel = computed(() => component.value.config?.inlineLabel);
const instructionsBelow = computed(() => component.value.config?.instructionsBelow);
</script>