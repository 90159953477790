/* eslint-disable no-undef */
import Keycloak from "keycloak-js";

/**
 * Connect to keycloak and do initial SSO check.
 */
export default defineNuxtPlugin(async (app) => {
  if (!app.$keycloak) {
    console.debug("Starting Keycloak...");

    //create the object
    const runtimeConfig = useRuntimeConfig();
    const keycloak = new Keycloak({
      url: runtimeConfig.public.keycloakUrl,
      realm: runtimeConfig.public.keycloakRealm,
      clientId: runtimeConfig.public.keycloakClient,
    });

    //call init - this may boot user to login
    const authenticated = await keycloak.init({
      onLoad: "login-required",
      enableLogging: runtimeConfig.public.keycloakLogging,
      checkLoginIframe: false,
    });

    //something has gone wrong - try again
    if (!authenticated) {
      console.warn("not authenticated, reloading window");
      window.location.reload();
    }

    //force-update token if it expires and not caught by middleware
    keycloak.onTokenExpired = () => keycloak.updateToken(-1);

    //make available to the whole app
    app.provide("keycloak", keycloak);
  }
});
