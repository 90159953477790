<template>
  <v-switch
    v-model="config.inline"
    label="Inline (single row)"
    class="ml-4"
  />
</template>

<script setup>
const config = defineModel({
  type: Object
})
</script>