<template>

  <v-table class="info-table">
    <tbody>
      <slot name="before" />
      <tr>
        <th :style="`width: ${labelWidth}`">
          Form
        </th>
        <td>
          <v-icon>{{ form.icon }}</v-icon>
          {{ form.title }} 
          <v-chip tile size="small">Workflow #{{ workflow.id }}</v-chip>
        </td>
      </tr>
      <tr>
        <th>Submitted By</th>
        <td>
          {{ user.full_name }} (<a :href="emailLink" target="_blank">{{ user.email }}</a>) 
          at {{ formatDate(submission.created_at) }}
        </td>
      </tr>
      <!-- <tr>
        <th>Workflow</th>
        <td>
          #{{ workflow.id }}: {{ workflow.title }}
        </td>
      </tr> -->
      <tr v-if="task.last_response">
        <th>Your Response</th>
        <td>
          You chose <b>{{ task.last_response.action }}</b> at 
          {{ formatDate(task.last_response.created_at) }}
        </td>
      </tr>
      <slot name="after" />
    </tbody>
  </v-table>
</template>

<script setup>
 
 const props = defineProps({
  task: {
    type: Object,
    required: true,
    default: () => ({})
  },
  labelWidth: {
    type: String,
    required: false,
    default: "20%"
  }
});

const approval = computed(() => props.task.data.approval);
const form = computed(() => approval.value.form_submission.form);
const user = computed(() => approval.value.submitted_by);
const workflow = computed(() => approval.value.process);
const emailLink = computed(() => `mailto:${user.value.email}?subject=${form.value.title} #${workflow.value.id}`);
const submission = computed(() => approval.value.form_submission);
</script>

<style scoped>
  th {
    width: 15%;
    background-color: #f5f5f5;
    font-weight: bold !important;
  }
</style>