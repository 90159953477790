<template>
  <div>

    <v-row v-if="hasHeading">
      <slot name="heading.before" />
      <slot name="heading">
        <v-col>
          <div class="d-flex space-around">
            <div class="me-auto">
              <slot name="heading.text" :text="heading">
                <b>{{ heading }}</b>
              </slot>
              <v-icon v-if="canAdd" @click="add">
                mdi-plus
              </v-icon>
            </div>
            <slot name="heading.right" />
          </div>
        </v-col>
      </slot>
      <slot name="heading.after" />
    </v-row>

    <slot name="instructions" />

    <v-input
      v-if="showRequirements"
      :label="label"
      :error="error"
      :class="{ 'text-red': error }"
    >
      {{ requirements }}
    </v-input>

    <v-row
      v-for="(code, index) in list"
      :key="index"
      dense
      no-gutters
    >

      <slot
        name="before"
        :list
        :code="list[index]"
        :index
      />
      <v-col>

        <div class="d-flex">

          <slot
            name="item"
            :list
            :index
            :rules
          >

            <list-item-select
              v-if="listName"
              v-model="list[index]"
              :list-name="listName"
              :label="label"
              :rules="[rules.required, rules.unique]"
            /> 

            <v-autocomplete
              v-else-if="items && items.length"
              v-model="list[index]"
              :items="items"
              :label="label"
              :rules="[rules.required, rules.unique]"
              item-title="title"
              item-value="key"
            />

            <v-text-field
              v-else
              v-model="list[index]"
              :label="label"
              :rules="[rules.required, rules.unique]"
              v-bind="$attrs"
            >
              <template v-if="graphStore.currentNode" #append-inner>
                <workflow-field-icon v-model="list[index]" />
              </template>
            </v-text-field>

          </slot>

          <slot
            name="after"
            :list
            :code
            :index
          />

          <v-icon
            v-if="canRemove"
            style="margin-top: 12px"
            @click="remove(index)"
          >
            mdi-close
          </v-icon>

        </div>
        
      </v-col>

    </v-row>

    <slot name="rows.after" />

    <v-row v-if="!hasHeading" dense>
      <v-icon v-if="canAdd" @click="add">
        mdi-plus
      </v-icon>
    </v-row>

    <debug title="list">
      {{ list }}
    </debug>
    <div v-if="$portal.debug">
      <pre>
        MIN: {{ min }}
        realMin: {{ realMin }}
        MAX: {{ max }}
        MINOK: {{ minOk }}
        MAXOK: {{ maxOk }}
      </pre>
    </div>

  </div>
</template>

<script setup>

const portal = usePortalStore();
const graphStore = useGraphStore();

const list = defineModel({
  type: Array,
  required: true,
  default: () => ([])
})

const props = defineProps({
  items: {
    type: Array,
    required: false,
    default: () => ([])
  },
  listName: {
    type: String,
    required: false,
    default: undefined
  },
  required: {
    type: Boolean,
    required: false,
    default: true
  },
  heading: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: undefined
  },
  min: {
    type: [Number, String],
    required: false,
    default: 0
  },
  max: {
    type: [Number, String],
    required: false,
    default: 0
  },
  persistentRequirements: {
    type: Boolean,
    required: false,
    default: true
  },
  // eslint-disable-next-line vue/require-prop-types
  defaultValue: {
    required: false,
    default: null
  },
  hideRequirements: Boolean
});

const error = ref(false);

const emit = defineEmits(['added', 'removed']);

const rules = {
  required: v => !!v || 'This field is required',
  unique: v => isUnique(v) || 'That value is already chosen'
}

const hasHeading = computed(() => !!props.heading);
const realMax = computed(() => props.max ? parseInt(props.max) : 0);
const realMin = computed(() => props.min ? parseInt(props.min) : (props.required ? 1 : 0));
const count = computed(() => list.value?.length || 0);
const minOk = computed(() => count.value >= realMin.value);
const maxOk = computed(() => !realMax.value || count.value <= realMax.value);
const canAdd = computed(() => portal.debug || (!realMax.value || count.value < realMax.value));
const canRemove = computed(() => portal.debug || (count.value > realMin.value));

const requirements = computed(() => {
  if (realMin.value && realMax.value) {
    return `Please enter between ${realMin.value} and ${realMax.value} items.`;
  }
  if (!realMin.value && realMax.value) {
    const s = realMax.value == 1 ? '' : 's';
    return `Please enter no more than ${realMax.value} item${s}.`;
  }
  if (realMin.value && !realMax.value) {
    const s = realMin.value == 1 ? '' : 's';
    return `Please enter at least ${realMin.value} item${s}.`;
  }
  return null;
});

watch(list, () => {
  console.log('list', list.value);
})

function validate() {
  if (realMin.value && realMax.value) {
    error.value = !(minOk.value && maxOk.value);
  }
  if (!realMin.value && realMax.value) {
    error.value = !maxOk.value;
  }
  if (realMin.value && !realMax.value) {
    error.value = !minOk.value;
  }
}

const showRequirements = computed(() => {
  return ! props.hideRequirements && (props.persistentRequirements || !!error.value);
});

onMounted(() => {
  if (realMin.value > 0) {
    for (let i = 0; i < (realMin.value - count.value); i++) {
      add();
    }
  }
})

function isUnique(candidate) {
  return list.value?.filter(e => e == candidate).length < 2;
}

function add() {
  list.value.push(deepClone(props.defaultValue));
  emit('added', list.value.length - 1);
}

function remove(index) {
  list.value.splice(index, 1)
  emit('removed', index);
}

defineExpose({ error, validate })

</script>