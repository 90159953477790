import validate from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45token_45refresh_45global from "/opt/render/project/src/middleware/00.tokenRefresh.global.js";
import _01_45portal_45init_45global from "/opt/render/project/src/middleware/01.portalInit.global.js";
import _03_45clear_45toast_45global from "/opt/render/project/src/middleware/03.clearToast.global.js";
import _04_45check_45page_45dirty_45global from "/opt/render/project/src/middleware/04.checkPageDirty.global.js";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45token_45refresh_45global,
  _01_45portal_45init_45global,
  _03_45clear_45toast_45global,
  _04_45check_45page_45dirty_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "99-reset-stores": () => import("/opt/render/project/src/middleware/99.resetStores.js")
}