<template>
  <list-item-select
    list-name="staffplus_contact"
    :placeholder="$attrs.placeholder || 'Please select an option - click or start typing to search'"
  />
</template>

<script>

export default {

  config: {
    defaults: {
      options: {
        type: 'list_item',
        listItem: 'staffplus_contact'
      }
    },
    dataSource: {
      required: false,
      allowMultiselect: true,
      cast: 'string'
    }
  },

}
</script>