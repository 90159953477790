<template>

  <v-empty-state
    :headline
    :title
    icon="mdi-alert-circle"
    action-text="Go Home"
    @click:action="home"
  >
    <template #text>

      <div v-if="error.statusCode == 404">
        If you clicked a link to get here, please notify the owner of the resource that the link is broken.
      </div>

      <div v-else class="text-medium-emphasis text-caption">
        The exact error message was "{{ message }}"
      </div>
    </template>

  </v-empty-state>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: Object,
}>()

const is404 = computed(() => props.error.statusCode == 404)
const headline = computed(() => is404.value ? 'Page Not Found' : 'Oops!');
const title = computed(() => is404.value ? 'The page you are looking for does not exist.' : 'An error occurred');
const message = computed(() => `${props.error.message} (error code ${props.error.statusCode})`)

const home = () => clearError({ redirect: '/' })
</script>