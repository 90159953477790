<!-- eslint-disable vue/no-v-html -->
<template>
  <div 
    @click="$emit('click:instructions')"
    @mouseover="$emit('mouseover:instructions')"
    @mouseout="$emit('mouseout:instructions')"
  >
    <slot v-if="empty" name="instructions.empty" />
    <slot :raw="content" :html="content">
      <html-display :content="content" />
    </slot>
  </div>
</template>

<script setup>
  
const props = defineProps({
  content: {
    type: String,
    default: ""
  }
});
  
defineEmits([
  'click:instructions',
  'mouseover:instructions',
  'mouseout:instructions'
]);

const empty = computed(() => isEmpty(props.content));

</script>