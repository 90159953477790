import { defineNuxtRouteMiddleware } from "#app";
import { usePortalStore, unauthorisedError, useBreadcrumbsStore, callbackValue } from "#imports";

export default defineNuxtRouteMiddleware((to, from) => {

  const portal = usePortalStore();
  const skipAuthorisation = to.path == '/' || to.meta.allowNoApplication;

  //ask portal to init, but don't mark initialised
  portal.ensureInitialised(false).then(() => {
    
    portal.detectApplication(to.path);
    if (! portal.application && ! skipAuthorisation) {
      return unauthorisedError();
    }

    //add breadcrumb
    if (portal.application) {
      const breadcrumbs = useBreadcrumbsStore();
      let skip = callbackValue(to.meta.skipBreadcrumb, to, from);
      if (! skip) {
        breadcrumbs.add(portal.application);
      }
    }
    
    //now that we have detected an app, safe to load the rest of the portal
    if (! portal.initialised) {
      portal.markInitialised();
    }
  });

});