import { useNuxtApp } from "#app";
import { usePortalStore } from "~/stores/portal"

export const usePortalHeaders = () => {
  
  const app = useNuxtApp();
  const token = app.$keycloak.token;

  const headers = { 
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }

  const portal = usePortalStore();
  if (portal.admin && portal.impersonateUsername) {
    headers['X-Divinity-Impersonate'] = portal.impersonateUsername;
  }

  return headers;

}