<template>

  <v-radio-group>

    <v-radio
      v-for="(option, i) in $attrs.items"
      :key="i"
      :value="$attrs.field?.options?.config?.titleAsKey ? (option.title || option.text) : (option.value || option.key)"
    >
      <template #label>
        {{ option.title || option.text }}
      </template>
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData || {}" />
      </template>
    </v-radio>

  </v-radio-group>
</template>

<script>

export default {
  
  config: {
    rules: ['required'],
    hideLabel: ['full'],
    dataSource: {
      cast: 'string',
      required: true,
      allowMultiselect: false
    }
  },

}
</script>