<template>

  <v-row>
    <v-col cols="6">
      <v-autocomplete
        v-model="model.mailer_class"
        :items="mailerDefinitions"
        item-title="name"
        item-value="mailer_class"
        label="Mailer class"
      />
    </v-col>

    <v-col cols="6">
      <v-autocomplete
        v-model="model.mailer_method"
        :items="mailerMethods"
        :disabled="! model.mailer_class"
        label="Mailer method"
      />
    </v-col>

  </v-row>

</template>

<script setup>

const model = defineModel({
  type: Object,
  default: () => ({})
});

const props = defineProps({
  mailerDefinitions: {
    type: Object,
    required: true
  }
});

const selectedDefinition = computed(() => props.mailerDefinitions.find(e => e.mailer_class === model.value.mailer_class));
const mailerMethods = computed(() => selectedDefinition.value?.mailer_methods);

watch(
  () => model.value.mailer_class, 
  () => {
    if (! model.value.mailer_method || ! mailerMethods.value?.includes(model.value.mailer_method)) {
      model.value.mailer_method = mailerMethods.value?.[0];
    }
  }
)

</script>